import { clsx } from "clsx";
import {
	type ComponentPropsWithoutRef,
	forwardRef,
	type HTMLAttributes,
	type TableHTMLAttributes,
	type ComponentProps,
	type ReactNode,
} from "react";
import styles from "./PlanningTable.module.css";

type PlanningTableProps = TableHTMLAttributes<HTMLTableElement> & {
	ariaLabel?: string;
};

export const PlanningTable = ({
	className,
	ariaLabel,
	...props
}: PlanningTableProps) => {
	return (
		<table
			className={clsx(
				"w-full border-collapse border-hidden bg-white",
				styles.planningTable,
				className,
			)}
			aria-label={ariaLabel}
			{...props}
		/>
	);
};

type PlanningTableContainerProps = HTMLAttributes<HTMLDivElement>;

export const PlanningTableContainer = forwardRef<
	HTMLDivElement,
	PlanningTableContainerProps
>(({ className, children, ...props }, ref) => {
	return (
		<div
			className={clsx(
				"rounded-lg shadow overflow-hidden overflow-x-auto border border-gray-200",
				className,
			)}
			ref={ref}
			{...props}
		>
			{/*
        this extra div is here because when an empty table renders the
        "no results found" and this parent has a horizontal scrollbar, the "no results found"
        isn't stretched to 100% of the parent width
      */}
			{/* <div className="inline-flex flex-col min-w-full">{children}</div> */}
			{children}
		</div>
	);
});

PlanningTableContainer.displayName = "PlanningTableContainer";

type PlanningTable2Props = TableHTMLAttributes<HTMLTableElement>;

export const PlanningTable2 = ({
	className,
	...props
}: PlanningTable2Props) => {
	return (
		<table
			className={clsx(
				"w-full border-separate border-spacing-0 border-hidden bg-white",
				className,
			)}
			{...props}
		/>
	);
};

interface ThTextProps {
	children?: ReactNode | undefined;
}

const ThText = ({ children }: ThTextProps) => {
	return <div className="text-gray-500 font-medium text-xs">{children}</div>;
};

PlanningTable2.ThText = ThText;

interface HeaderCellProps
	extends Omit<ComponentPropsWithoutRef<"th">, "children"> {
	labelProps?: ComponentProps<"label">;
	children?:
		| (ComponentPropsWithoutRef<"th">["children"] | (() => ReactNode))
		| undefined;
	noDefaultPadding?: boolean;
}

const Th = forwardRef<HTMLTableCellElement, HeaderCellProps>(
	(
		{ className, children, noDefaultPadding = false, labelProps, ...rest },
		ref,
	) => {
		const content =
			typeof children === "string" || typeof children === "function" ? (
				<ThText>{typeof children === "string" ? children : children()}</ThText>
			) : (
				children
			);

		return (
			<th
				className={clsx(
					"border-b-gray-200 bg-gray-50 text-left",
					!noDefaultPadding && "px-2 py-3 first:pl-4 last:pr-4",
					className,
				)}
				{...rest}
				ref={ref}
			>
				{labelProps !== undefined ? (
					<label {...labelProps}>{content}</label>
				) : (
					content
				)}
			</th>
		);
	},
);

Th.displayName = "Th";

PlanningTable2.Th = Th;

interface DataCellProps extends ComponentPropsWithoutRef<"td"> {
	noDefaultPadding?: boolean;
}

const Td = forwardRef<HTMLTableCellElement, DataCellProps>(
	({ className, noDefaultPadding = false, ...rest }, ref) => {
		return (
			<td
				className={clsx(
					"[&] border-t border-b-gray-200 bg-white text-left",
					!noDefaultPadding && "px-2 first:pl-4 last:pr-4",
					className,
				)}
				{...rest}
				ref={ref}
			/>
		);
	},
);

Td.displayName = "Td";

PlanningTable2.Td = Td;
