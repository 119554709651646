import type { SourceSystem } from "src/features/connector/sourceSystems";
import clsx from "clsx";
import { useSourceSystemMetadata } from "src/features/connector/metadata";

type Props = {
	sourceSystemName?: SourceSystem | undefined;
	className?: string | undefined;
	size?: "small" | "large" | undefined;
};

export const SourceSystemLogo = ({
	sourceSystemName,
	className,
	size = "large",
}: Props) => {
	const { name, logoUrl } = useSourceSystemMetadata(
		sourceSystemName as SourceSystem,
	);

	return (
		<img
			src={logoUrl}
			className={clsx(
				"rounded-xl",
				size === "small" ? "size-8" : "size-10",
				className,
			)}
			alt={name}
			title={name}
		/>
	);
};
