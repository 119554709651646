import { clsx } from "clsx";
import { type ComponentPropsWithoutRef, forwardRef } from "react";
import { SourceSystemLogo } from "src/features/connector/connectors/ConnectorsTable/SourceSystemLogo";
import { useSourceSystemMetadata } from "src/features/connector/metadata";
import type { SourceSystem } from "src/features/connector/sourceSystems";

interface ConnectorButtonProps extends ComponentPropsWithoutRef<"button"> {
	sourceSystem: SourceSystem;
	isLoading?: boolean | undefined;
}

export const ConnectorButton = forwardRef<
	HTMLButtonElement,
	ConnectorButtonProps
>(function ConnectorButton(
	{ className, sourceSystem, isLoading, ...others },
	ref,
) {
	const { name } = useSourceSystemMetadata(sourceSystem);
	return (
		<button
			className={clsx(
				"flex w-full items-center gap-4 rounded-lg border border-gray-200 bg-white h-16 px-4 text-left hover:border-purple-300 aria-busy:opacity-30 transition-opacity",
				className,
			)}
			aria-busy={isLoading}
			{...others}
			ref={ref}
		>
			<div className="shrink-0">
				<SourceSystemLogo sourceSystemName={sourceSystem} size="large" />
			</div>
			<div className="grow">
				<div className="text-gray-700 font-medium text-base">{name}</div>
			</div>
		</button>
	);
});
