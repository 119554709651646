import { IconBinaryTree } from "@tabler/icons-react";
import { Anchor } from "components/anchor/Anchor";
import { Button } from "components/button";
import { Input } from "components/input/Input";
import { Pagination } from "components/pagination/Pagination";
import { useMemo, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router";
import { useLoadedCompanyDomain } from "src/routes/routeDataHooks.js";
import { ItemPicker } from "src/common/components/item-picker/ItemPicker";
import { useDocumentTitle } from "src/common/utils/hooks/useDocumentTitle";
import { dateStringSort, stringSort } from "src/common/utils/hooks/useSort";
import {
	legalEntitiesFilterSlice,
	useLegalEntitiesFilter,
} from "src/features/legalEntities/legalEntitiesFilter";
import { LegalEntitiesLayout } from "src/features/legalEntities/LegalEntitiesLayout";
import { Card } from "src/features/planning/components/Card";
import { EmptyState } from "src/features/planning/components/EmptyState";
import { FilterNoResults } from "src/features/planning/components/FilterNoResults";
import { TableContainer } from "src/features/planning/components/TableContainer";
import { GridColumn } from "src/features/planning/scenario/common/grid/GridColumn";
import { GridContainer } from "src/features/planning/scenario/common/grid/GridContainer";
import { GridRow } from "src/features/planning/scenario/common/grid/GridRow";
import { useLocale } from "src/locales/useLocale";
import { useLoadedLegalEntities } from "src/routes/routeDataHooks";

const {
	reducer,
	getInitialState,
	actions: { clearFilter, filterName, filterCurrency, setPage },
} = legalEntitiesFilterSlice;

const PAGE_SIZE = 15;

const LegalEntitySource = ({ source }: { source: string }) => {
	const companyDomain = useLoadedCompanyDomain();
	const { t } = useTranslation();
	if (source.startsWith("FusionHub")) {
		const id = source.split(":")[1];
		return (
			<>
				<div>·</div>
				<Anchor
					component={Link}
					viewTransition
					to={`/company-domains/${companyDomain.id}/data-sources/${id}`}
				>
					{t("Data source")}
				</Anchor>
			</>
		);
	}
};

export const LegalEntitiesPage = () => {
	const { t } = useTranslation();
	const companyDomain = useLoadedCompanyDomain();
	const legalEntities = useLoadedLegalEntities();
	const { lang } = useLocale();
	const currencyNames = new Intl.DisplayNames([lang], { type: "currency" });
	const currencies = Array.from(
		new Set(legalEntities.map((legalEntity) => legalEntity.currencyBase)),
	).map((currencyCode) => {
		return {
			id: currencyCode,
			label: `${currencyCode} (${currencyNames.of(currencyCode)})`,
		};
	});
	const [filter, dispatch] = useReducer(reducer, getInitialState());
	const [sort, setSort] = useState<"oldest" | "newest" | "a-z" | "z-a" | null>(
		null,
	);
	const filteredLegalEntities = useLegalEntitiesFilter(legalEntities, filter);
	const sortedLegalEntities = useMemo(() => {
		switch (sort) {
			case "a-z":
			case null:
				return filteredLegalEntities.toSorted((a, b) =>
					stringSort(a.name, b.name),
				);
			case "z-a":
				return filteredLegalEntities.toSorted((a, b) =>
					stringSort(b.name, a.name),
				);
			case "newest":
				return filteredLegalEntities.toSorted((a, b) => {
					return dateStringSort(b.createdAt, a.createdAt);
				});
			case "oldest":
				return filteredLegalEntities.toSorted((a, b) => {
					return dateStringSort(a.createdAt, b.createdAt);
				});
		}
	}, [filteredLegalEntities, sort]);

	const pagedItems = sortedLegalEntities.slice(
		(filter.page - 1) * PAGE_SIZE,
		(filter.page - 1) * PAGE_SIZE + PAGE_SIZE,
	);

	useDocumentTitle(t("Companies"));

	return (
		<LegalEntitiesLayout>
			{legalEntities.length === 0 ? (
				<Card>
					<EmptyState
						header={t("No companies found")}
						subHeader={t(
							"Companies are automatically created when you add a data source. Add one to get started.",
						)}
						createButton={
							<Link
								to={`/company-domains/${companyDomain.id}/data-sources/new`}
							>
								<Button variant="primary">{t("Add data source")}</Button>
							</Link>
						}
					/>
				</Card>
			) : (
				<>
					<div className="flex gap-4 mb-4">
						<Input
							containerProps={{ className: "grow" }}
							placeholder={t("Filter by name")}
							type="search"
							value={filter.name}
							onChange={(event) => {
								dispatch(filterName(event.target.value));
							}}
						/>
						<Link to="./hierarchy">
							<Button variant="ghost" icon={<IconBinaryTree />}>
								{t("Group structure")}
							</Button>
						</Link>
					</div>
					<TableContainer className="mb-4">
						<div className="flex border-b p-2 gap-1 items-center">
							<div className="grow" />
							<>
								<ItemPicker
									filterLabel={t("Currency")}
									filterHeaderLabel={t("Filter by currency")}
									items={[{ id: "", label: t("All") }, ...currencies]}
									defaultValue=""
									onSelect={(status) => {
										dispatch(filterCurrency(status === "" ? null : status));
										setPage(1);
									}}
									selected={filter.currencyBase}
								/>
								<ItemPicker
									items={[
										{ id: "newest" as const, label: t("Newest") },
										{ id: "oldest" as const, label: t("Oldest") },
										{ id: "a-z" as const, label: t("Name A - Z") },
										{ id: "z-a" as const, label: t("Name Z - A") },
									]}
									defaultValue="a-z"
									onSelect={setSort}
									selected={sort}
									filterLabel={t("Sort")}
									filterHeaderLabel={t("Sort by")}
								/>
							</>
						</div>
						{pagedItems.length > 0 && (
							<GridContainer
								className="bg-white"
								role="list"
								aria-label={t("Data sources")}
								templateColumns="auto"
							>
								{pagedItems.map((legalEntity) => {
									return (
										<GridRow key={legalEntity.id}>
											<GridColumn>
												<div>
													<Anchor
														to={`./${legalEntity.id}`}
														component={Link}
														viewTransition
													>
														{legalEntity.name}
													</Anchor>
													<div className="flex items-center gap-2">
														{legalEntity.currencyBase}{" "}
														<LegalEntitySource source={legalEntity.source} />
													</div>
												</div>
											</GridColumn>
										</GridRow>
									);
								})}
							</GridContainer>
						)}

						{filteredLegalEntities.length === 0 && (
							<FilterNoResults
								onClearFilter={() => {
									dispatch(clearFilter(getInitialState()));
								}}
							/>
						)}
					</TableContainer>
					<div className="flex items-center justify-center gap-2">
						<Pagination
							page={filter.page}
							totalPages={Math.ceil(sortedLegalEntities.length / PAGE_SIZE)}
							onChange={(page) => {
								dispatch(setPage(page));
							}}
						/>
					</div>
				</>
			)}
		</LegalEntitiesLayout>
	);
};
