import { useTranslation } from "react-i18next";
import { Button } from "../../common/components/atoms/button/Button";
import { EwrReportList } from "./EwrReportList";
import { IconChevronRight, IconExternalLink } from "@tabler/icons-react";
import {
	useCreateReportDatabaseMutation,
	useCreateReportEwrMutation,
} from "../../common/redux/api/exopenApi";
import {
	showErrorNotification,
	showSuccessNotification,
} from "../../common/components/atoms/notifications/events";
import { Card } from "../planning/components/Card";
import { ButtonLink } from "../../common/components/atoms/button/ButtonLink.js";
import { useLoadedCompanyDomain } from "src/routes/routeDataHooks.js";

export const Ewr = () => {
	const { t } = useTranslation();

	const companyDomain = useLoadedCompanyDomain();

	const [createReportDatabase, { isLoading: isLoadingReportDb }] =
		useCreateReportDatabaseMutation();
	const [createEwr, { isLoading: isLoadingEwr }] = useCreateReportEwrMutation();

	const createNewReportDatabase = async () => {
		const result = await createReportDatabase({
			companyDomainId: `${companyDomain.id}`,
		});
		if ("error" in result) {
			showErrorNotification({
				message: t("Failed to create Report system database"),
			});
		} else {
			showSuccessNotification({
				message: t("Starting to create Report system database"),
			});
		}
	};

	const createNewEwr = async () => {
		const result = await createEwr({
			companyDomainId: `${companyDomain.id}`,
		});
		if ("error" in result) {
			showErrorNotification({
				message: t("Failed to start creation of the EWR"),
			});
		} else {
			showSuccessNotification({ message: t("Starting to create the EWR") });
		}
	};

	if (!companyDomain.ewr) {
		return (
			<Card className="p-4">
				<div className="mb-4 text-base">
					{t("Start by creating a Report database")}
				</div>
				<Button
					isLoading={isLoadingReportDb}
					onClick={() => {
						createNewReportDatabase();
					}}
					icon={<IconChevronRight />}
					iconPosition="right"
				>
					{t("Create Report system database")}
				</Button>
			</Card>
		);
	}

	if (!companyDomain.ewr.url) {
		return (
			<Card className="p-4">
				<EwrReportList />
				<div className="mb-4 text-base">{t("Report database created")}</div>
				<Button
					isLoading={isLoadingEwr}
					onClick={() => {
						createNewEwr();
					}}
					icon={<IconChevronRight />}
					iconPosition="right"
				>
					{t("Create EWR")}
				</Button>
			</Card>
		);
	}

	return (
		<Card className="w-full p-4">
			<EwrReportList />
			<div className="space-x-4">
				{companyDomain.ewr.url && (
					<ButtonLink
						reloadDocument
						to={companyDomain.ewr.url}
						target="_blank"
						icon={<IconExternalLink />}
						iconPosition="right"
					>
						{t("Open EWR (in new tab)")}
					</ButtonLink>
				)}
			</div>
		</Card>
	);
};
