type ProgressCircleSize = "sm";

const sizes: Record<
	ProgressCircleSize,
	{
		widthLabel: number;
		width: number;
		strokeWidth: number;
		strokeWidthLabel: number;
	}
> = {
	sm: { widthLabel: 44, width: 18, strokeWidthLabel: 4, strokeWidth: 2 },
};

interface ProgressCircleProps {
	value: number;
	size: ProgressCircleSize;
	label?: boolean;
}

export const ProgressCircle = ({
	value,
	size,
	label = true,
}: ProgressCircleProps) => {
	const {
		strokeWidth: strokeWidthWithoutLabel,
		strokeWidthLabel,
		width: widthWithoutLabel,
		widthLabel,
	} = sizes[size];
	const strokeWidth = label ? strokeWidthLabel : strokeWidthWithoutLabel;
	const width = label ? widthLabel : widthWithoutLabel;
	const radius = Math.floor(width / 2 - strokeWidth / 2);

	return (
		<div
			className="relative flex items-center justify-center"
			style={{ width, height: width }}
		>
			{label && <div className="text-xs font-medium">{value}%</div>}
			<svg
				className="absolute -rotate-90"
				width={width}
				height={width}
				viewBox={`0 0 ${width} ${width}`}
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle
					r={radius}
					cx={width / 2}
					cy={width / 2}
					fill="none"
					className="stroke-purple-25"
					strokeWidth={strokeWidth}
				/>
				<circle
					id="bar"
					r={radius}
					cx={width / 2}
					cy={width / 2}
					fill="none"
					className="stroke-purple-500 transition-[stroke-dashoffset] duration-300 ease-in-out"
					style={{ strokeDashoffset: 100 - value }}
					pathLength={100}
					strokeDasharray={100}
					strokeWidth={strokeWidth}
				/>
			</svg>
		</div>
	);
};
