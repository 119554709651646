import { useTranslation } from "react-i18next";
import type { circleLoaderSizes } from "./circleLoaderSizes";
import { CircleLoader } from "./CircleLoader";
import { clsx } from "clsx";

interface LoadingStateProps extends React.HTMLAttributes<HTMLDivElement> {
	size?: keyof typeof circleLoaderSizes;
	className?: string;
}

export const LoadingState = ({
	size = "md",
	className,
	...others
}: LoadingStateProps) => {
	const { t } = useTranslation();

	return (
		<div
			className={clsx(
				"flex flex-col items-center justify-center py-12",
				className,
			)}
			{...others}
		>
			<CircleLoader size={size} className="mb-4" />
			<div className="text-base font-medium">{t("Loading")}</div>
		</div>
	);
};
