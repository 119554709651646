import { clsx } from "clsx";
import { forwardRef, type LabelHTMLAttributes } from "react";
import { Radio, type RadioProps } from "./Radio";

interface RadioFieldProps extends RadioProps {
	label?: string;
	labelProps?: LabelHTMLAttributes<HTMLLabelElement>;
}

export const RadioField = forwardRef<HTMLInputElement, RadioFieldProps>(
	({ label, labelProps, ...props }, ref) => {
		const { className: labelClassName, ...extraLabelProps } = labelProps ?? {};
		const { className, ...restProps } = props;

		return (
			<label
				className={clsx(
					"inline-flex cursor-pointer items-center",
					labelClassName,
				)}
				{...extraLabelProps}
			>
				<Radio
					{...restProps}
					className={clsx("mr-2 shrink-0", className)}
					ref={ref}
					style={{ marginInlineEnd: 8 }}
				/>
				<div className="text-sm font-medium text-gray-700">{label}</div>
			</label>
		);
	},
);

RadioField.displayName = "RadioField";
