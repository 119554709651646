import { useTranslation } from "react-i18next";
import { ExopenLogo } from "../sidebar/ExopenLogo.tsx";
import { Button } from "../../common/components/atoms/button/Button.tsx";
import { IconArrowBack, IconExternalLink } from "@tabler/icons-react";
import { ButtonLink } from "../../common/components/atoms/button/ButtonLink.tsx";
import { logout } from "src/auth0.ts";

export const EmailVerification = () => {
	const { t } = useTranslation();

	return (
		<div className="md:py-44">
			<div className="mx-auto max-w-lg p-6 md:rounded-lg md:bg-white md:shadow">
				<div className="w-24 text-green-500">
					<ExopenLogo />
				</div>
				<div className="text-xl font-semibold pb-3 pt-6 text-center">
					{t("You just signed up")}
				</div>

				<div className="px-10 pb-6 text-center">
					{t(
						"Check your mail for a verification mail. If you haven't received one for a while, please contact us for assistance.",
					)}
				</div>

				<div className="flex justify-center gap-4">
					<Button
						variant="secondaryGray"
						onClick={() => {
							logout();
						}}
						icon={<IconArrowBack />}
						size="sm"
					>
						{t("Go back to login")}
					</Button>
					<ButtonLink
						reloadDocument
						to="https://www.exopen.se/kontakta-oss"
						target="_blank"
						variant="secondaryGray"
						icon={<IconExternalLink />}
						size="sm"
					>
						{t("Support")}
					</ButtonLink>
				</div>
			</div>
		</div>
	);
};
