import {
	MODULE_DEPENDENCIES,
	type DomainModule,
} from "@exopengithub/planning-api-shared";
import type { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Switch } from "../../../common/components/atoms/checkbox/Switch";
import { Divider } from "../../../common/components/atoms/divider/Divider";
import { getModuleName } from "../modules";
import { ValidateHint } from "./ValidateHint";
import { useId } from "react";
import { arrayToRecordByKey } from "src/common/utils/array-utils/arrayToRecordByKey.js";

interface ModuleSelectableListProps {
	modules: DomainModule[];
	selected: string[];
	onChange: (selected: string[]) => void;
	error?: FieldError | undefined;
	readOnly?: boolean | undefined;
	className?: string | undefined;
}

export const ModuleSelectableList = ({
	modules,
	selected,
	onChange,
	error,
	readOnly,
	className,
}: ModuleSelectableListProps) => {
	const { t } = useTranslation();
	const uid = useId();

	const moduleByRefType = arrayToRecordByKey(modules, "refType");

	return (
		<div className={className}>
			<ul className="m-0 list-none rounded-lg border border-gray-300 bg-white px-4">
				{modules.map((module, index, list) => {
					const nameId = `${uid}-${module.id}-name`;
					const descriptionId = `${uid}-${module.id}-description`;

					return (
						<li key={module.id}>
							<label className="flex items-center gap-4 py-4">
								<Switch
									disabled={readOnly}
									value={module.id}
									checked={selected.includes(module.id)}
									onChange={(event) => {
										let newSelected = [...selected];
										if (event.target.checked) {
											newSelected.push(module.id);
										} else {
											newSelected = newSelected.filter((moduleId2) => {
												return moduleId2 !== module.id;
											});
										}

										const dependencies = MODULE_DEPENDENCIES[module.refType];

										// Enable the dependencies of this module
										if (event.target.checked) {
											if (dependencies) {
												newSelected.push(
													...dependencies.map((refType) => {
														return moduleByRefType[refType].id;
													}),
												);
											}
											newSelected = Array.from(new Set(newSelected));
										} else {
											// Disable modules that's depending on this module
											const dependentOnThisModule = Object.entries(
												MODULE_DEPENDENCIES,
											).filter(([, dependencies]) => {
												return dependencies.includes(module.refType);
											});

											for (const [refType] of dependentOnThisModule) {
												const moduleId = moduleByRefType[refType].id;
												if (newSelected.includes(moduleId)) {
													newSelected.splice(newSelected.indexOf(moduleId), 1);
												}
											}
										}

										onChange(newSelected);
									}}
									aria-labelledby={nameId}
									aria-describedby={
										module.description ? descriptionId : undefined
									}
									className="shrink-0"
								/>
								<div>
									<div
										className="text-gray-700 text-sm font-medium"
										id={nameId}
									>
										{getModuleName(t, module)}
									</div>
									{module.description && (
										<div className="text-gray-500 text-sm" id={descriptionId}>
											{module.description}
										</div>
									)}
								</div>
							</label>
							{list.length - 1 !== index && <Divider />}
						</li>
					);
				})}
			</ul>
			{error !== undefined && (
				<ValidateHint error>{error.message}</ValidateHint>
			)}
		</div>
	);
};
