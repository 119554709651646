import {
	useGetChangeLogLastReadQuery,
	useUpdateChangeLogLastReadMutation,
} from "../api/changeLogApi";
import { changeLogItems } from "./ChangeLogItem";
import { useTranslation } from "react-i18next";
import { Button } from "components/button";
import { formatYYYYMMDD } from "../utils";
import { Alert } from "components/alert/Alert";
import { DialogContent } from "components/dialog/DialogContent";
import { DialogTitle } from "components/dialog/DialogTitle";
import { DialogBody } from "components/dialog/DialogBody";
import { DialogActions } from "components/dialog/DialogActions";
import { Dialog } from "components/dialog/Dialog";
import { useCallback, useEffect, useMemo } from "react";

const getUnreadChangeLogItems = (
	lastRead: { id: string | null } | undefined,
) => {
	if (!lastRead || !lastRead.id) {
		return [];
	}

	const lastReadIndex = changeLogItems.findIndex(
		({ id }) => lastRead.id === id,
	);

	if (lastReadIndex === -1) {
		return changeLogItems;
	}

	return changeLogItems.slice(0, lastReadIndex);
};

let called = false;

export const ChangeLog = () => {
	const { t } = useTranslation();
	const { data: lastRead } = useGetChangeLogLastReadQuery();
	const [updateChangeLog, { isError: isMutationError }] =
		useUpdateChangeLogLastReadMutation();

	const unreadChangeLogItems = useMemo(
		() => getUnreadChangeLogItems(lastRead),
		[lastRead],
	);

	const onClose = useCallback(() => {
		const [newestItem] = unreadChangeLogItems;
		updateChangeLog(newestItem.id);
	}, [unreadChangeLogItems, updateChangeLog]);

	useEffect(() => {
		if (lastRead && lastRead.id === null && !called) {
			called = true;
			updateChangeLog(changeLogItems[0].id);
		}
	}, [lastRead, updateChangeLog]);

	const modalOpen = Boolean(unreadChangeLogItems.length && !isMutationError);

	return (
		<Dialog open={modalOpen} onClose={onClose}>
			<DialogContent size="2xl">
				{({ close }) => {
					return (
						<>
							<DialogTitle>{t("News in Planning")}</DialogTitle>
							<DialogBody>
								{unreadChangeLogItems.map((item) => {
									const Component = item.content;
									return (
										<article
											className="border-b py-6 first:pt-0 last:border-b-0"
											key={item.id}
										>
											<h3 className="pb-2">
												<div className="text-xs">
													{formatYYYYMMDD(item.date)}
												</div>
												<div className="font-semibold text-base">
													{item.title({ t })}
												</div>
											</h3>
											<Component t={t} />
											{item.affectsPlanCalculations && (
												<Alert
													variant="info"
													className="mt-2"
													title={t("Affects plan calculations")}
													supportingText={
														<span>
															{t(
																"Your plan's calculations may be affected by this change if they are ongoing. That is, not the plans that have already been exported to Data Layer, either via manual export or by marking the plan as complete.",
															)}
														</span>
													}
												/>
											)}
										</article>
									);
								})}
							</DialogBody>
							<DialogActions>
								<Button
									onClick={() => {
										close();
									}}
									variant="secondaryGray"
								>
									{t("Close")}
								</Button>
							</DialogActions>
						</>
					);
				}}
			</DialogContent>
		</Dialog>
	);
};
