import { clsx } from "clsx";
import { forwardRef, type ComponentProps } from "react";
import { ValidateHint } from "../../../../features/planning/components/ValidateHint";
import type { InputProps } from "../input/Input";
import {
	COMMON_BORDERLESS_CLASS,
	COMMON_INPUT_CLASS,
} from "../input/inputUtils";
import type { Except } from "type-fest";

interface NativeSelectProps
	extends Except<ComponentProps<"select">, "aria-invalid">,
		Pick<InputProps, "hint" | "containerProps"> {
	borderless?: boolean;
	ariaInvalid?: boolean;
}

export const NativeSelect = forwardRef<HTMLSelectElement, NativeSelectProps>(
	function NativeSelect(
		{
			className,
			ariaInvalid = false,
			hint,
			containerProps,
			borderless = false,
			style,
			...props
		},
		ref,
	) {
		return (
			<div {...containerProps}>
				<select
					className={clsx(
						borderless ? COMMON_BORDERLESS_CLASS : COMMON_INPUT_CLASS,
						"appearance-none h-[40px] pr-10 pl-4 bg-no-repeat bg-[right_12px_center] block w-full text-sm text-gray-900 disabled:cursor-not-allowed disabled:text-gray-500",
						ariaInvalid ? "text-red-500" : "text-gray-900",
						className,
					)}
					style={{
						...style,
						backgroundImage: ariaInvalid
							? `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='rgb(240 68 56)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='text-red-500 shrink-0 ml-2'%3E%3Cpath d='M6 9l6 6l6 -6'%3E%3C/path%3E%3C/svg%3E")`
							: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='%236666CC' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='text-red-500 shrink-0 ml-2'%3E%3Cpath d='M6 9l6 6l6 -6'%3E%3C/path%3E%3C/svg%3E")`,
					}}
					aria-invalid={ariaInvalid}
					{...props}
					ref={ref}
				/>
				{hint && <ValidateHint error={ariaInvalid}>{hint}</ValidateHint>}
			</div>
		);
	},
);
