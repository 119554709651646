const FEATURE_FLAGS = {
	demoIntegration: true,
	costCenterPermissions: false,
} satisfies Record<string, boolean>;

export type FeatureFlagName = keyof typeof FEATURE_FLAGS;

export const FEATURE_FLAG_ENABLED_STORAGE_KEY = "featureFlagsEnabled";
const FEATURE_FLAG_SESSION_STORAGE_KEY = "featureFlags";

export function loadFeatureFlags() {
	const defaultFeatureFlags: Record<FeatureFlagName, boolean> = {
		...FEATURE_FLAGS,
	};
	const savedFeatureFlags = sessionStorage.getItem(
		FEATURE_FLAG_SESSION_STORAGE_KEY,
	);
	if (savedFeatureFlags !== null) {
		try {
			const json = JSON.parse(savedFeatureFlags);
			const entries = Object.entries(json);
			for (const [key, value] of entries) {
				if (key in FEATURE_FLAGS && typeof value === "boolean") {
					defaultFeatureFlags[key as FeatureFlagName] = value;
				}
			}
			return defaultFeatureFlags;
		} catch {
			sessionStorage.removeItem(FEATURE_FLAG_SESSION_STORAGE_KEY);
		}
	}
	return defaultFeatureFlags;
}

let currentFeatureFlags = loadFeatureFlags();

export const toggleFeature = (feature: FeatureFlagName, value: boolean) => {
	sessionStorage.setItem(
		FEATURE_FLAG_SESSION_STORAGE_KEY,
		JSON.stringify({ ...currentFeatureFlags, [feature]: value }),
	);
	currentFeatureFlags = loadFeatureFlags();
};

export const hasFeature = (feature: FeatureFlagName) => {
	return currentFeatureFlags[feature];
};
