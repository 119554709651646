import { SidebarUserSection } from "../../sidebar/SidebarUserSection";
import { useUser } from "../../../common/context/userContextUtils";
import { ExopenSingleLetterLogo } from "src/features/sidebar/ExopenSingleLetterLogo";
import type { ReactNode } from "react";

interface Inputs {
	children?: ReactNode;
	title: string;
	logo: string;
}

export const AddDataSourcePage = ({ children, title, logo }: Inputs) => {
	const user = useUser();
	return (
		<>
			<div className="mb-2 pt-8">
				<div className="mb-2 flex items-center justify-center gap-2">
					<ExopenSingleLetterLogo className="size-9 text-green-500" />
					<img src={logo} alt="logotype" className="size-9" />
				</div>
				<div className="mb-2 flex items-center justify-center text-xl font-medium text-gray-900">
					{title}
				</div>
				<div className="mx-auto max-w-screen-lg">{children}</div>
			</div>
			<div className="fixed bottom-4 left-4 flex justify-end gap-4 md:bottom-6 md:left-6">
				{user.user && (
					<SidebarUserSection sidebarOpen={true} user={user.user} />
				)}
			</div>
		</>
	);
};
