export type StepperSize = "sm" | "md" | "lg";

export const stepperSizes: Record<
	StepperSize,
	{
		iconSize: number;
		labelSizeClass?: string;
		dotSize: number;
		horizontalIconSpacing: number;
		verticalIconSpacing: number;
	}
> = {
	sm: {
		iconSize: 24,
		labelSizeClass: "text-sm",
		dotSize: 8,
		horizontalIconSpacing: 14,
		verticalIconSpacing: 12,
	},
	md: {
		iconSize: 32,
		dotSize: 10,
		horizontalIconSpacing: 20,
		verticalIconSpacing: 16,
	},
	lg: {
		iconSize: 40,
		dotSize: 12,
		horizontalIconSpacing: 24,
		verticalIconSpacing: 16,
	},
};
