import { clsx } from "clsx";
import type { ComponentPropsWithoutRef, ReactElement } from "react";
import { Chip } from "../chip/Chip";
import type { IconProps } from "@tabler/icons-react";

interface TabProps<T extends React.ElementType> {
	as?: T;
	selected?: boolean;
	count?: number;
	children?: string | undefined;
	icon?: ReactElement<IconProps>;
}

export const Tab = <T extends React.ElementType = "button">({
	as,
	className,
	children,
	selected,
	count,
	icon,
	...props
}: TabProps<T> & Omit<ComponentPropsWithoutRef<T>, keyof TabProps<T>>) => {
	const Component = as ?? "button";

	return (
		<Component
			role="tab"
			className={clsx(
				"group px-1 pb-4 pt-[2px] border-b border-b-transparent hover:border-b-purple-500 aria-selected:border-b-purple-500",
				className,
			)}
			type="button"
			{...props}
			aria-selected={selected}
		>
			<div
				className={clsx(
					"flex items-center whitespace-nowrap",
					selected
						? "text-purple-500"
						: "text-gray-500 group-hover:text-purple-500",
				)}
			>
				{icon && <div className="mr-1">{icon}</div>}
				<span className="text-inherit font-medium text-sm">{children}</span>
				{count !== undefined ? (
					<Chip
						size="small"
						className="ml-2"
						variant={selected ? "purple" : "gray"}
					>
						{count}
					</Chip>
				) : null}
			</div>
		</Component>
	);
};
