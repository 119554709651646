import { Outlet } from "react-router";
import { Sidebar } from "../features/sidebar/Sidebar";
import { AppFrame, MainFrame } from "./ProtectedRoutes";
import { PipeLineProgressLoader } from "../features/pipelineProgressLoader/PipelineProgressLoader.js";
import { CompanySidebarPart } from "../features/sidebar/CompanySidebarPart.js";

export const CompanyRouteLayout = ({
	sidebarOpen,
}: {
	sidebarOpen?: boolean;
}) => {
	return (
		<AppFrame>
			<Sidebar
				defaultOpen={sidebarOpen}
				renderFooter={() => <PipeLineProgressLoader />}
				renderContent={({ sidebarOpen }) => (
					<CompanySidebarPart sidebarOpen={sidebarOpen} />
				)}
			/>
			<MainFrame>
				<Outlet />
			</MainFrame>
		</AppFrame>
	);
};
