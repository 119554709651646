import { IconBuildings } from "@tabler/icons-react";
import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useLoadedCompanyDomain } from "src/routes/routeDataHooks.js";
import {
	type BreadCrumbLink,
	BreadcrumbsLayout,
} from "src/common/components/layout/BreadcrumbsLayout";

export const LegalEntitiesLayout = ({
	breadcrumbs,
	children,
}: { breadcrumbs?: BreadCrumbLink[] | undefined; children: ReactNode }) => {
	const { t } = useTranslation();
	const companyDomain = useLoadedCompanyDomain();

	return (
		<BreadcrumbsLayout
			breadcrumbs={[
				{
					icon: <IconBuildings />,
					name: breadcrumbs ? undefined : t("Companies"),
					path: `/company-domains/${companyDomain.id}/legal-entities`,
				},
				...(breadcrumbs ?? []),
			]}
		>
			{children}
		</BreadcrumbsLayout>
	);
};
