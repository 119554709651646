import { useContext, type ReactNode } from "react";
import { invariant } from "src/common/utils/invariant.js";
import { FormSectionContext } from "./FormSectionContext.js";

interface FormSectionProps {
	title?: string;
	description?: ReactNode;
	children?: ReactNode | undefined;
	titleId?: string;
}

export const FormSection = ({
	title,
	description,
	children,
	titleId,
}: FormSectionProps) => {
	const context = useContext(FormSectionContext);
	invariant(
		context,
		"<FormSection> must be rendered within <FormSectionContainer>",
	);

	return (
		<div className="mb-6 flex flex-col gap-6 border-b border-b-gray-200 pb-6 @2xl:flex-row">
			<div className="w-[280px] shrink-0">
				<div className="sticky top-0">
					{title && (
						<div className="text-sm font-medium text-gray-700" id={titleId}>
							{title}
						</div>
					)}
					{description && (
						<div className="text-gray-500 text-sm whitespace-pre-wrap">
							{description}
						</div>
					)}
				</div>
			</div>
			<div className="grow">{children}</div>
		</div>
	);
};
