import { IconExternalLink, IconCopy } from "@tabler/icons-react";
import { Button } from "components/button";
import { Radio } from "components/checkbox/Radio";
import {
	showErrorNotification,
	showSuccessNotification,
} from "components/notifications/events";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "components/dialog/Dialog";
import { DialogBody } from "components/dialog/DialogBody";
import { DialogContent } from "components/dialog/DialogContent";
import { DialogTitle } from "components/dialog/DialogTitle";
import { DialogTrigger } from "components/dialog/DialogTrigger";
import { ConnectorButton } from "../ConnectorButton";
import { useLazyGetFortnoxAuthorizationUrlQuery } from "src/common/service/fusionHub/integrations/fortnoxApi";
import { Collapse } from "components/collapse/Collapse.tsx";
import { DialogActions } from "components/dialog/DialogActions.tsx";

export const FortnoxConnectorDialogContent = ({
	companyDomainId,
}: {
	companyDomainId: string;
}) => {
	const { t } = useTranslation();

	const [fortnoxValue, setFortnoxValue] = useState<"redirect" | "copy">(
		"redirect",
	);
	const [createLink, { isLoading: isCreatingUrl }] =
		useLazyGetFortnoxAuthorizationUrlQuery();

	return (
		<>
			<DialogTitle>{t("Add Fortnox data source")}</DialogTitle>
			<DialogBody>
				<div className="mt-2">
					{t(
						"In order to add a Fortnox data source you must be an administrator in Fortnox for the company you want to connect.",
					)}
				</div>
				<div className="mt-2 mb-4">
					{t(
						"If you are a user in multiple companies in Fortnox, make sure you are signed in to the correct one. Otherwise, you will have to sign out and then sign in to the correct account in Fortnox in another window before you do this step.",
					)}
				</div>
				<label className="mb-5 flex items-center gap-2">
					<div className="shrink-0">
						<Radio
							name="fortnox-data-source-type"
							checked={fortnoxValue === "redirect"}
							onChange={(event) => {
								if (event.target.checked) {
									setFortnoxValue("redirect");
								}
							}}
						/>
					</div>
					<div>
						<div className="text-sm font-medium text-gray-700">
							{t("I want to do it myself")}
						</div>
						<div className="text-sm text-gray-500">
							{t(
								"You'll be redirected to Fortnox to sign in and approve the integration",
							)}
						</div>
					</div>
				</label>
				<label className="flex items-center gap-2">
					<div className="shrink-0">
						<Radio
							name="fortnox-data-source-type"
							checked={fortnoxValue === "copy"}
							onChange={(event) => {
								if (event.target.checked) {
									setFortnoxValue("copy");
								}
							}}
						/>
					</div>
					<div>
						<div className="text-sm font-medium text-gray-700">
							{t("I want to share a link with a non-Exopen user")}
						</div>
						<div className="text-sm text-gray-500">
							{t(
								"An external user will use the URL to sign in and approve the integration, copy a link and send it to a user with a Fortnox account",
							)}
						</div>
					</div>
				</label>
				<Collapse in={fortnoxValue === "copy"}>
					<div className="text-sm text-gray-500 pt-2">
						{t(
							"The link remains valid for 8 hours. After this period, you will need to generate a new one.",
						)}
					</div>
				</Collapse>
			</DialogBody>
			<DialogActions withCancelButton>
				{fortnoxValue === "redirect" ? (
					<Button
						icon={<IconExternalLink />}
						iconPosition="right"
						isLoading={isCreatingUrl}
						onClick={async () => {
							const result = await createLink({
								companyDomainId,
								successRedirectUrl: `${window.location.origin}/company-domains/${companyDomainId}/data-sources/setup?autoResolve=true`,
								errorRedirectUrl: window.location.href,
							});

							if ("error" in result) {
								showErrorNotification({ message: t("Something failed...") });
							} else {
								location.href = result.data!.authorizationUrl;
							}
						}}
					>
						{t("Continue")}
					</Button>
				) : (
					<Button
						icon={<IconCopy />}
						isLoading={isCreatingUrl}
						onClick={async () => {
							const result = await createLink({
								companyDomainId,
								successRedirectUrl: `${window.location.origin}/fortnox-external-callback`,
								errorRedirectUrl: `${window.location.origin}/fortnox-external-callback`,
							});

							if ("error" in result) {
								showErrorNotification({ message: t("Something failed...") });
							} else {
								await navigator.clipboard.writeText(
									result.data!.authorizationUrl!,
								);
								showSuccessNotification({
									message: t("The link was copied to clipboard"),
								});
							}
						}}
					>
						{t("Copy Link")}
					</Button>
				)}
			</DialogActions>
		</>
	);
};

interface Props {
	companyDomainId: string;
}

export const AddFortnoxConnectorModal = ({ companyDomainId }: Props) => {
	return (
		<Dialog>
			<DialogTrigger asChild>
				<ConnectorButton sourceSystem="FORTNOX" />
			</DialogTrigger>
			<DialogContent size="md">
				<FortnoxConnectorDialogContent companyDomainId={companyDomainId} />
			</DialogContent>
		</Dialog>
	);
};
