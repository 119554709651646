import { TabLink } from "components/tabs/TabLink";
import { Tabs } from "components/tabs/Tabs";
import type { TFunction } from "i18next";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, type To, useLocation, useMatch } from "react-router";
import type { BreadCrumbSelectionItem } from "src/common/components/layout/BreadcrumbsLayout";
import type { LegalEntity } from "src/common/service/nexus/endpoints/companyDomain/types";
import { useDocumentTitle } from "src/common/utils/hooks/useDocumentTitle";
import { QUERY_PARAM_GROUP } from "src/features/legalEntities/constants";
import { LegalEntitiesLayout } from "src/features/legalEntities/LegalEntitiesLayout";
import {
	useLoadedCompanyHierarchy,
	useLoadedLegalEntities,
	useLoadedLegalEntity,
} from "src/routes/routeDataHooks";

function useBuildBreadcrumbItems({
	t,
	legalEntity,
}: { t: TFunction; legalEntity: LegalEntity }) {
	const legalEntities = useLoadedLegalEntities();
	const match = useMatch(
		"/company-domains/:companyDomainId/legal-entities/:legalEntityId/*",
	);
	const { hierarchy } = useLoadedCompanyHierarchy();
	const { search } = useLocation();

	return useMemo(() => {
		const items: BreadCrumbSelectionItem[] = [];

		const getTo = (legalEntity: LegalEntity, isGroup: boolean): To => {
			let pathname = `/company-domains/${legalEntity.companyDomainId}/legal-entities/${legalEntity.id}`;
			if (match?.params["*"]) {
				pathname += `/${match.params["*"]}`;
			}

			return { pathname, search: isGroup ? `?${QUERY_PARAM_GROUP}` : "" };
		};

		if (hierarchy) {
			items.push({
				label: t("Group ({{name}})", {
					name: hierarchy.legalEntity.name,
				}),
				value: `${hierarchy.legalEntity.id}-group`,
				to: getTo(hierarchy.legalEntity, true),
			});
		}

		for (const legalEntity of legalEntities) {
			items.push({
				label: legalEntity.name,
				value: legalEntity.id,
				to: getTo(legalEntity, false),
			});
		}

		const searchParams = new URLSearchParams(search);
		const isGroupSelected = searchParams.has(QUERY_PARAM_GROUP);
		return {
			items,
			isGroupSelected,
			selected: items.find((item) => {
				return (
					item.value ===
					(isGroupSelected && hierarchy
						? `${hierarchy.legalEntity.id}-group`
						: legalEntity.id)
				);
			}),
		};
	}, [hierarchy, legalEntities, t, match?.params["*"], legalEntity.id, search]);
}

export const LegalEntityDetailLayout = () => {
	const legalEntity = useLoadedLegalEntity();
	const { t } = useTranslation();
	useDocumentTitle(legalEntity.name);

	const { items, selected, isGroupSelected } = useBuildBreadcrumbItems({
		t,
		legalEntity,
	});

	const buildTo = useCallback(
		(pathname: string): To => {
			return {
				pathname,
				search: isGroupSelected ? `?${QUERY_PARAM_GROUP}` : "",
			};
		},
		[isGroupSelected],
	);

	return (
		<>
			<LegalEntitiesLayout
				breadcrumbs={[
					{
						name: selected!.label,
						selections: {
							filterPlaceholder: t("Find company"),
							selected: selected!.value,
							items,
						},
					},
				]}
			>
				<Tabs>
					<TabLink to={buildTo("accounts")}>{t("Accounts")}</TabLink>
					<TabLink to={buildTo("cost-centers")}>{t("Cost centers")}</TabLink>
					<TabLink to={buildTo("customers")}>{t("Customers")}</TabLink>
					<TabLink to={buildTo("suppliers")}>{t("Suppliers")}</TabLink>
					<TabLink to={buildTo("projects")}>{t("Projects")}</TabLink>
					<TabLink to={buildTo("products")}>{t("Products")}</TabLink>
					<TabLink to={buildTo("invoices")}>{t("Invoices")}</TabLink>
				</Tabs>
				<Outlet context={{ buildTo }} />
			</LegalEntitiesLayout>
		</>
	);
};
