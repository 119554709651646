import type { IconProps } from "@tabler/icons-react";
import { clsx } from "clsx";
import { cloneElement, type HTMLAttributes, type ReactElement } from "react";

export type FeaturedIconSize = "xs" | "sm" | "md" | "lg" | "xl";

export type FeaturedIconColor = "primary" | "error" | "gray" | "warning";

export type FeaturedIconTheme = "light_circle" | "light_circle_outline";

const iconSize: Record<FeaturedIconSize, number> = {
	xs: 12,
	sm: 16,
	md: 20,
	lg: 24,
	xl: 28,
};

const containerSize: Record<FeaturedIconSize, string> = {
	xs: "w-[24px] h-[24px]",
	sm: "w-[32px] h-[32px]",
	md: "w-[40px] h-[40px]",
	lg: "w-[48px] h-[48px]",
	xl: "w-[56px] h-[56px]",
};

const backgroundColors: Record<
	FeaturedIconColor,
	string | Record<FeaturedIconTheme, string | null>
> = {
	error: {
		light_circle: "bg-red-100",
		light_circle_outline: "bg-red-100",
	},
	gray: {
		light_circle: "bg-gray-100",
		light_circle_outline: "bg-gray-100",
	},
	warning: {
		light_circle: "bg-yellow-100",
		light_circle_outline: "bg-yellow-100",
	},
	primary: {
		light_circle: "bg-green-50",
		light_circle_outline: "bg-green-100",
	},
};

const iconColor: Record<
	FeaturedIconTheme,
	Record<FeaturedIconColor, string>
> = {
	light_circle: {
		primary: "text-green-500",
		gray: "text-gray-600",
		error: "text-red-600",
		warning: "text-yellow-600",
	},
	light_circle_outline: {
		primary: "text-green-600",
		gray: "text-gray-600",
		error: "text-red-600",
		warning: "text-yellow-600",
	},
};

const borderWidths: Record<
	FeaturedIconTheme,
	Record<FeaturedIconSize, number> | null
> = {
	light_circle: null,
	light_circle_outline: { xs: 2, sm: 4, md: 6, lg: 8, xl: 10 },
};

const borderColor: Record<
	FeaturedIconTheme,
	Record<FeaturedIconColor, string> | null
> = {
	light_circle_outline: {
		error: "border-red-50",
		primary: "border-green-50",
		warning: "border-yellow-50",
		gray: "border-gray-50",
	},
	light_circle: null,
};

const borderRadiuses: Record<
	FeaturedIconTheme,
	string | Record<FeaturedIconSize, number>
> = {
	light_circle_outline: "50%",
	light_circle: "50%",
};

export interface FeaturedIconProps extends HTMLAttributes<HTMLDivElement> {
	children: ReactElement<IconProps>;
	size: FeaturedIconSize;
	theme: FeaturedIconTheme;
	variant: FeaturedIconColor;
}

export const FeaturedIcon = ({
	children,
	size,
	theme,
	variant,
	className,
	...props
}: FeaturedIconProps) => {
	const bWidths = borderWidths[theme];
	const borderRadius = borderRadiuses[theme];
	const bgColor = backgroundColors[variant];
	const borderC = borderColor[theme];
	return (
		<div
			className={clsx(
				"inline-flex items-center justify-center",
				typeof bgColor === "string" ? bgColor : (bgColor[theme] ?? undefined),
				borderC === null ? undefined : borderC[variant],
				containerSize[size],
				className,
			)}
			style={{
				borderWidth: bWidths !== null ? bWidths[size] : undefined,
				borderRadius:
					typeof borderRadius === "string" ? borderRadius : borderRadius[size],
			}}
			{...props}
		>
			{cloneElement(children, {
				size: iconSize[size],
				className: iconColor[theme][variant],
			})}
		</div>
	);
};
