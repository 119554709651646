import type { IconProps } from "@tabler/icons-react";
import { clsx } from "clsx";
import { type ReactElement, cloneElement } from "react";

interface LinkItemProps {
	isActive?: boolean;
	icon: ReactElement<IconProps>;
	label?: string | undefined;
}

export const LinkItem = ({ isActive, label, icon }: LinkItemProps) => {
	if (!label) {
		return (
			<div
				className={clsx(
					"rounded-[6px] p-[10px]",
					isActive ? "bg-green-50" : "hover:bg-gray-50",
				)}
			>
				{cloneElement<IconProps>(icon, {
					size: 20,
					className: isActive ? "text-green-500" : "text-gray-500",
				})}
			</div>
		);
	}

	return (
		<div
			className={clsx(
				"flex items-center gap-4 rounded-[6px] px-[12px] py-[10px]",
				isActive ? "bg-green-200" : "hover:bg-gray-50",
			)}
		>
			<div className="shrink-0">
				{cloneElement<IconProps>(icon, {
					size: 20,
					className: isActive ? "text-green-700" : "text-gray-500",
				})}
			</div>
			<div>
				<div
					className={clsx(
						"font-medium",
						isActive ? "text-green-700" : "text-gray-700",
					)}
				>
					{label}
				</div>
			</div>
		</div>
	);
};
