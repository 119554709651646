import { IconCheck, IconSelector } from "@tabler/icons-react";
import clsx from "clsx";
import { Input } from "components/input/Input";
import { Popover } from "components/popoverMenu/Popover";
import { PopoverContent } from "components/popoverMenu/PopoverContent";
import { PopoverTrigger } from "components/popoverMenu/PopoverTrigger";
import { type MutableRefObject, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router";
import type { BreadCrumbLink } from "src/common/components/layout/BreadcrumbsLayout";
import { useDebounceState } from "src/common/utils/hooks/useDebounce";

export const BreadcrumbItemSelector = ({
	selections,
}: { selections: Exclude<BreadCrumbLink["selections"], undefined> }) => {
	const [filter, setFilter, debouncedFilter] = useDebounceState({
		initialValue: "",
		ms: 300,
	});

	const filteredItems = useMemo(() => {
		return selections.items.filter((item) =>
			item.label.toLowerCase().includes(debouncedFilter.toLowerCase()),
		);
	}, [debouncedFilter, selections.items]);

	const { t } = useTranslation();

	return (
		<Popover role="menu" placement="bottom-start" maxHeight={500}>
			<PopoverTrigger className="hover:bg-gray-200 p-1 py-1 rounded transition-colors">
				<IconSelector className="text-gray-500" size={20} />
			</PopoverTrigger>
			<PopoverContent className="w-[300px]">
				{({ close, initialElementRef }) => {
					return (
						<div className="overflow-hidden rounded-lg border border-gray-300 bg-white shadow-2xl max-h-[inherit] flex flex-col">
							<div className="border-b border-b-gray-200">
								<Input
									type="search"
									placeholder={selections.filterPlaceholder}
									borderless
									clearable
									value={filter}
									onChange={(e) => setFilter(e.target.value)}
									ref={initialElementRef as MutableRefObject<HTMLInputElement>}
								/>
							</div>
							{filteredItems.length === 0 ? (
								<div className="text-center py-2 text-gray-700">
									{t("No results found")}
								</div>
							) : (
								<div className="flex flex-col grow overflow-y-auto">
									{filteredItems.map((selection) => {
										return (
											<Link
												key={selection.value}
												to={selection.to}
												className="flex items-center gap-2 border-b border-b-gray-200 px-3 py-2 text-left text-gray-700 last:border-b-0 hover:bg-gray-100"
												viewTransition
												onClick={() => {
													close();
												}}
											>
												<div className="grow truncate">{selection.label}</div>
												<IconCheck
													className={clsx(
														"shrink-0 text-gray-500 opacity-0",
														selections.selected === selection.value &&
															"opacity-100",
													)}
													size={20}
												/>
											</Link>
										);
									})}
								</div>
							)}
						</div>
					);
				}}
			</PopoverContent>
		</Popover>
	);
};
