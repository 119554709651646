import { IconX, type IconProps } from "@tabler/icons-react";
import { clsx } from "clsx";
import {
	type ComponentPropsWithoutRef,
	forwardRef,
	type ReactElement,
	type ReactNode,
} from "react";
import {
	FeaturedIcon,
	type FeaturedIconColor,
} from "../featuredIcon/FeaturedIcon";
import { useTranslation } from "react-i18next";
import { Button } from "../button/Button.tsx";

export interface NotificationProps
	extends Omit<ComponentPropsWithoutRef<"div">, "title"> {
	onClose?(): void;
	icon?: ReactElement<IconProps>;
	iconColor?: FeaturedIconColor;
	title?: ReactNode;
	children?: ReactNode | undefined;
	disallowClose?: boolean;
}

export const Notification = forwardRef<HTMLDivElement, NotificationProps>(
	function Notification(
		{
			className,
			disallowClose,
			title,
			icon,
			iconColor,
			children,
			onClose,
			...others
		},
		ref,
	) {
		const withTitle = title !== undefined;
		const title_ = withTitle ? title : children;
		const message = !withTitle ? undefined : children;
		const { t } = useTranslation();

		return (
			<div
				className={clsx(
					"shadow bg-white rounded-lg items-center flex",
					className,
				)}
				role="alert"
				aria-labelledby={`${others.id}-label`}
				ref={ref}
				{...others}
			>
				{icon && (
					<div className="py-3 pl-3 self-start">
						<FeaturedIcon
							size="md"
							theme="light_circle"
							variant={iconColor ?? "gray"}
						>
							{icon}
						</FeaturedIcon>
					</div>
				)}

				<div className="min-w-0 grow p-4">
					<div
						className="text-sm font-medium break-words"
						id={`${others.id}-label`}
					>
						{title_}
					</div>

					{message && (
						<div className="break-words text-gray-500 text-sm">{message}</div>
					)}
				</div>

				{!disallowClose && (
					<div className="self-start p-2">
						<Button
							size="sm"
							variant="ghost"
							ariaLabel={t("Close")}
							onClick={onClose}
							icon={<IconX />}
						/>
					</div>
				)}
			</div>
		);
	},
);
