import { Link } from "react-router";
import { NavItem } from "./NavItem";
import {
	IconBooks,
	IconChevronsLeft,
	IconChevronsRight,
	IconHelp,
	IconMenu2,
	IconX,
} from "@tabler/icons-react";
import { NavItemDivider } from "./NavItemDivider";
import { useTranslation } from "react-i18next";
import { useCollapsibleSidebar } from "./useCollapsibleSidebar";
import { clsx } from "clsx";
import { SidebarContext } from "./SidebarContext";
import { useState } from "react";
import { ExopenSingleLetterLogo } from "./ExopenSingleLetterLogo";
import { SidebarUserSection } from "./SidebarUserSection";
import { useUser } from "../../common/context/userContextUtils";
import { DrawerContent } from "../../common/components/atoms/drawer/DrawerContent.tsx";
import { Drawer } from "../../common/components/atoms/drawer/Drawer.tsx";
import { ExopenLogo } from "./ExopenLogo.tsx";
import { TooltipTrigger } from "../../common/components/atoms/tooltip/TooltipTrigger.tsx";
import { TooltipContent } from "../../common/components/atoms/tooltip/TooltipContent.tsx";
import { Tooltip } from "../../common/components/atoms/tooltip/Tooltip.tsx";

type RenderParams = {
	sidebarOpen: boolean;
	mobileSidebarOpen: boolean;
};

interface SidebarProps {
	empty?: boolean;
	defaultOpen?: boolean | undefined;
	renderContent?: (params: RenderParams) => React.ReactNode;
	renderFooter?: (params: RenderParams) => React.ReactNode;
}

export const Sidebar = ({
	empty,
	defaultOpen = true,
	renderContent = () => <div className="grow" />,
	renderFooter = () => null,
}: SidebarProps) => {
	const { t, i18n } = useTranslation();
	const user = useUser();
	const { toggleSidebar, open } = useCollapsibleSidebar(defaultOpen);

	const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);

	const isOpen = mobileSidebarOpen ? true : open;

	const sidebarContent = (
		<div
			className={clsx(
				"sticky top-0 h-screen flex-shrink-0 border-gray-200",
				!empty && "border-r bg-white",
				mobileSidebarOpen ? "block" : "hidden sm:block",
				!mobileSidebarOpen && (open ? "w-[320px]" : "w-[72px]"),
			)}
		>
			<div className="flex h-full flex-col">
				<div className={clsx("flex pb-2", isOpen ? "px-4 pt-6" : "px-5 pt-6")}>
					<div className="grow">
						<Link
							to="/"
							className="block max-w-36 grow text-green-500 hover:text-green-600 transition-colors"
						>
							{isOpen ? <ExopenLogo /> : <ExopenSingleLetterLogo />}
						</Link>
					</div>
					{mobileSidebarOpen ? (
						<button
							type="button"
							className="self-start text-gray-500"
							onClick={() => {
								setMobileSidebarOpen(false);
							}}
						>
							<IconX size={24} />
						</button>
					) : open && !empty ? (
						<Tooltip placement="right">
							<TooltipTrigger asChild>
								<button
									type="button"
									onClick={toggleSidebar}
									aria-label={t("Collapse sidebar")}
								>
									<div className="rounded-[6px] p-[10px] hover:bg-gray-50">
										<IconChevronsLeft className="text-purple-500" size={20} />
									</div>
								</button>
							</TooltipTrigger>
							<TooltipContent>{t("Collapse sidebar")}</TooltipContent>
						</Tooltip>
					) : null}
				</div>
				{!isOpen && (
					<div className="px-4 pb-1">
						<Tooltip placement="right">
							<TooltipTrigger asChild>
								<button
									type="button"
									className="block"
									onClick={toggleSidebar}
									aria-label={t("Expand sidebar")}
								>
									<div className="rounded-[6px] p-[10px] hover:bg-gray-50">
										<IconChevronsRight className="text-purple-500" size={20} />
									</div>
								</button>
							</TooltipTrigger>
							<TooltipContent>{t("Expand sidebar")}</TooltipContent>
						</Tooltip>
					</div>
				)}
				{renderContent({ sidebarOpen: isOpen, mobileSidebarOpen })}
				<div
					className={clsx(
						"flex flex-col justify-end border-gray-200 px-4 pb-6 pt-4",
						!empty && "border-t",
					)}
				>
					{isOpen && renderFooter({ sidebarOpen: isOpen, mobileSidebarOpen })}
					<div className="mb-2 space-y-1">
						<NavItem
							to="https://www.exopen.se/kontakta-oss"
							label={t("Support")}
							icon={<IconHelp />}
						/>
					</div>
					<div className="mb-2 space-y-1">
						<NavItem
							to={
								i18n.language === "sv"
									? "https://docs.exopen.se"
									: "https://docs.exopen.se/en"
							}
							label={t("Knowledge base")}
							icon={<IconBooks />}
						/>
					</div>
					{user.user && (
						<>
							<NavItemDivider className="mb-4" />
							<SidebarUserSection sidebarOpen={isOpen} user={user.user} />
						</>
					)}
				</div>
			</div>
		</div>
	);

	return (
		<SidebarContext.Provider value={{ open: isOpen, toggleSidebar }}>
			<Drawer
				open={mobileSidebarOpen}
				onClose={() => {
					setMobileSidebarOpen(false);
				}}
				position="left"
			>
				<DrawerContent>{sidebarContent}</DrawerContent>
			</Drawer>
			{!mobileSidebarOpen && sidebarContent}
			<div className="sticky top-0 z-10 flex h-[64px] items-center border-b border-gray-200 bg-white py-[20px] pl-[20px] pr-3 sm:hidden">
				<div className="grow">
					<Link
						to="/"
						className="block text-green-500 hover:text-green-600 transition-colors"
					>
						<ExopenSingleLetterLogo />
					</Link>
				</div>
				<button
					type="button"
					className="shrink-0 p-2 text-gray-500"
					onClick={() => {
						setMobileSidebarOpen(true);
					}}
				>
					<IconMenu2 size={24} />
				</button>
			</div>
		</SidebarContext.Provider>
	);
};
