import { IconLogout } from "@tabler/icons-react";
import { clsx } from "clsx";
import { useTranslation } from "react-i18next";
import { Avatar } from "../../common/components/atoms/avatar/Avatar";
import type { User } from "../../common/service/Types/UserTypes";
import { SwitchLanguage } from "./SwitchLanguage.js";
import { getGravatarUrl } from "../planning/utils/getGravatarUrl.js";
import { PopoverTrigger } from "components/popoverMenu/PopoverTrigger.js";
import { PopoverContent } from "components/popoverMenu/PopoverContent.js";
import { PopoverMenu } from "components/popoverMenu/menu/PopoverMenu.js";
import { PopoverMenuItem } from "components/popoverMenu/menu/PopoverMenuItem.js";
import { Popover } from "components/popoverMenu/Popover.js";
import { TooltipTrigger } from "components/tooltip/TooltipTrigger.js";
import { TooltipContent } from "components/tooltip/TooltipContent.js";
import { Tooltip } from "components/tooltip/Tooltip.js";
import { logout } from "src/auth0.js";

interface SidebarUserSectionProps {
	sidebarOpen: boolean;
	user: User;
}

export const SidebarUserSection = ({
	sidebarOpen,
	user,
}: SidebarUserSectionProps) => {
	const { t } = useTranslation();

	const avatar = (
		<Avatar
			src={getGravatarUrl(user.email)}
			size="md"
			alt=""
			className="mr-1 align-middle"
		/>
	);

	return (
		<div className={clsx("flex items-center gap-2", sidebarOpen && "pl-2")}>
			<div>
				<Popover role="menu" placement="right-end">
					<PopoverTrigger>{avatar}</PopoverTrigger>
					<PopoverContent>
						<PopoverMenu>
							<PopoverMenuItem
								label={t("Sign out")}
								icon={<IconLogout />}
								onClick={() => {
									logout();
								}}
							/>
						</PopoverMenu>
					</PopoverContent>
				</Popover>
			</div>
			{sidebarOpen && (
				<>
					<div className="grow overflow-hidden">
						<div className="text-sm font-medium text-gray-700 truncate">
							{user.name}
						</div>
						<div className="text-xs truncate text-gray-500">{user.email}</div>
					</div>
					<div className="flex gap-2">
						<SwitchLanguage />
						<Tooltip>
							<TooltipTrigger asChild>
								<button
									aria-label={t("Sign out")}
									className="text-gray-500"
									type="button"
									onClick={() => {
										logout();
									}}
								>
									<IconLogout size={20} />
								</button>
							</TooltipTrigger>
							<TooltipContent>{t("Sign out")}</TooltipContent>
						</Tooltip>
					</div>
				</>
			)}
		</div>
	);
};
