import { IconPencil, IconPlus, IconTrash, IconX } from "@tabler/icons-react";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../common/components/atoms/button/Button.js";
import { HierarchyTable } from "../../../common/components/atoms/hierarchyTable/HierarchyTable.js";
import type {
	Hierarchy,
	HierarchyItem,
} from "../../../common/components/atoms/hierarchyTable/types.js";
import { showErrorNotification } from "../../../common/components/atoms/notifications/events.js";
import {
	Card,
	CardHeader,
	CardItemsHeader,
} from "../../planning/components/Card.js";
import { EmptyState } from "../../planning/components/EmptyState.js";
import { EditGroupModal } from "./EditGroupModal.js";
import { EntityItemsList } from "./EntityItemsList.js";
import { QueryResult } from "../../../common/components/atoms/queryResult/QueryResult.js";
import { RemoveGroupModal } from "./RemoveGroupModal.js";
import { Input } from "../../../common/components/atoms/input/Input.js";
import { clsx } from "clsx";
import { EntityHierarchySearch } from "./EntityHierarchySearch.js";
import { useEntityHierarchy } from "../entityHierarchyProviderUtils";
import { Checkbox } from "../../../common/components/atoms/checkbox/Checkbox.js";
import { Label } from "../../../common/components/atoms/label/Label.js";
import type { LegalEntityEntity } from "src/common/service/nexus/types.js";
import type {
	EntityHierarchyGroup,
	EntityHierarchy,
	EntityHierachyResponse,
} from "src/common/service/nexus/utils/hierarchy.js";
import { useLoadedCompanyDomain } from "src/routes/routeDataHooks.js";

type GroupItem = HierarchyItem & EntityHierarchyGroup;

type EntityHierarchyItem = Hierarchy<GroupItem>;

const mapToHierarchy = (
	hierarchy: EntityHierarchy<LegalEntityEntity, Record<string, never>>,
	{
		getItemLabel,
		getGroupLabel,
	}: {
		getItemLabel: (item: LegalEntityEntity) => string;
		getGroupLabel: (
			hierarchy: EntityHierarchy<LegalEntityEntity, Record<string, never>>,
		) => React.ReactNode;
	},
): EntityHierarchyItem => {
	return {
		...hierarchy,
		label: getGroupLabel(hierarchy),
		children: hierarchy.children.map((child) =>
			mapToHierarchy(child, { getItemLabel, getGroupLabel }),
		),
	};
};

const countNumberOfItemsInHierarchy = (
	hierarchy: EntityHierarchyItem,
): number => {
	return hierarchy.children.reduce(
		(acc, child) => acc + countNumberOfItemsInHierarchy(child),
		hierarchy.items.length,
	);
};

const defaultGetGroupLabel = (
	h: EntityHierarchy<LegalEntityEntity, Record<string, never>>,
) => {
	const numberOfChildren = h.children.length;

	return `${h.name}${numberOfChildren > 0 ? ` (${numberOfChildren})` : ""}`;
};

type TableContentProps = {
	data: EntityHierachyResponse<LegalEntityEntity, Record<string, never>>;
	isEditable: boolean;
	isFetching: boolean;
	highlightedGroupId: string | null;
	onCreateGroup: (parentNode?: GroupItem) => void;
	onEditGroup: (node: GroupItem) => void;
	onRemoveGroup: (node: GroupItem) => void;
	onMoveItems: (items: LegalEntityEntity[]) => void;
};

const TableContent = ({
	data,
	isEditable,
	isFetching,
	highlightedGroupId,
	onCreateGroup,
	onEditGroup,
	onRemoveGroup,
	onMoveItems,
}: TableContentProps) => {
	const companyDomain = useLoadedCompanyDomain();

	const { t } = useTranslation();

	const {
		labels: { entityNamePlural },
		updateGroupMutation: [updateGroup, { isLoading: isUpdatingGroup }],
		getItemLabel,
		parentLegalEntityId: legalEntityId,
		canHaveChildren,
		canBeChildOf,
		getGroupLabel,
		type,
	} = useEntityHierarchy();

	const canBeDropped = useCallback(
		(node: EntityHierarchyItem, parentNode: EntityHierarchyItem | null) => {
			if (parentNode) {
				return canHaveChildren(parentNode) && canBeChildOf(node, parentNode);
			}

			return node.parentId !== null;
		},
		[canBeChildOf, canHaveChildren],
	);

	const handleDrop = useCallback(
		async (
			node: EntityHierarchyItem,
			parentNode: EntityHierarchyItem | null,
		) => {
			const newParentId = parentNode?.id ?? null;

			if (newParentId !== node.parentId && node.id !== parentNode?.id) {
				const result = await updateGroup({
					companyDomainId: companyDomain.id,
					legalEntityId,
					groupId: node.id,
					type,
					group: {
						sortOrder:
							(parentNode?.children.length ?? data.hierarchy.length) * 1000 +
							1000,
						parentId: parentNode?.id ?? null,
					},
				});

				if ("error" in result) {
					showErrorNotification({
						message: t("Failed to update group"),
					});
				}
			}
		},
		[
			companyDomain.id,
			data.hierarchy.length,
			legalEntityId,
			t,
			type,
			updateGroup,
		],
	);

	const handleReorder = useCallback(
		async (
			node: EntityHierarchyItem,
			between: [EntityHierarchyItem | null, EntityHierarchyItem | null],
		) => {
			const [prevSibling, nextSibling] = between;
			const min = prevSibling?.sortOrder ?? 0;
			const max =
				nextSibling?.sortOrder ??
				(prevSibling?.sortOrder ? prevSibling.sortOrder + 1000 : 2000);

			await updateGroup({
				companyDomainId: companyDomain.id,
				legalEntityId,
				groupId: node.id,
				type,
				group: {
					sortOrder: Math.floor((min + max) / 2),
				},
			});
		},
		[companyDomain.id, legalEntityId, type, updateGroup],
	);

	const hierarchy = useMemo(
		() =>
			data.hierarchy.map((item) =>
				mapToHierarchy(item, {
					getItemLabel,
					getGroupLabel: getGroupLabel ?? defaultGetGroupLabel,
				}),
			),
		[data.hierarchy, getGroupLabel, getItemLabel],
	);

	return (
		<HierarchyTable
			title={t("Hierarchy")}
			expandedByDefault={false}
			hierarchy={hierarchy}
			isDraggable={isEditable}
			onReorder={handleReorder}
			onDrop={handleDrop}
			canBeDropped={canBeDropped}
			isLoading={isUpdatingGroup || isFetching}
			highlightedNodeId={highlightedGroupId}
			renderActions={
				isEditable
					? (node) => (
							<>
								{canHaveChildren(node) && (
									<Button
										ariaLabel={t("Add group")}
										variant="ghost"
										onClick={() => {
											onCreateGroup(node);
										}}
										icon={<IconPlus />}
									/>
								)}
								<Button
									ariaLabel={t("Edit group")}
									variant="ghost"
									onClick={() => {
										onEditGroup(node);
									}}
									icon={<IconPencil />}
								/>
								<Button
									ariaLabel={t("Remove group")}
									variant="ghost"
									onClick={() => {
										onRemoveGroup(node);
									}}
									icon={<IconTrash />}
								/>
							</>
						)
					: undefined
			}
			renderAdditionalExpandalbeContent={(node) => {
				if (!node.items.length) {
					return null;
				}

				return (
					<EntityItemsList
						isEditable={isEditable}
						items={node.items}
						onMoveItems={onMoveItems}
					/>
				);
			}}
			columns={[
				{
					id: "count",
					label: t("Number of {{entityNamePlural}}", {
						entityNamePlural,
					}),
					render: (node) => countNumberOfItemsInHierarchy(node),
				},
			]}
			extraRows={
				isEditable
					? [
							<div key="add_group" className="flex w-full justify-center p-3">
								<Button onClick={() => onCreateGroup()}>
									{t("Add group")}
								</Button>
							</div>,
						]
					: undefined
			}
		/>
	);
};

type CreateGroupViewState = {
	type: "createGroup";
	parent: EntityHierarchy<LegalEntityEntity, Record<string, never>> | null;
	position: number;
};

type EditGroupViewState = {
	type: "editGroup";
	parent: EntityHierarchy<LegalEntityEntity, Record<string, never>> | null;
	group: EntityHierarchy<LegalEntityEntity, Record<string, never>>;
};

type RemoveGroupViewState = {
	type: "removeGroup";
	group: EntityHierarchy<LegalEntityEntity, Record<string, never>>;
};

type ViewState =
	| CreateGroupViewState
	| EditGroupViewState
	| RemoveGroupViewState
	| null;

const findNodeInHierarchy = (
	data: EntityHierachyResponse<LegalEntityEntity, Record<string, never>>,
	nodeId: string,
) => {
	const findNode = (
		hierarchy: EntityHierarchy<LegalEntityEntity, Record<string, never>>,
	): EntityHierarchy<LegalEntityEntity, Record<string, never>> | null => {
		if (hierarchy.id === nodeId) {
			return hierarchy;
		}

		for (const child of hierarchy.children) {
			const found = findNode(child);

			if (found) {
				return found;
			}
		}

		return null;
	};

	for (const hierarchy of data.hierarchy) {
		const found = findNode(hierarchy);

		if (found) {
			return found;
		}
	}

	return null;
};

type Props = {
	onMoveItems: (items: LegalEntityEntity[]) => void;
	isEditable: boolean;
	onEdit?: (() => void) | undefined;
};

export const EntityHierarchyTable = ({
	onMoveItems,
	isEditable,
	onEdit,
}: Props) => {
	const [isSearching, setIsSearching] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");

	const [viewState, setViewState] = useState<ViewState>(null);

	const [highlightedGroupId, setHighlightedGroupId] = useState<string | null>(
		null,
	);

	const { t } = useTranslation();

	const {
		labels: { entityNamePlural },
		useHierarchyQuery,
		filterUsed,
		setFilterUsed,
	} = useEntityHierarchy();

	const hierarchyQuery = useHierarchyQuery({
		used: filterUsed || undefined,
	});

	const createEditGroupModalProps = () => {
		if (viewState?.type === "createGroup") {
			return {
				isOpen: true,
				parent: viewState.parent,
				position: viewState.position,
			};
		}

		if (viewState?.type === "editGroup") {
			return {
				isOpen: true,
				parent: viewState.parent,
				group: viewState.group,
			};
		}

		return {
			isOpen: false,
		};
	};

	return (
		<Card className="mt-4">
			<CardHeader className="flex items-center">
				<CardItemsHeader heading={entityNamePlural} />

				<span className="mr-4 flex items-center gap-x-2">
					<Checkbox
						className="p-2"
						checked={filterUsed}
						onChange={() => setFilterUsed(!filterUsed)}
					/>
					<Label className="pt-1.5">
						{t("Show only used {{entityNamePlural}}", { entityNamePlural })}
					</Label>
				</span>
				<Input
					value={searchQuery}
					containerProps={{
						className: clsx(
							isSearching ? "w-1/2" : "w-1/5",
							"transition-width",
						),
					}}
					onChange={(e) => setSearchQuery(e.target.value)}
					type="search"
					placeholder={t("Search")}
					onFocus={() => {
						setIsSearching(true);
						setHighlightedGroupId(null);
					}}
				/>
				{isSearching && (
					<Button
						className={clsx("ml-2")}
						variant="secondaryGray"
						icon={<IconX />}
						onClick={() => {
							setIsSearching(false);
							setSearchQuery("");
							setHighlightedGroupId(null);
						}}
					>
						{t("Clear")}
					</Button>
				)}
				{!isEditable && (
					<Button
						ariaLabel={t("Edit group")}
						variant="ghost"
						size="md"
						onClick={onEdit}
						icon={<IconPencil />}
					/>
				)}
			</CardHeader>
			<EditGroupModal
				{...createEditGroupModalProps()}
				onClose={() => setViewState(null)}
			/>
			<RemoveGroupModal
				isOpen={viewState?.type === "removeGroup"}
				group={viewState?.type === "removeGroup" ? viewState.group : null}
				onClose={() => setViewState(null)}
			/>
			<QueryResult
				query={hierarchyQuery}
				render={(data, { isFetching }) => {
					if (data.hierarchy.length === 0) {
						return (
							<EmptyState
								header={t("No hierarchy")}
								subHeader={t(
									"Create a hierarchy to group your {{entityNamePlural}}",
									{
										entityNamePlural,
									},
								)}
								createButton={
									<Button
										variant="primary"
										onClick={() => {
											setViewState({
												type: "createGroup",
												parent: null,
												position: 0,
											});
										}}
									>
										{t("Create hierarchy")}
									</Button>
								}
							/>
						);
					}

					if (isSearching) {
						return (
							<div className="p-4">
								<EntityHierarchySearch
									searchQuery={searchQuery}
									hierarchy={data}
									isEditable={isEditable}
									onMoveItems={onMoveItems}
									onGroupClick={(group) => {
										setIsSearching(false);
										setHighlightedGroupId(group.id);
									}}
								/>
							</div>
						);
					}

					return (
						<TableContent
							data={data}
							isEditable={isEditable}
							isFetching={isFetching}
							highlightedGroupId={highlightedGroupId}
							onCreateGroup={(parentNode) => {
								const parentNodeObj = parentNode?.id
									? findNodeInHierarchy(data, parentNode.id)
									: null;

								setViewState({
									type: "createGroup",
									parent: parentNodeObj,
									position:
										parentNodeObj?.children.length ?? data.hierarchy.length,
								});
							}}
							onEditGroup={(node) => {
								const parent = node.parentId
									? findNodeInHierarchy(data, node.parentId)
									: null;

								const found = findNodeInHierarchy(data, node.id);

								if (found) {
									setViewState({
										type: "editGroup",
										parent,
										group: found,
									});
								}
							}}
							onRemoveGroup={(node) => {
								const found = findNodeInHierarchy(data, node.id);

								if (found) {
									setViewState({
										type: "removeGroup",
										group: found,
									});
								}
							}}
							onMoveItems={onMoveItems}
						/>
					);
				}}
			/>
		</Card>
	);
};
