import { useMergeRefs } from "@floating-ui/react";
import {
	forwardRef,
	isValidElement,
	cloneElement,
	type Ref,
	type ComponentProps,
	type ReactNode,
} from "react";
import { useTooltipContext } from "./TooltipContext.js";
import type { Except } from "type-fest";
import clsx from "clsx";

interface TooltipTriggerProps {
	children: ReactNode | ((options: Record<string, unknown>) => JSX.Element);
	asChild?: boolean;
}

export const TooltipTrigger = forwardRef<
	HTMLElement,
	Except<ComponentProps<"button">, "children" | "type"> & TooltipTriggerProps
>(function TooltipTrigger({ children, asChild = false, ...props }, propRef) {
	const context = useTooltipContext();
	const childrenRef = (
		typeof children === "object" && children !== null && "ref" in children
			? children.ref
			: null
	) as Ref<unknown>;
	const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

	if (typeof children === "function") {
		return children(
			context.getReferenceProps({
				ref,
				...props,
			}),
		);
	}

	if (asChild && isValidElement(children)) {
		return cloneElement(
			children,
			context.getReferenceProps({
				ref,
				...props,
				...children.props,
			}),
		);
	}

	const { className, ...referenceProps } = context.getReferenceProps(props);

	return (
		<button
			ref={ref}
			type="button"
			className={clsx(
				"cursor-default",
				typeof className === "string" ? className : null,
			)}
			{...referenceProps}
		>
			{children}
		</button>
	);
});
