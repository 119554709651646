import { skipToken } from "@reduxjs/toolkit/query";
import { useMemo } from "react";
import { useParams } from "react-router";
import type { CompanyDomain } from "src/common/company-domain/types";
import { exopenApi } from "src/common/redux/api/exopenApi";
import { fusionHubApi } from "src/common/service/fusionHub/fusionHubApi";
import { fortnoxApi } from "src/common/service/fusionHub/integrations/fortnoxApi";
import { nexusApi } from "src/common/service/nexus/nexusApi";
import { planningApi } from "src/features/planning/api/planningApi";

export const useMaybeLoadedCompanyDomain = () => {
	const { companyDomainId } = useParams();
	const { data } = exopenApi.endpoints.getCompany.useQueryState(
		companyDomainId
			? {
					companyId: companyDomainId,
				}
			: skipToken,
	);
	return data;
};

export const useLoadedCompanyDomain = () => {
	const companyDomain = useMaybeLoadedCompanyDomain();

	if (!companyDomain) {
		throw new Error("Company domain not found");
	}

	return useMemo(
		(): CompanyDomain => ({
			...companyDomain.company,
			id: `${companyDomain.company.id}`,
			hasDataLayer: companyDomain.hasDataLayer,
			ewr: companyDomain.ewr,
			pumps: companyDomain.pumps,
			leMasterKeyMappings: companyDomain.leMasterKeyMappings,
			ewrPackages: companyDomain.ewrPackages,
		}),
		[companyDomain],
	);
};

export const useLoadedUser = () => {
	const { data } = exopenApi.endpoints.getCurrentUser.useQueryState();

	if (!data) {
		throw new Error("User not found");
	}

	return data;
};

export const useLoadedCompanyDomains = () => {
	const { data } = exopenApi.endpoints.getAllCompanies.useQueryState();

	if (!data) {
		throw new Error("Company domains not found");
	}

	return data;
};

export const useLoadedLegalEntities = () => {
	const companyDomain = useLoadedCompanyDomain();
	const { data } = nexusApi.endpoints.getLegalEntities.useQueryState(
		companyDomain.id,
	);

	if (!data) {
		throw new Error("Legal entities not found");
	}

	return data;
};

export const useLoadedLegalEntity = () => {
	const companyDomain = useLoadedCompanyDomain();
	const { legalEntityId } = useParams();
	const { data } = nexusApi.endpoints.getLegalEntity.useQueryState({
		companyDomainId: companyDomain.id,
		legalEntityId: legalEntityId!,
	});

	if (!data) {
		throw new Error("Legal entity not found");
	}

	return data;
};

export const useLoadedCompanyHierarchy = () => {
	const companyDomain = useLoadedCompanyDomain();
	const { data } = nexusApi.endpoints.getCompanyHierarchy.useQueryState(
		companyDomain.id,
	);

	if (!data) {
		throw new Error("Company hierarchy not found");
	}

	return data;
};

export const useLoadedPipelineRuns = () => {
	const companyDomain = useLoadedCompanyDomain();
	const { data } = exopenApi.endpoints.getPipelineRuns.useQueryState({
		companyId: companyDomain.id,
	});

	if (!data) {
		throw new Error("Pipeline runs not found");
	}

	return data;
};

export const useLoadedFusionHubIntegrations = () => {
	const companyDomain = useLoadedCompanyDomain();
	const { data } = fusionHubApi.endpoints.getAllIntegrations.useQueryState({
		companyDomainId: companyDomain.id,
	});

	if (!data) {
		throw new Error("FusionHub integrations not found");
	}

	return data;
};

export const useLoadedNexusIntegrations = () => {
	const companyDomain = useLoadedCompanyDomain();
	const { data } = nexusApi.endpoints.getIntegrations.useQueryState({
		companyDomainId: companyDomain.id,
	});

	if (!data) {
		throw new Error("Nexus integrations not found");
	}

	return data;
};

export const useLoadedFortnoxStatus = () => {
	const { data } = fortnoxApi.endpoints.getStatus.useQueryState();

	if (!data) {
		throw new Error("Fortnox status not found");
	}

	return data;
};

export const useLoadedPlanCostCenters = () => {
	const companyDomain = useLoadedCompanyDomain();
	const { planId } = useParams();
	const { data } = planningApi.endpoints.getOrganizationalUnit.useQueryState({
		companyDomainId: companyDomain.id,
		planId: planId!,
	});

	if (!data) {
		throw new Error("Cost centers not found");
	}

	return data;
};
