import { type SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDocumentTitle } from "src/common/utils/hooks/useDocumentTitle";
import { VismaNetDimensionSection } from "./VismaNetDimensionSection.js";
import { Stepper } from "components/stepper/Stepper";
import { useSteps } from "components/stepper/useSteps";
import { AddDataSourcePage } from "../../confirmConnector/AddDataSourcePage.js";
import vismaLogo from "../../addConnector/visma/visma-logo.png";
import { AddDataSourceNavigation } from "../../confirmConnector/AddDataSourceNavigation.js";
import { AddDataSourceCompanyDomainSection } from "../../confirmConnector/AddDataSourceCompanyDomainSection.js";
import {
	useAddVismaNetPumpMutation,
	useCreateVismaNetCompanyMutation,
	useStartMainPipelineMutation,
} from "../../../../common/redux/api/exopenApi.js";
import {
	showErrorNotification,
	showSuccessNotification,
} from "components/notifications/events";
import {
	getDefaultValuesForAddDataSource,
	type AddDataSourceInputs,
} from "../../confirmConnector/schema.js";
import { useLoadedCompanyDomains } from "src/routes/routeDataHooks.js";
import { useNavigate } from "react-router";

interface VismaNetAddDataSourceInputs extends AddDataSourceInputs {
	tenantId: string;
	costCenterId: number | null;
	projectId: number | null;
}

const getDefaultValues = (
	operation: AddDataSourceInputs["operation"],
): VismaNetAddDataSourceInputs => {
	return {
		...getDefaultValuesForAddDataSource(operation),
		tenantId: "",
		costCenterId: null,
		projectId: null,
	};
};

export const AddVismaNetConnectorRedirect = () => {
	const { t } = useTranslation();
	useDocumentTitle(t("Add data source"));
	const navigate = useNavigate();
	const [createVismaNetCompany] = useCreateVismaNetCompanyMutation();
	const [addPump] = useAddVismaNetPumpMutation();
	const [startMainPipeline] = useStartMainPipelineMutation();

	const { activeStep, nextStep, prevStep } = useSteps({
		initialStep: 0,
	});
	const companyDomains = useLoadedCompanyDomains();
	const form = useForm({
		defaultValues: getDefaultValues(
			companyDomains.length === 0 ? "new" : "add",
		),
	});

	const submitHandler: SubmitHandler<VismaNetAddDataSourceInputs> = async (
		data,
	) => {
		if (data.operation === "add" && data.companyDomainId) {
			const result = await addPump({
				companyDomainId: data.companyDomainId,
				tenantId: data.tenantId,
				costCenterId: data.costCenterId,
				projectId: data.projectId,
			});
			if ("error" in result) {
				showErrorNotification({ message: t("Failed to add data source") });
			} else {
				const result = await startMainPipeline({
					companyId: data.companyDomainId,
				});
				if ("error" in result) {
					showErrorNotification({
						message: t(
							"Failed to start the main pipeline. You can trigger this manually in the data sources page",
						),
					});
				} else {
					showSuccessNotification({
						message: t("The data source was saved"),
					});
					navigate(`/company-domains/${data.companyDomainId}/data-sources`);
				}
			}
		}

		if (data.operation === "new") {
			const result = await createVismaNetCompany({
				companyName: data.companyDomainName,
				config: {
					tenantId: data.tenantId,
					subAccountSegmentIds: {
						costCenterId: data.costCenterId,
						projectId: data.projectId,
					},
					startPump: true,
				},
			});
			if ("error" in result) {
				showErrorNotification({
					message: t("Something failed..."),
				});
			} else {
				navigate(`/company-domains/${result.data.id}/data-sources`);
			}
		}
	};

	return (
		<form onSubmit={form.handleSubmit(submitHandler)}>
			<AddDataSourcePage
				title={t("Add {{name}} as a data source", { name: "VismaNet" })}
				logo={vismaLogo}
			>
				<Stepper step={activeStep} size="sm" className="my-4">
					<Stepper.Step label={t("Tenant & dimensions")}>
						<AddDataSourceNavigation
							form={form}
							onNextStep={nextStep}
							disableOnNextStep={!form.watch("tenantId")}
						>
							<VismaNetDimensionSection form={form} />
						</AddDataSourceNavigation>
					</Stepper.Step>
					<Stepper.Step label={t("Select company domain")}>
						<AddDataSourceNavigation
							form={form}
							onBack={prevStep}
							onSubmit
							disableOnSubmit={
								!form.watch("approve") ||
								(!form.watch("companyDomainName") &&
									!form.watch("companyDomainId"))
							}
						>
							<AddDataSourceCompanyDomainSection
								form={form}
								companyDomains={companyDomains}
							/>
						</AddDataSourceNavigation>
					</Stepper.Step>
				</Stepper>
			</AddDataSourcePage>
		</form>
	);
};
