import { clsx } from "clsx";
import type { ComponentPropsWithoutRef } from "react";
import { circleLoaderSizes } from "./circleLoaderSizes";

interface GetSize {
	size: string | number;
	sizes: Record<string, number>;
}

function getSize(props: GetSize) {
	if (typeof props.size === "number") {
		return props.size;
	}

	const computedSize = props.sizes[props.size];

	return computedSize ?? (props.size || props.sizes.md);
}

interface CircleLoaderProps extends ComponentPropsWithoutRef<"svg"> {
	size?: keyof typeof circleLoaderSizes;
}

export const CircleLoader = ({
	size,
	className,
	...others
}: CircleLoaderProps) => {
	return (
		<svg
			width={`${getSize({ size: size!, sizes: circleLoaderSizes })}px`}
			height={`${getSize({ size: size!, sizes: circleLoaderSizes })}px`}
			viewBox="0 0 38 38"
			xmlns="http://www.w3.org/2000/svg"
			className={clsx("stroke-current text-purple-500", className)}
			{...others}
		>
			<g fill="none" fillRule="evenodd">
				<g transform="translate(2.5 2.5)" strokeWidth="5">
					<circle strokeOpacity=".5" cx="16" cy="16" r="16" />
					<path d="M32 16c0-9.94-8.06-16-16-16">
						<animateTransform
							attributeName="transform"
							type="rotate"
							from="0 16 16"
							to="360 16 16"
							dur="1s"
							repeatCount="indefinite"
						/>
					</path>
				</g>
			</g>
		</svg>
	);
};
