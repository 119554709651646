import { useState, useEffect } from "react";

export let setSidebarOpenStatus: undefined | ((value: boolean) => void);

export const useCollapsibleSidebar = (defaultOpen: boolean) => {
	const [sidebarOpen, setSidebarOpen] = useState(() => {
		const storedState = sessionStorage.getItem("sidebarOpen");
		if (
			storedState !== null &&
			(storedState === "true" || storedState === "false")
		) {
			return storedState === "true";
		}
		return defaultOpen;
	});

	useEffect(() => {
		setSidebarOpenStatus = (value) => {
			setSidebarOpen(value);
		};
		return () => {
			setSidebarOpenStatus = undefined;
		};
	}, []);

	const toggleSidebar = () => {
		setSidebarOpen(!sidebarOpen);
	};

	useEffect(() => {
		sessionStorage.setItem("sidebarOpen", `${sidebarOpen}`);
	}, [sidebarOpen]);

	return { toggleSidebar, open: sidebarOpen };
};
