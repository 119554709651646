import { clsx } from "clsx";
import type { ReactNode } from "react";
import { PopoverMenuContext } from "./PopoverContext";
import { PopoverContent } from "./PopoverContent.js";
import { PopoverTrigger } from "./PopoverTrigger.js";
import { type UsePopoverOptions, usePopover } from "./usePopover.js";

interface ContentContainerProps extends React.HTMLProps<HTMLDivElement> {
	children: ReactNode;
}

const ContentContainer = ({
	children,
	className,
	...rest
}: ContentContainerProps) => (
	<div
		{...rest}
		className={clsx(
			"h-full overflow-hidden rounded-lg border border-gray-300 bg-white shadow-2xl",
			className,
		)}
	>
		{children}
	</div>
);

type PopoverProps = {
	children: ReactNode;
} & Omit<UsePopoverOptions, "controlledOpen" | "controlledSetOpen"> & {
		open?: boolean;
		setOpen?: (value: boolean) => void;
	};

export const Popover = ({
	children,
	role = "menu",
	...props
}: PopoverProps) => {
	const { open, setOpen, ...popoverProps } = props;
	const context = usePopover({
		...popoverProps,
		role,
		controlledOpen: open,
		controlledSetOpen: setOpen,
	});

	return (
		<PopoverMenuContext.Provider value={context}>
			{children}
		</PopoverMenuContext.Provider>
	);
};

Popover.Trigger = PopoverTrigger;
Popover.Content = PopoverContent;
Popover.ContentContainer = ContentContainer;
