import type {
	DomainIncomingBalance,
	DomainAccount,
} from "@exopengithub/planning-api-shared";
import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Pagination } from "../../../common/components/atoms/pagination/Pagination.tsx";
import { sortArrayByAttribute } from "../../../common/utils/sortArrayByAttribute.ts";
import { EmptyState } from "../components/EmptyState.tsx";
import { useAmountFormatter } from "../utils/amountFormat/AmountFormatContext.ts";
import { arrayToMapByKey } from "../../../common/utils/array-utils/arrayToMapByKey.ts";
import { GridContainer } from "../scenario/common/grid/GridContainer.tsx";
import {
	GridHeaderLabel,
	GridHeaderLabelWithSortingButton,
} from "../scenario/common/grid/GridHeader.tsx";
import { stringSort, useSort } from "../../../common/utils/hooks/useSort.ts";
import { TableContainer } from "../components/TableContainer.tsx";
import { SwitchField } from "../../../common/components/atoms/checkbox/SwitchField.tsx";
import { Input } from "../../../common/components/atoms/input/Input.tsx";
import { Button } from "../../../common/components/atoms/button/Button.tsx";
import { IconPencil } from "@tabler/icons-react";
import { EditIncomingBalanceDialog } from "./EditIncomingBalanceDialog.tsx";
import { getAccountDisplayName } from "../utils/getAccountName.ts";

const ROWS_PER_PAGE = 50;

export const IncomingBalanceTable = ({
	incomingBalances,
	accounts,
	readOnly,
}: {
	incomingBalances: DomainIncomingBalance[];
	accounts: DomainAccount[];
	readOnly: boolean;
}) => {
	const { t } = useTranslation();
	const [page, setPage] = useState(1);
	const [textFilter, setTextFilter] = useState("");
	const [onlyWithAmount, setOnlyWithAmount] = useState(false);

	const accountById = arrayToMapByKey(accounts, "id");

	const incomingBalancesWithAccount = useMemo(() => {
		return sortArrayByAttribute(
			incomingBalances.map((incomingBalance) => ({
				...incomingBalance,
				account: accountById.get(incomingBalance.accountId),
			})),
			(incomingBalance) => incomingBalance.account?.accountNumber ?? "",
		).filter(
			(
				incomingBalance,
			): incomingBalance is DomainIncomingBalance & {
				account: DomainAccount;
			} => {
				return Boolean(incomingBalance.account);
			},
		);
	}, [incomingBalances, accountById]);

	const { items: sortedItems, sortColumnProps } = useSort({
		keys: ["byAccount", "byAmount"],
		defaultKey: "byAccount",
		defaultOrder: "asc",
		rows: incomingBalancesWithAccount,
		columns: {
			byAccount: {
				sort(a, b) {
					return stringSort(a.account.accountNumber, b.account.accountNumber);
				},
			},
			byAmount: {
				key: "amount",
			},
		},
	});

	const filteredItems = useMemo(() => {
		return sortedItems.filter((incomingBalance) => {
			const account = incomingBalance.account;

			const textFilterMatch =
				account.accountName
					.toLowerCase()
					.includes(textFilter.trim().toLowerCase()) ||
				account.accountNumber
					.toLowerCase()
					.includes(textFilter.trim().toLowerCase());

			if (onlyWithAmount) {
				return textFilterMatch && Number(incomingBalance.amount) !== 0;
			}

			return textFilterMatch;
		});
	}, [onlyWithAmount, sortedItems, textFilter]);

	const formatAmount = useAmountFormatter();

	if (incomingBalances.length === 0) {
		return <EmptyState header={t("No incoming balances found")} />;
	}

	const paginatedItems = filteredItems.slice(
		(page - 1) * ROWS_PER_PAGE,
		page * ROWS_PER_PAGE,
	);

	return (
		<div>
			<div className="mb-4 flex items-center gap-4">
				<Input
					placeholder={t("Filter")}
					value={textFilter}
					onChange={(event) => {
						setTextFilter(event.target.value);
						setPage(1);
					}}
					clearable
				/>
				<SwitchField
					label={t("Only with amount")}
					checked={onlyWithAmount}
					onChange={(event) => {
						setOnlyWithAmount(event.target.checked);
						setPage(1);
					}}
				/>
			</div>
			<div>
				{paginatedItems.length === 0 ? (
					<div className="flex flex-col items-center gap-2">
						<div className="font-medium">
							{t("No results matched your search.")}
						</div>
						<Button
							variant="ghost"
							onClick={() => {
								setTextFilter("");
								setOnlyWithAmount(false);
							}}
						>
							{t("Clear filters")}
						</Button>
					</div>
				) : (
					<>
						<TableContainer className="mb-4">
							<GridContainer templateColumns="minmax(0, 1fr) auto min-content">
								<div className="contents" role="row">
									<GridHeaderLabelWithSortingButton
										sortingProps={sortColumnProps.byAccount}
									>
										{t("Account")}
									</GridHeaderLabelWithSortingButton>
									<GridHeaderLabelWithSortingButton
										align="right"
										sortingProps={sortColumnProps.byAmount}
									>
										{t("Amount")}
									</GridHeaderLabelWithSortingButton>
									<GridHeaderLabel />
								</div>
								{paginatedItems.map((item) => {
									const name = getAccountDisplayName(item.account);
									return (
										<div
											key={item.account.id}
											className="group contents"
											role="row"
										>
											<div
												title={name}
												className="flex items-center border-b px-3 py-2 group-last:border-b-0 group-hover:bg-gray-100"
											>
												<div className="truncate">{name}</div>
											</div>
											<div className="flex items-center justify-end border-b px-3 py-2 tabular-nums tracking-tighter group-last:border-b-0 group-hover:bg-gray-100">
												{formatAmount(Math.round(item.amount))}
											</div>
											<div className="flex items-center border-b p-1 group-last:border-b-0 group-hover:bg-gray-100">
												<EditIncomingBalanceDialog incomingBalance={item}>
													<Button
														variant="ghost"
														size="sm"
														disabled={readOnly}
														icon={<IconPencil />}
														ariaLabel={t("Edit")}
													/>
												</EditIncomingBalanceDialog>
											</div>
										</div>
									);
								})}
							</GridContainer>
						</TableContainer>

						<Pagination
							totalPages={Math.ceil(filteredItems.length / ROWS_PER_PAGE)}
							page={page}
							onChange={setPage}
						/>
					</>
				)}
			</div>
		</div>
	);
};
