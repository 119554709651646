import { Controller, type UseFormReturn } from "react-hook-form";
import { CheckboxField } from "../../../../common/components/atoms/checkbox/CheckboxField.tsx";
import {
	showErrorNotification,
	showSuccessNotification,
} from "../../../../common/components/atoms/notifications/events.tsx";
import { useTranslation } from "react-i18next";
import { useUpdateUserMutation } from "../../api/planUserApi.ts";
import { useCurrentPlanOrThrow } from "../../useCurrentPlan.ts";
import type {
	DomainUser,
	AddUserToPlanBody,
} from "@exopengithub/planning-api-shared";
import { CostCenterPermissions } from "./CostCenterPermissions.tsx";
import type { EditPlanUserFormValues } from "./EditPlanUserDialog.tsx";
import { hasFeature } from "../../../../featureFlagUtils.ts";

export const EditPlanUserForm = ({
	onSuccessfulSubmit,
	permissions,
	user,
	formId,
	form,
}: {
	onSuccessfulSubmit: () => void;
	user: DomainUser | null;
	permissions: {
		label: string;
		value: AddUserToPlanBody["permissions"][number];
	}[];
	formId: string;
	form: UseFormReturn<EditPlanUserFormValues>;
}) => {
	const { t } = useTranslation();
	const [edit] = useUpdateUserMutation();
	const plan = useCurrentPlanOrThrow();

	return (
		<form
			id={formId}
			onSubmit={form.handleSubmit(async (data) => {
				const result = await edit({
					companyDomainId: plan.companyId,
					legalEntityId: plan.nexusLegalEntityId,
					planId: plan.id,
					body: {
						permissions: data.permissions,
						allCostCenters: true,
						costCenterIds: [],
					},
					userId: user!.explicitUserId!,
				});
				if ("error" in result) {
					showErrorNotification({
						message: t("Something failed..."),
					});
				} else {
					showSuccessNotification({
						message: t("The changes are saved"),
					});
					onSuccessfulSubmit();
				}
			})}
		>
			{user && (
				<div>
					{t("You are editing the permissions of {{user}}.", {
						user: user.name,
					})}
				</div>
			)}
			<div className="mb-1 pt-4 font-medium">{t("Permissions")}</div>
			<Controller
				control={form.control}
				name="permissions"
				render={({ field }) => {
					return (
						<div role="group">
							{permissions.map(({ value, label }) => {
								return (
									<CheckboxField
										key={value}
										label={label}
										name="permissions"
										value={value}
										checked={field.value.includes(value)}
										onChange={(event) => {
											if (event.target.checked) {
												field.onChange([...field.value, event.target.value]);
											} else {
												field.onChange(
													field.value.filter(
														(value) => value !== event.target.value,
													),
												);
											}
										}}
									/>
								);
							})}
						</div>
					);
				}}
			/>
			{hasFeature("costCenterPermissions") && (
				<CostCenterPermissions form={form} />
			)}
		</form>
	);
};
