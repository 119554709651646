import {
	createBrowserRouter,
	Navigate,
	Outlet,
	data,
	isRouteErrorResponse,
	useRouteError,
	useParams,
	type RouteObject,
} from "react-router";
import { ProtectedApp } from "../ProtectedApp.js";
import { CompanyDashboard } from "../features/companyDashboard/CompanyDashboard.js";
import { BusinessCentralPump } from "../features/connector/addConnector/businessCentral/BusinessCentralPump.js";
import { XledgerPump } from "../features/connector/addConnector/xledger/XledgerPump.js";
import { Reports } from "../features/ewrReports/EwrReports.js";
import { Home } from "../features/home/Home.js";
import { LegacyInvoice } from "../features/invoice/LegacyInvoice.js";
import { Sidebar } from "../features/sidebar/Sidebar.js";
import { AccessGroupTable } from "../features/usersAndGroups/AccessGroupTable.js";
import { UserTable } from "../features/usersAndGroups/UserTable.js";
import { SuperAdminRoute, AuthRoute } from "./AuthRoute.js";
import { CompanyRouteLayout } from "./CompanyRouteLayout.js";
import { AppFrame, MainFrame } from "./ProtectedRoutes.js";
import { lazy } from "../common/utils/lazy.js";
import { PlanPageWrapper } from "../features/planning/PlanPageWrapper.js";
import { GroupHierarchyCard } from "../features/data-perspectives/legal-entities/GroupHierarchyCard.js";
import { BalanceSheetHierarchyPage } from "../features/data-perspectives/pages/BalanceSheetPage.js";
import { CostCenterHierarchyPage } from "../features/data-perspectives/pages/CostCenterHierarchyPage.js";
import { CustomerHierarchyPage } from "../features/data-perspectives/pages/CustomerHierarchyPage.js";
import { PlanningUsersPage } from "../features/planning/settings/users/PlanningUsersPage.js";
import { ConnectConnectorPage } from "../features/connector/connectConnector/ConnectConnectorPage.js";
import { AddVismaNetConnectorRedirect } from "../features/connector/addConnector/visma/AddVismaNetConnectorRedirect.js";
import { ActivateFortnoxPage } from "../features/connector/addConnector/fortnox/ActivateFortnoxPage.js";
import { AddFortnoxConnectorExternalRedirect } from "../features/connector/addConnector/fortnox/AddFortnoxConnectorExternalRedirect.js";
import { Documentation } from "../features/connector/connectors/Documentation.js";
import { IncomingBalancePage } from "../features/planning/incoming-balance/IncomingBalancePage.js";
import { GeneralSettingsPage } from "../features/planning/settings/GeneralSettingsPage.js";
import { PLANNING_DEFINITIONS_TAB_ID } from "./routerConstants.js";
import { SettingsPage } from "../features/settings/SettingsPage.js";
import { CompanyDomainSettingsPage } from "../features/settings/CompanyDomainSettingsPage.js";
import { FortnoxExternalCallbackPage } from "../features/connector/addConnector/fortnox/FortnoxExternalCallbackPage.js";
import { PageNotFound } from "src/features/appError/PageNotFound.js";
import { COMPANYADMIN, SUPERADMIN } from "src/common/service/users.js";
import { exopenApi } from "src/common/redux/api/exopenApi.js";
import { nexusApi } from "src/common/service/nexus/nexusApi.js";
import {
	createLoaderWithAuthCheck,
	dataStrategy,
	dispatchUnloadEvents,
	ensureRoutesHaveLoaderFunction,
	subscribeToQuery,
} from "src/routes/routerUtils.js";
import { CircleLoader } from "components/loadingState/CircleLoader.js";
import { EmailVerification } from "src/features/emailVerification/EmailVerification.js";
import { fusionHubApi } from "src/common/service/fusionHub/fusionHubApi.js";
import { fortnoxApi } from "src/common/service/fusionHub/integrations/fortnoxApi.js";
import { planningApi } from "src/features/planning/api/planningApi.js";
import { LegalEntityDetailLayout } from "src/features/legalEntities/LegalEntityDetailLayout.js";
import { AccountHierarchyLayout } from "src/features/data-perspectives/pages/AccountHierarchyLayout.js";
import { LegalEntitiesPage } from "src/features/legalEntities/LegalEntitiesPage.js";
import { InvoicesLayout } from "src/features/invoice/InvoicesLayout.js";
import { VismaEaccountingExternalCallbackPage } from "src/features/connector/addConnector/visma-eaccounting/VismaEaccountingExternalCallbackPage.js";

const { DevToolsPage } = lazy(() => import("../features/devTools/DevTools.js"));
const { JobListingPage } = lazy(
	() => import("../features/connector/jobs/JobListingPage.js"),
);
const { ConnectorDetailPage } = lazy(
	() => import("../features/connector/connectors/ConnectorDetailPage.js"),
);
const { JobDetailPage } = lazy(
	() => import("../features/connector/jobs/JobDetailPage.js"),
);
const { SupportTools } = lazy(
	() => import("src/features/devTools/SupportTools.js"),
);
const { Pumps } = lazy(() => import("src/features/devTools/Pumps.js"));
const { LegalEntities } = lazy(
	() => import("src/features/devTools/LegalEntities.js"),
);
const { FakePumps } = lazy(() => import("src/features/devTools/FakePumps.js"));
const { MartManagement } = lazy(
	() => import("src/features/devTools/MartManagement.js"),
);
const { PlanningAdminCP } = lazy(
	() => import("src/features/planning/PlanningAdminCP.js"),
);
const { AzureResourceTools } = lazy(
	() => import("src/features/devTools/AzureResourceTools.js"),
);
const { DLMTools } = lazy(() => import("src/features/devTools/DLMTools.js"));
const { TemplateApps } = lazy(
	() => import("src/features/devTools/TemplateApps.js"),
);
const { DevtoolIntegrationsPage } = lazy(
	() => import("src/features/devTools/DevtoolIntegrationsPage.js"),
);
const { CustomerInvoicesPage } = lazy(
	() => import("src/features/invoice/CustomerInvoicesPage.js"),
);
const { SupplierInvoicesPage } = lazy(
	() => import("src/features/invoice/SupplierInvoicesPage.js"),
);
const { IncomeStatementHierarchyPage } = lazy(
	() => import("../features/data-perspectives/pages/IncomeStatementPage.js"),
);
const { ProductHierarchyPage } = lazy(
	() => import("../features/data-perspectives/pages/ProductHierarchyPage.js"),
);
const { ProjectHierarchyPage } = lazy(
	() => import("../features/data-perspectives/pages/ProjectHierarchyPage.js"),
);
const { SupplierHierarchyPage } = lazy(
	() => import("../features/data-perspectives/pages/SupplierHierarchyPage.js"),
);
const { CreatePlanPage } = lazy(
	() => import("../features/planning/plans/CreatePlanPage/CreatePlanPage.js"),
);
const { PlanDetailLayout } = lazy(
	() => import("../features/planning/PlanDetailLayout.js"),
);
const { PlanDetailPage } = lazy(
	() => import("../features/planning/tasks/TaskDetailPage/PlanDetailPage.js"),
);
const { PlanningOverviewPage } = lazy(
	() => import("../features/planning/plans/PlanningOverviewPage.js"),
);
const { AssumptionsTab } = lazy(
	() => import("../features/planning/tasks/TaskDetailPage/AssumptionsTab.js"),
);
const { DefinitionsTabs } = lazy(
	() => import("../features/planning/tasks/TaskDetailPage/DefinitionsTab.js"),
);
const { AssetRegisters } = lazy(
	() => import("../features/planning/modules/assets/AssetRegisters.js"),
);
const { FinanceRegisters } = lazy(
	() => import("../features/planning/modules/finance/FinanceRegisters.js"),
);
const { FinancialAssetTypes } = lazy(
	() =>
		import(
			"../features/planning/modules/finance/financialAssetType/FinancialAssetTypes.js"
		),
);
const { EmployeeGroupPage } = lazy(
	() =>
		import(
			"../features/planning/modules/employee/register/employeeGroups/EmployeeGroupPage.js"
		),
);
const { ConsultancyRegisters } = lazy(
	() =>
		import(
			"../features/planning/modules/consultancy/register/ConsultancyRegisters.js"
		),
);
const { ConsultantTypes } = lazy(
	() =>
		import(
			"../features/planning/modules/consultancy/register/consultant-types/ConsultantTypes.js"
		),
);
const { CustomerInvoicePage } = lazy(
	() => import("src/features/invoice/CustomerInvoicePage.js"),
);
const { SupplierInvoicePage } = lazy(
	() => import("src/features/invoice/SupplierInvoicePage.js"),
);
const { CopyPlanPage } = lazy(
	() => import("../features/planning/plans/CopyPlanPage.js"),
);
const { PlanningSettingsPage } = lazy(
	() => import("../features/planning/settings/PlanningSettingsPage.js"),
);
const { HolidaysPage } = lazy(
	() =>
		import("../features/planning/settings/general/holidays/HolidaysPage.js"),
);
const { CostCenters } = lazy(
	() => import("../features/planning/settings/dim/costCenters/CostCenters.js"),
);
const { Customers } = lazy(
	() => import("../features/planning/settings/dim/Customers.js"),
);
const { Products } = lazy(
	() => import("../features/planning/settings/dim/Products.js"),
);
const { Projects } = lazy(
	() => import("../features/planning/settings/dim/Projects.js"),
);
const { Suppliers } = lazy(
	() => import("../features/planning/settings/dim/Suppliers.js"),
);
const { Dimensions } = lazy(
	() => import("../features/planning/registers/Dimensions.js"),
);
const { DebitRateKeys } = lazy(
	() =>
		import(
			"../features/planning/modules/consultancy/register/debit-rate-keys/DebitRateKeys.js"
		),
);
const { AccountLinks } = lazy(
	() => import("../features/planning/registers/accountLink/AccountLinks"),
);
const { AssetTypes } = lazy(
	() => import("../features/planning/modules/assets/assetType/AssetTypes.js"),
);
const { DriversRegisterPage } = lazy(
	() =>
		import("../features/planning/modules/driver/registers/DriversRegisterPage"),
);
const { PersonnelAgreements } = lazy(
	() =>
		import(
			"../features/planning/modules/employee/register/personnelAgreement/PersonnelAgreements"
		),
);
const { VacationKeys } = lazy(
	() =>
		import(
			"../features/planning/modules/employee/register/vacationKeys/VacationKeys"
		),
);
const { ServiceLevelKeys } = lazy(
	() =>
		import(
			"../features/planning/modules/employee/register/serviceLevelKeys/ServiceLevelKeys"
		),
);
const { VatSettings } = lazy(
	() =>
		import("../features/planning/settings/general/vatSetting/VatSettings.js"),
);

const { AllocationKeys } = lazy(
	() => import("../features/planning/registers/allocationKeys/AllocationKeys"),
);
const { AccountRecipes } = lazy(
	() => import("../features/planning/registers/accountRecipes/AccountRecipes"),
);
const { AssetPage } = lazy(
	() => import("../features/planning/modules/assets/assetType/AssetPage.js"),
);
const { FinancialAssetPage } = lazy(
	() =>
		import(
			"../features/planning/modules/finance/financialAssetType/FinancialAssetPage.js"
		),
);
const { EmployeeRegisters } = lazy(
	() =>
		import(
			"../features/planning/modules/employee/register/EmployeeRegisters.js"
		),
);
const { EmployeePage } = lazy(
	() =>
		import("../features/planning/modules/employee/register/EmployeePage.js"),
);

const { DataLayerWrapper } = lazy(
	() => import("../features/dataLayer/DataLayerWrapper"),
);
const { DataLayerOverview } = lazy(
	() => import("../features/dataLayer/DataLayerOverview"),
);

const { DataLayerPage } = lazy(
	() => import("../features/dataLayer/DataLayerPage"),
);

const { Connectors } = lazy(
	() => import("../features/connector/connectors/Connectors"),
);
const { ConnectorsOverviewPage } = lazy(
	() =>
		import(
			"../features/connector/connectors/ConnectorsTable/ConnectorsOverviewPage"
		),
);
const { AddConnector } = lazy(
	() => import("../features/connector/addConnector/AddConnector"),
);
const { LoadHistory } = lazy(
	() => import("../features/connector/connectors/LoadHistory/LoadHistory"),
);
const { UsersAndGroupsPage } = lazy(
	() => import("../features/usersAndGroups/UsersAndGroupsPage"),
);
const { SupportUsers } = lazy(
	() => import("../features/supportUsers/SupportUsers"),
);
const { PeAccountingPump } = lazy(
	() =>
		import(
			"../features/connector/addConnector/peAccounting/PeAccountingPump.js"
		),
);
const { TfsOfficePump } = lazy(
	() => import("../features/connector/addConnector/tfsOffice/TfsOfficePump.js"),
);
const { AddVismaNetConnectorPortal: VismaPump } = lazy(
	() =>
		import(
			"../features/connector/addConnector/visma/AddVismaNetConnectorPortal.js"
		),
);
const { AdminPlansPage } = lazy(
	() => import("../features/planning/admin/AdminPlansPage.js"),
);
const { AccountTypeEditPage } = lazy(
	() => import("../features/data-perspectives/pages/AccountTypeEditPage.js"),
);
const { VitecConnectorOverview } = lazy(
	() => import("../features/connector/vitec/VitecConnectorOverview.js"),
);
const { AddXeroConnectorPage } = lazy(
	() =>
		import("../features/connector/addConnector/xero/AddXeroConnectorPage.js"),
);
const { SiePreviewPage } = lazy(
	() => import("../features/connector/sie/SiePreviewPage.js"),
);
const { SieIntegrationPage } = lazy(
	() => import("../features/connector/sie/integration/IndexPage.js"),
);
const { SieIntegrationFileSummaryPage } = lazy(
	() => import("../features/connector/sie/integration/SummaryPage.js"),
);

const dataSourcesLoaderFn = createLoaderWithAuthCheck(
	async (args, handlerCtx) => {
		await Promise.all([
			subscribeToQuery({
				getThunkAction(args) {
					return fusionHubApi.endpoints.getAllIntegrations.initiate(
						{
							companyDomainId: args.params.companyDomainId as string,
						},
						{ subscriptionOptions: { pollingInterval: 1000 * 10 } },
					);
				},
				args,
				handlerCtx,
			}),
			subscribeToQuery({
				getThunkAction(args) {
					return nexusApi.endpoints.getIntegrations.initiate({
						companyDomainId: args.params.companyDomainId as string,
					});
				},
				args,
				handlerCtx,
			}),
			subscribeToQuery({
				getThunkAction() {
					return fortnoxApi.endpoints.getStatus.initiate(undefined, {
						subscriptionOptions: {
							pollingInterval: 1000 * 60,
						},
					});
				},
				args,
				handlerCtx,
			}),
			subscribeToQuery({
				getThunkAction() {
					return exopenApi.endpoints.getPipelineRuns.initiate(
						{
							companyId: args.params.companyDomainId as string,
						},
						{
							subscriptionOptions: {
								pollingInterval: 1000 * 60,
							},
						},
					);
				},
				args,
				handlerCtx,
			}),
		]);
	},
);

const routeObjects: RouteObject[] = [
	{
		path: "*",
		element: <PageNotFound />,
	},
	{
		path: "fortnox-external",
		element: <AddFortnoxConnectorExternalRedirect />,
	},
	{
		path: "fortnox-external-callback",
		element: <FortnoxExternalCallbackPage />,
	},
	{
		path: "visma-eaccounting-external-callback",
		element: <VismaEaccountingExternalCallbackPage />,
	},
	{
		path: "verify-email",
		element: <EmailVerification />,
	},
	{
		path: "/",
		loader: createLoaderWithAuthCheck(
			async (args, handlerCtx) => {
				await Promise.all([
					subscribeToQuery({
						getThunkAction() {
							return exopenApi.endpoints.getAllCompanies.initiate();
						},
						args,
						handlerCtx,
					}),
					subscribeToQuery({
						getThunkAction() {
							return exopenApi.endpoints.getCurrentUser.initiate();
						},
						args,
						handlerCtx,
					}),
				]);
			},
			{ performAuthentication: true },
		),
		element: <ProtectedApp />,
		children: [
			{ path: "fortnox", element: <ActivateFortnoxPage /> },
			{
				path: "company-domains/:companyDomainId",
				element: <CompanyRouteLayout sidebarOpen={false} />,
				loader: createLoaderWithAuthCheck(async (args, handlerCtx) => {
					await Promise.all([
						subscribeToQuery({
							getThunkAction(args) {
								return exopenApi.endpoints.getCompany.initiate({
									companyId: args.params.companyDomainId as string,
								});
							},
							args,
							handlerCtx,
							getErrorToThrow() {
								return data(null, { status: 404 });
							},
						}),
						subscribeToQuery({
							getThunkAction(args) {
								return nexusApi.endpoints.getLegalEntities.initiate(
									args.params.companyDomainId as string,
								);
							},
							args,
							handlerCtx,
						}),
						subscribeToQuery({
							getThunkAction(args) {
								return nexusApi.endpoints.getCompanyHierarchy.initiate(
									args.params.companyDomainId as string,
								);
							},
							args,
							handlerCtx,
						}),
					]);
				}),
				children: [
					{ index: true, element: <CompanyDashboard /> },
					{
						path: "data-sources",
						children: [
							{
								element: <Connectors />,
								children: [
									{
										index: true,
										element: <ConnectorsOverviewPage />,
										loader: dataSourcesLoaderFn,
									},
									{
										path: "documentation",
										element: <Documentation />,
									},
									{
										path: "history",
										element: <LoadHistory />,
									},
									{
										path: "data-runs",
										children: [
											{ index: true, element: <JobListingPage /> },
											{ path: ":dataRunId", element: <JobDetailPage /> },
										],
									},
								],
							},
							{
								path: ":integrationId",
								element: <ConnectorDetailPage />,
							},
							{
								path: "setup",
								element: <ConnectConnectorPage />,
							},
							{
								path: "new",
								children: [
									{ index: true, element: <AddConnector /> },
									{ path: "visma", element: <VismaPump /> },
									{ path: "xledger", element: <XledgerPump /> },
									{
										path: "businesscentral",
										element: <BusinessCentralPump />,
									},
									{ path: "24sevenoffice", element: <TfsOfficePump /> },
									{ path: "peaccounting", element: <PeAccountingPump /> },
									{
										path: "vitec",
										element: <VitecConnectorOverview />,
									},
									{ path: "xero", element: <AddXeroConnectorPage /> },
									{
										path: "sie",
										children: [
											{
												path: ":sourceId",
												element: <SiePreviewPage />,
											},
										],
									},
								],
							},
							{
								path: "sie/:integrationId",
								children: [
									{
										index: true,
										element: <SieIntegrationPage />,
									},
									{
										path: ":fileId",
										element: <SieIntegrationFileSummaryPage />,
									},
								],
							},
						],
					},
					{
						path: "planning",
						element: <PlanPageWrapper />,
						children: [
							{
								path: "",
								element: <PlanningOverviewPage />,
							},
							{ path: "create-plan", element: <CreatePlanPage /> },
						],
					},
					{
						path: "datalayer",
						element: (
							<AuthRoute
								Component={DataLayerWrapper}
								permissions={[COMPANYADMIN, "DataLayer"]}
								permissionMode="any"
							/>
						),
						children: [
							{
								path: "",
								element: <DataLayerOverview />,
								children: [
									{
										index: true,
										element: <DataLayerPage />,
									},
								],
							},
						],
					},
					{
						path: "legal-entities",
						children: [
							{ index: true, element: <LegalEntitiesPage /> },
							{ path: "hierarchy", element: <GroupHierarchyCard /> },
							{
								path: ":legalEntityId",
								loader: createLoaderWithAuthCheck(async (args, handlerCtx) => {
									await Promise.all([
										subscribeToQuery({
											getThunkAction(args) {
												return nexusApi.endpoints.getLegalEntity.initiate({
													legalEntityId: args.params.legalEntityId as string,
													companyDomainId: args.params
														.companyDomainId as string,
												});
											},
											args,
											handlerCtx,
											getErrorToThrow() {
												return data(null, { status: 404 });
											},
										}),
									]);
								}),
								children: [
									{
										element: <LegalEntityDetailLayout />,
										children: [
											{
												index: true,
												element: <Navigate to="./accounts" />,
											},
											{
												element: <AccountHierarchyLayout />,
												path: "accounts",
												children: [
													{
														index: true,
														element: <IncomeStatementHierarchyPage />,
													},
													{
														path: "balance-sheet",
														element: <BalanceSheetHierarchyPage />,
													},
													{
														path: "types",
														element: <AccountTypeEditPage />,
													},
												],
											},
											{
												path: "projects",
												element: <ProjectHierarchyPage />,
											},
											{
												path: "cost-centers",
												element: <CostCenterHierarchyPage />,
											},
											{
												path: "products",
												element: <ProductHierarchyPage />,
											},
											{
												path: "customers",
												element: <CustomerHierarchyPage />,
											},
											{
												path: "suppliers",
												element: <SupplierHierarchyPage />,
											},
											{
												path: "invoices",
												element: <InvoicesLayout />,
												children: [
													{
														index: true,
														element: <Navigate to="customer" />,
													},
													{
														path: "customer",
														children: [
															{
																index: true,
																element: <CustomerInvoicesPage />,
															},
															{
																path: ":invoiceId",
																element: <CustomerInvoicePage />,
															},
														],
													},
													{
														path: "supplier",
														children: [
															{
																index: true,
																element: <SupplierInvoicesPage />,
															},
															{
																path: ":invoiceId",
																element: <SupplierInvoicePage />,
															},
														],
													},
												],
											},
											{
												path: "customer-invoices/:invoiceId",
												Component: () => {
													const { invoiceId } = useParams();
													return (
														<Navigate
															to={`../invoices/customer/${invoiceId}`}
														/>
													);
												},
											},
											{
												path: "supplier-invoices/:invoiceId",
												Component: () => {
													const { invoiceId } = useParams();
													return (
														<Navigate
															to={`../invoices/supplier/${invoiceId}`}
														/>
													);
												},
											},
										],
									},

									// todo: remove this
									{
										path: "planning",
										children: [
											{
												path: "",
												element: <Navigate to="./../../../planning" />,
											},
											{
												path: "create-plan",
												element: (
													<Navigate to="./../../../../planning/create-plan" />
												),
											},
										],
									},
									{
										path: "plan/:planId",
										element: <PlanDetailLayout />,
										children: [
											{
												element: <PlanDetailPage />,
												children: [
													{ index: true, element: <AssumptionsTab /> },
													{
														path: "definitions",
														element: <DefinitionsTabs />,
														children: [
															{
																path: "account-recipes",
																element: <AccountRecipes />,
															},
															{
																path: "account",
																element: <AccountLinks />,
																id: "account",
															},
															{
																path: "accrual-keys",
																element: <AllocationKeys />,
															},
															{
																path: "incoming-balance",
																element: <IncomingBalancePage />,
															},
															{
																path: "driver",
																element: (
																	<DriversRegisterPage refType="driver" />
																),
																id: "driver",
															},
															{
																path: "asset",
																element: <AssetRegisters />,
																children: [
																	{
																		index: true,
																		element: <AssetTypes />,
																	},
																	{
																		path: "asset",
																		element: <AssetPage />,
																	},
																],
																id: "asset",
															},
															{
																path: "finance",
																element: <FinanceRegisters />,
																children: [
																	{
																		index: true,
																		element: <FinancialAssetTypes />,
																	},
																	{
																		path: "financial-asset",
																		element: <FinancialAssetPage />,
																	},
																],
																id: "finance",
															},
															{
																path: "employee_pp",
																element: <EmployeeRegisters />,
																children: [
																	{
																		index: true,
																		element: <EmployeePage />,
																	},
																	{
																		path: "personnel-agreements",
																		element: <PersonnelAgreements />,
																	},
																	{
																		path: "benefits",
																		element: (
																			<DriversRegisterPage refType="benefit" />
																		),
																	},
																	{
																		path: "vacation-keys",
																		element: <VacationKeys />,
																	},
																	{
																		path: "service-level-keys",
																		element: <ServiceLevelKeys />,
																	},
																	{
																		path: "employee-groups",
																		element: <EmployeeGroupPage />,
																	},
																],
																id: "employee_pp",
															},
															{
																path: "consultancy",
																element: <ConsultancyRegisters />,
																children: [
																	{
																		index: true,
																		element: <ConsultantTypes />,
																	},
																	{
																		path: "debit-rate-keys",
																		element: <DebitRateKeys />,
																	},
																],
																id: "consultancy",
															},
														],
														id: PLANNING_DEFINITIONS_TAB_ID,
													},
												],
											},

											{ path: "copy-plan", element: <CopyPlanPage /> },
											{
												path: "settings",
												element: <PlanningSettingsPage />,
												children: [
													{
														path: "general",
														element: <GeneralSettingsPage />,
													},
													{ path: "users", element: <PlanningUsersPage /> },
													{
														path: "dimensions",
														element: <Dimensions />,
														children: [
															{
																index: true,
																element: <CostCenters />,
																loader: createLoaderWithAuthCheck(
																	async (args, handlerCtx) => {
																		await Promise.all([
																			subscribeToQuery({
																				getThunkAction(args) {
																					return planningApi.endpoints.getOrganizationalUnit.initiate(
																						{
																							planId: args.params
																								.planId as string,
																							companyDomainId: args.params
																								.companyDomainId as string,
																						},
																					);
																				},
																				args,
																				handlerCtx,
																			}),
																		]);
																	},
																),
															},
															{ path: "customers", element: <Customers /> },
															{ path: "suppliers", element: <Suppliers /> },
															{ path: "projects", element: <Projects /> },
															{ path: "products", element: <Products /> },
														],
													},
													{
														path: "vat",
														element: <VatSettings />,
													},
													{
														path: "holidays",
														element: <HolidaysPage />,
													},
												],
											},
										],
									},
								],
							},
						],
					},
					{
						path: "settings",
						element: (
							<AuthRoute
								Component={SettingsPage}
								permissions={[COMPANYADMIN]}
							/>
						),
						children: [
							{ index: true, element: <CompanyDomainSettingsPage /> },
							{
								path: "users",
								element: <UsersAndGroupsPage />,
								children: [
									{ index: true, element: <UserTable /> },
									{ path: "groups", element: <AccessGroupTable /> },
								],
							},
						],
					},
					{
						path: "internal",
						children: [
							{ path: "planning", element: <AdminPlansPage /> },
							{
								path: "reports",
								element: (
									<AuthRoute Component={Reports} permissions={[SUPERADMIN]} />
								),
							},
							{
								path: "dev-tools",
								element: (
									<AuthRoute
										Component={DevToolsPage}
										permissions={[SUPERADMIN]}
									/>
								),
								children: [
									{ index: true, element: <SupportTools /> },
									{
										path: "integrations",
										element: <DevtoolIntegrationsPage />,
										loader: dataSourcesLoaderFn,
									},
									{ path: "pumps", element: <Pumps /> },
									{ path: "legal-entities", element: <LegalEntities /> },
									{ path: "fake-pumps", element: <FakePumps /> },
									{ path: "marts", element: <MartManagement /> },
									{ path: "planning", element: <PlanningAdminCP /> },
									{
										path: "azure-resources",
										element: <AzureResourceTools />,
									},
									{ path: "dlm", element: <DLMTools /> },
									{ path: "template-apps", element: <TemplateApps /> },
								],
							},
							{
								path: "users",
								element: <SuperAdminRoute Component={SupportUsers} />,
							},
						],
					},
				],
			},
			{
				path: ":companyId",
				Component: () => {
					const { companyId } = useParams();
					return <Navigate to={`/company-domains/${companyId}`} />;
				},
			},
			{
				path: "vismanet-redirect",
				element: (
					<AppFrame>
						<MainFrame>
							<AddVismaNetConnectorRedirect />
						</MainFrame>
					</AppFrame>
				),
			},
			{
				path: "storage/companies/:companyId/pump/:pumpId/CustomerInvoice/*",
				element: <LegacyInvoice />,
			},
			{
				path: "storage/companies/:companyId/pump/:pumpId/SupplierInvoice/*",
				element: <LegacyInvoice />,
			},
			{
				element: (
					<AppFrame>
						<Sidebar empty />
						<MainFrame>
							<Outlet />
						</MainFrame>
					</AppFrame>
				),
				children: [
					{
						index: true,
						element: <Home />,
					},
				],
			},
		],
		HydrateFallback: () => (
			<div className="grid place-items-center h-screen">
				<CircleLoader />
			</div>
		),
		ErrorBoundary: () => {
			const error = useRouteError();
			if (isRouteErrorResponse(error) && error.status === 404) {
				return <PageNotFound />;
			}

			const message = "Oops!";
			const details = "An unexpected error occurred.";
			let stack: string | undefined;

			return (
				<main id="error-page">
					<h1>{message}</h1>
					<p>{details}</p>
					{stack && (
						<pre>
							<code>{stack}</code>
						</pre>
					)}
				</main>
			);
		},
	},
];

ensureRoutesHaveLoaderFunction(routeObjects);

export const router = createBrowserRouter(routeObjects, {
	dataStrategy,
});

if (import.meta.hot) {
	import.meta.hot.dispose(() => {
		dispatchUnloadEvents([]);
		router.dispose();
	});
}
