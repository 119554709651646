import { clsx } from "clsx";
import type { ComponentProps } from "react";

export const PopoverMenu = ({ className, ...props }: ComponentProps<"div">) => {
	return (
		<div
			className={clsx(
				"flex h-full flex-col gap-1 overflow-hidden rounded-lg border border-gray-300 bg-white p-1 shadow-2xl",
				className,
			)}
			{...props}
		/>
	);
};
