import { clsx } from "clsx";
import { type HTMLAttributes, forwardRef } from "react";

type TableContainerProps = HTMLAttributes<HTMLDivElement>;

export const TableContainer = forwardRef<HTMLDivElement, TableContainerProps>(
	function TableContainer({ className, children, ...props }, ref) {
		return (
			<div
				className={clsx(
					"overflow-auto rounded-lg border border-gray-200",
					className,
				)}
				ref={ref}
				{...props}
			>
				{children}
			</div>
		);
	},
);
