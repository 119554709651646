import { QueriesResult } from "../../../../common/components/atoms/queryResult/QueriesResult.tsx";
import { useCurrentPlanOrThrow } from "../../useCurrentPlan.ts";
import { useTranslation } from "react-i18next";
import { GridContainer } from "../../scenario/common/grid/GridContainer.tsx";
import { TableContainer } from "../../components/TableContainer.tsx";
import { IconDots, IconEdit, IconTrash } from "@tabler/icons-react";
import { Button } from "../../../../common/components/atoms/button/Button.tsx";
import { clsx } from "clsx";
import { useGetCompanyUsersQuery } from "../../../../common/redux/api/exopenApi.ts";
import { AddUserToPlanDialog } from "./AddUserToPlanDialog.tsx";
import { useGetUsersQuery } from "../../api/planUserApi.ts";
import { Popover } from "../../../../common/components/atoms/popoverMenu/Popover.tsx";
import { PopoverTrigger } from "../../../../common/components/atoms/popoverMenu/PopoverTrigger.tsx";
import { PopoverContent } from "../../../../common/components/atoms/popoverMenu/PopoverContent.tsx";
import { PopoverMenuItem } from "../../../../common/components/atoms/popoverMenu/menu/PopoverMenuItem.tsx";
import { PopoverMenu } from "../../../../common/components/atoms/popoverMenu/menu/PopoverMenu.tsx";
import { RemoveUserDialog } from "./RemoveUserDialog.tsx";
import type {
	AddUserToPlanBody,
	DomainUser,
} from "@exopengithub/planning-api-shared";
import { useId, useMemo, useState } from "react";
import { Chip } from "../../../../common/components/atoms/chip/Chip.tsx";
import { Divider } from "../../../../common/components/atoms/divider/Divider.tsx";
import { stringSort } from "../../../../common/utils/hooks/useSort.ts";
import { useUser } from "../../../../common/context/userContextUtils.ts";
import {
	COMPANYADMIN,
	ROLE_PLANNING_ADMIN,
} from "../../../../common/service/users.ts";
import { skipToken } from "@reduxjs/toolkit/query";
import { EditPlanUserDialog } from "./EditPlanUserDialog.tsx";
import { Navigate } from "react-router";

export const PlanningUsersPage = () => {
	const plan = useCurrentPlanOrThrow();
	const { t } = useTranslation();
	const [editUser, setEditUser] = useState<DomainUser | null>(null);
	const [deleteUser, setDeleteUser] = useState<DomainUser | null>(null);

	const permissions = useMemo((): {
		label: string;
		value: AddUserToPlanBody["permissions"][number];
	}[] => {
		return [{ label: t("Personel details"), value: "PersonnelDetails" }];
	}, [t]);

	const tableLabelId = useId();
	const { hasPermissions } = useUser();
	const hasPlanningAdmin = hasPermissions(plan.companyId, [
		ROLE_PLANNING_ADMIN,
	]);

	const queries = {
		planningUsers: useGetUsersQuery(
			hasPlanningAdmin
				? {
						companyDomainId: plan.companyId,
						legalEntityId: plan.nexusLegalEntityId,
						planId: plan.id,
					}
				: skipToken,
		),
		companyDomainUsers: useGetCompanyUsersQuery(
			hasPlanningAdmin ? plan.companyId : skipToken,
		),
	};

	if (!hasPlanningAdmin) {
		return <Navigate to="./../general" />;
	}

	return (
		<QueriesResult
			queries={queries}
			render={({ planningUsers, companyDomainUsers }) => {
				const usersToInvite = companyDomainUsers.filter((user) => {
					return (
						user.companyId.includes(Number(plan.companyId)) &&
						user.accessGroups.some((accessGroup) => {
							return (
								`${accessGroup.companyId}` === plan.companyId &&
								(accessGroup.name === COMPANYADMIN ||
									accessGroup.legalEntities.includes(plan.legalEntityMasterKey))
							);
						}) &&
						!planningUsers.some(
							(planningUser) => planningUser.email === user.email,
						)
					);
				});

				const sortedUsers = planningUsers.slice().sort((a, b) => {
					return stringSort(a.name, b.name);
				});

				return (
					<>
						<RemoveUserDialog
							user={deleteUser}
							onClose={() => {
								setDeleteUser(null);
							}}
						/>
						<EditPlanUserDialog
							user={editUser}
							onClose={() => {
								setEditUser(null);
							}}
							permissions={permissions}
						/>
						<div className="mb-4 flex items-end gap-4">
							<h1 className="grow">
								<div id={tableLabelId} className="pb-2 text-lg font-bold">
									{t("Users")}
								</div>
								<Divider />
							</h1>
							<div>
								<AddUserToPlanDialog
									users={usersToInvite}
									permissions={permissions}
								/>
							</div>
						</div>
						<TableContainer>
							<GridContainer
								templateColumns="auto min-content"
								templateRows={
									planningUsers.length > 0
										? `repeat(${planningUsers.length}, auto)`
										: undefined
								}
								role="table"
								aria-labelledby={tableLabelId}
							>
								{sortedUsers.map((user, index) => {
									const isPlanningUser = user.explicitUserId;
									const zebraBg = index % 2 === 0 ? "bg-gray-50" : "bg-white";
									const companyDomainUser = companyDomainUsers.find(
										(companyDomainUser) => {
											return companyDomainUser.email === user.email;
										},
									);
									if (!companyDomainUser) {
										console.warn("Company domain user not found");
										return null;
									}
									const isPlanningAdmin = Boolean(
										companyDomainUser.accessGroups.find((group) => {
											return (
												`${group.companyId}` === plan.companyId &&
												group.name === ROLE_PLANNING_ADMIN
											);
										}),
									);
									return (
										<div
											key={companyDomainUser.email}
											className="group contents"
											role="row"
										>
											<div
												className={clsx(
													"flex h-14 items-center gap-2 border-b px-4 group-last:border-b-0",
													zebraBg,
												)}
											>
												<div className="font-medium">
													{companyDomainUser.name}
												</div>
												{isPlanningAdmin && (
													<Chip variant="blue-light">
														{t("Planning admin")}
													</Chip>
												)}
												{user.permissions.map((permission) => {
													const permissionObject = permissions.find(
														(permissionObject) =>
															permissionObject.value === permission,
													);
													return (
														<Chip variant="error" key={permission}>
															{permissionObject?.label}
														</Chip>
													);
												})}
											</div>
											<div
												className={clsx(
													"flex h-14 items-center border-b p-2 group-last:border-b-0",
													zebraBg,
												)}
											>
												{isPlanningUser && (
													<Popover role="dialog" placement="bottom-end">
														<PopoverTrigger asChild>
															<Button
																size="sm"
																variant="ghost"
																ariaLabel={t("Edit employee")}
																icon={<IconDots />}
															/>
														</PopoverTrigger>
														<PopoverContent>
															<PopoverMenu>
																<PopoverMenuItem
																	label={t("Edit")}
																	icon={<IconEdit />}
																	onClick={() => {
																		setEditUser(user);
																	}}
																/>
																<PopoverMenuItem
																	label={t("Remove")}
																	icon={<IconTrash />}
																	onClick={() => {
																		setDeleteUser(user);
																	}}
																/>
															</PopoverMenu>
														</PopoverContent>
													</Popover>
												)}
											</div>
										</div>
									);
								})}
							</GridContainer>
						</TableContainer>
					</>
				);
			}}
		/>
	);
};
