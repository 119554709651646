import { Button } from "../../../../common/components/atoms/button/Button.tsx";
import { Dialog } from "../../../../common/components/atoms/dialog/Dialog.tsx";
import { DialogActions } from "../../../../common/components/atoms/dialog/DialogActions.tsx";
import { DialogBody } from "../../../../common/components/atoms/dialog/DialogBody.tsx";
import { DialogContent } from "../../../../common/components/atoms/dialog/DialogContent.tsx";
import { DialogTitle } from "../../../../common/components/atoms/dialog/DialogTitle.tsx";
import { useTranslation } from "react-i18next";
import { useRemoveUserMutation } from "../../api/planUserApi.ts";
import {
	showErrorNotification,
	showSuccessNotification,
} from "../../../../common/components/atoms/notifications/events.tsx";
import { useCurrentPlanOrThrow } from "../../useCurrentPlan.ts";
import type { DomainUser } from "@exopengithub/planning-api-shared";
import { invariant } from "../../../../common/utils/invariant.ts";

export const RemoveUserDialog = ({
	user,
	onClose,
}: {
	user: DomainUser | null;
	onClose(): void;
}) => {
	const { t } = useTranslation();
	const [removeUser, { isLoading }] = useRemoveUserMutation();
	const plan = useCurrentPlanOrThrow();

	return (
		<Dialog open={user !== null} onClose={onClose}>
			<DialogContent>
				<DialogTitle>{t("Remove user")}</DialogTitle>
				<DialogBody>
					<div>
						{t("Are you sure you want to remove {{name}} from this plan?", {
							name: user?.name,
						})}
					</div>
				</DialogBody>
				<DialogActions withCancelButton>
					<Button
						variant="primary"
						isLoading={isLoading}
						onClick={async () => {
							invariant(user?.explicitUserId);
							const result = await removeUser({
								companyDomainId: plan.companyId,
								planId: plan.id,
								legalEntityId: plan.nexusLegalEntityId,
								userId: user.explicitUserId,
							});
							if ("error" in result) {
								showErrorNotification({
									message: t("Something failed..."),
								});
							} else {
								showSuccessNotification({
									title: t("User removed"),
									message: t("You've removed {{name}} from this plan.", {
										name: user.name,
									}),
								});
								onClose();
							}
						}}
					>
						{t("Remove user")}
					</Button>
				</DialogActions>
			</DialogContent>
		</Dialog>
	);
};
