import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { useMemo } from "react";
import type { LegalEntity } from "src/common/service/nexus/endpoints/companyDomain/types";

interface LegalEntitiesFilterState {
	name: string;
	currencyBase: string | null;
	page: number;
}

const initialState: LegalEntitiesFilterState = {
	name: "",
	currencyBase: null,
	page: 1,
};

export const legalEntitiesFilterSlice = createSlice({
	name: "_",
	reducers: {
		filterName: (state, action: PayloadAction<string>) => {
			state.name = action.payload;
			state.page = 1;
		},
		filterCurrency: (state, action: PayloadAction<string | null>) => {
			state.currencyBase = action.payload;
			state.page = 1;
		},
		clearFilter: (_state, action: PayloadAction<LegalEntitiesFilterState>) => {
			return action.payload;
		},
		setPage: (state, action: PayloadAction<number>) => {
			state.page = action.payload;
		},
	},
	initialState,
});

export const useLegalEntitiesFilter = (
	legalEntities: LegalEntity[],
	filter: LegalEntitiesFilterState,
) => {
	return useMemo(() => {
		return legalEntities.filter((legalEntity) => {
			const matches: boolean[] = [];

			if (filter.name !== "") {
				const parts = legalEntity.name.split(" ");
				const queryParts = filter.name.trim().split(" ");
				matches.push(
					queryParts.every((queryPart) => {
						return parts.some((part) => {
							return part
								.toLocaleLowerCase()
								.includes(queryPart.toLocaleLowerCase());
						});
					}),
				);
			}

			if (filter.currencyBase !== null) {
				matches.push(legalEntity.currencyBase === filter.currencyBase);
			}

			return matches.every((match) => match);
		});
	}, [legalEntities, filter.name, filter.currencyBase]);
};
