import { useMemo, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Chip } from "../../../common/components/atoms/chip/Chip.js";
import { ToggleTabButton } from "../../../common/components/atoms/toggle-button/ToggleButton.js";
import { ToggleTabButtonGroup } from "../../../common/components/atoms/toggle-button/ToggleTabButtonGroup.js";
import type { LegalEntityEntity } from "../../../common/service/nexus/types.js";
import { useEntityHierarchy } from "../entityHierarchyProviderUtils";
import {
	extractGroupsFromHierarchy,
	type GroupWithParent,
} from "../utils/extractGroupsFromHierarchy.js";
import { EntityItemsList } from "./EntityItemsList.js";
import { HierarchyGroupTable } from "./HierarchyGroupTable.js";
import type {
	EntityHierarchy,
	EntityHierachyResponse,
} from "src/common/service/nexus/utils/hierarchy.js";

type EntityWithLocation<T extends LegalEntityEntity> = T & {
	location: EntityHierarchy<T, Record<string, never>>[];
};

const extractItemsFromHierarchy = <T extends LegalEntityEntity>(
	hierarchies: EntityHierarchy<T, Record<string, never>>[],
	location: EntityHierarchy<T, Record<string, never>>[] = [],
) => {
	const items: EntityWithLocation<T>[] = [];

	hierarchies.forEach((hierarchy) => {
		items.push(
			...hierarchy.items.map((item) => ({
				...item,
				location: [...location, hierarchy],
			})),
		);
		items.push(
			...extractItemsFromHierarchy(hierarchy.children, [
				...location,
				hierarchy,
			]),
		);
	});

	return items;
};

const extractMatchingGroups = <T extends LegalEntityEntity>(
	hierarchies: EntityHierarchy<T, Record<string, never>>[],
	searchQuery: string,
) => {
	const groups = extractGroupsFromHierarchy(hierarchies);

	const filteredGroups = groups.filter((group) => {
		return group.name.toLowerCase().includes(searchQuery.toLowerCase());
	});

	return filteredGroups;
};

type Props<T extends LegalEntityEntity> = {
	searchQuery: string;
	hierarchy: EntityHierachyResponse<T, Record<string, never>>;
	isEditable: boolean;
	onMoveItems: (items: T[]) => void;
	onGroupClick: (group: EntityHierarchy<T, Record<string, never>>) => void;
};

export const EntityHierarchySearch = <T extends LegalEntityEntity>({
	searchQuery,
	hierarchy,
	isEditable,
	onMoveItems,
	onGroupClick,
}: Props<T>) => {
	const { t } = useTranslation();

	const {
		entityColumns,
		labels: { entityName, entityNamePlural },
	} = useEntityHierarchy();

	const [searchType, setSearchType] = useState<"entities" | "groups">(
		"entities",
	);

	const allItems = useMemo(
		() => [
			...extractItemsFromHierarchy(hierarchy.hierarchy),
			...hierarchy.availableItems.map<EntityWithLocation<T>>((item) => ({
				...item,
				location: [],
			})),
		],
		[hierarchy.availableItems, hierarchy.hierarchy],
	);

	const filteredItems = useMemo(
		() =>
			allItems.filter((item) =>
				entityColumns.some((column) => {
					const value = item[column.key];

					return value.toLowerCase().includes(searchQuery.toLowerCase());
				}),
			),
		[allItems, entityColumns, searchQuery],
	);

	const filteredGroups = useMemo(
		() => extractMatchingGroups(hierarchy.hierarchy, searchQuery),
		[hierarchy.hierarchy, searchQuery],
	);

	const handleGroupClick = (
		group: EntityHierarchy<T, Record<string, never>> | GroupWithParent,
	) => {
		onGroupClick(group as EntityHierarchy<T, Record<string, never>>);
	};

	return (
		<>
			<ToggleTabButtonGroup className="mb-4">
				<ToggleTabButton
					onClick={() => setSearchType("groups")}
					selected={searchType === "groups"}
				>
					{t("{{entityName}} groups", {
						entityName,
					})}{" "}
					<Chip size="small" variant="purple">
						{filteredGroups.length}
					</Chip>
				</ToggleTabButton>
				<ToggleTabButton
					onClick={() => setSearchType("entities")}
					selected={searchType === "entities"}
				>
					{entityNamePlural}{" "}
					<Chip size="small" variant="purple">
						{filteredItems.length}
					</Chip>
				</ToggleTabButton>
			</ToggleTabButtonGroup>
			{searchType === "groups" && (
				<HierarchyGroupTable
					groups={filteredGroups}
					isSearchable={false}
					isSelectable={false}
					onClick={handleGroupClick}
				/>
			)}
			{searchType === "entities" && (
				<EntityItemsList
					items={filteredItems}
					onMoveItems={onMoveItems}
					extraColumns={[
						{
							id: "location",
							label: t("Path"),
							render: (item) => (
								<>
									{item.location.map((group, index) => (
										<Fragment key={index}>
											<Chip
												size="small"
												variant="purple"
												className="cursor-pointer"
												onClick={() => handleGroupClick(group)}
											>
												{group.name}
											</Chip>
											{index < item.location.length - 1 && " / "}
										</Fragment>
									))}
								</>
							),
						},
					]}
					isEditable={isEditable}
					isSearchable={false}
				/>
			)}
		</>
	);
};
