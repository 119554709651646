import { Controller, type UseFormReturn } from "react-hook-form";
import { CheckboxField } from "../../../../common/components/atoms/checkbox/CheckboxField.tsx";
import { Label } from "../../../../common/components/atoms/label/Label.tsx";
import {
	showErrorNotification,
	showSuccessNotification,
} from "../../../../common/components/atoms/notifications/events.tsx";
import { Select } from "../../../../common/components/atoms/select/Select.tsx";
import { ValidateHint } from "../../components/ValidateHint.tsx";
import { useTranslation } from "react-i18next";
import { useId } from "react";
import { useAddUserMutation } from "../../api/planUserApi.ts";
import { useCurrentPlanOrThrow } from "../../useCurrentPlan.ts";
import type { AddUserToPlanBody } from "@exopengithub/planning-api-shared";
import type { PlanUserFormValues } from "./AddUserToPlanDialog.tsx";
import { hasFeature } from "../../../../featureFlagUtils.ts";
import { CostCenterPermissions } from "./CostCenterPermissions.tsx";

export const AddUserToPlanForm = ({
	onSuccessfulSubmit,
	permissions,
	users,
	formId,
	form,
}: {
	onSuccessfulSubmit: () => void;
	users: {
		name: string;
		email: string;
	}[];
	permissions: {
		label: string;
		value: AddUserToPlanBody["permissions"][number];
	}[];
	formId: string;
	form: UseFormReturn<PlanUserFormValues>;
}) => {
	const { t } = useTranslation();
	const userLabelId = useId();
	const [addUserToPlan] = useAddUserMutation();
	const plan = useCurrentPlanOrThrow();

	return (
		<form
			id={formId}
			onSubmit={form.handleSubmit(async (data) => {
				const result = await addUserToPlan({
					companyDomainId: plan.companyId,
					legalEntityId: plan.nexusLegalEntityId,
					planId: plan.id,
					body: {
						email: data.user!,
						permissions: data.permissions,
						allCostCenters: true,
						costCenterIds: [],
					},
				});
				if ("error" in result) {
					showErrorNotification({
						message: t("Something failed..."),
					});
				} else {
					const user = users.find((user) => user.email === data.user!);
					showSuccessNotification({
						title: t("User added"),
						message: t("The user {{name}} was added to plan {{plan}}", {
							name: user?.name ?? "",
							plan: plan.name,
						}),
					});
					onSuccessfulSubmit();
				}
			})}
		>
			<Label id={userLabelId}>{t("User")}</Label>
			<Controller
				control={form.control}
				name="user"
				rules={{ required: t("This field is required") }}
				render={({ field, fieldState }) => {
					return (
						<>
							<Select
								{...field}
								options={users.map((user) => {
									return { value: user.email, label: user.name };
								})}
								aria-labelledby={userLabelId}
								ariaInvalid={fieldState.invalid}
								placeholder={t("Select user")}
							/>
							{fieldState.error && (
								<ValidateHint error>{fieldState.error.message}</ValidateHint>
							)}
						</>
					);
				}}
			/>

			<div className="mb-1 pt-4 font-medium">{t("Permissions")}</div>
			<Controller
				control={form.control}
				name="permissions"
				render={({ field }) => {
					return (
						<div role="group">
							{permissions.map(({ value, label }) => {
								return (
									<CheckboxField
										key={value}
										label={label}
										name="permissions"
										value={value}
										checked={field.value.includes(value)}
										onChange={(event) => {
											if (event.target.checked) {
												field.onChange([...field.value, event.target.value]);
											} else {
												field.onChange(
													field.value.filter(
														(value) => value !== event.target.value,
													),
												);
											}
										}}
									/>
								);
							})}
						</div>
					);
				}}
			/>
			{hasFeature("costCenterPermissions") && (
				<CostCenterPermissions form={form} />
			)}
		</form>
	);
};
