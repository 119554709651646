import { useTranslation } from "react-i18next";
import { Controller, type SubmitHandler, useForm } from "react-hook-form";
import { Label } from "../../common/components/atoms/label/Label";
import { Input } from "../../common/components/atoms/input/Input";
import { Button } from "../../common/components/atoms/button/Button";
import type {
	AccessGroup,
	AccessGroupObject,
} from "../../common/service/Types/AccessGroup";
import {
	useAddAccessGroupMutation,
	useEditAccessGroupMutation,
	useGetAvailableObjectsForAccessGroupsQuery,
} from "../../common/redux/api/exopenApi";
import {
	showErrorNotification,
	showSuccessNotification,
} from "../../common/components/atoms/notifications/events";
import { SwitchField } from "../../common/components/atoms/checkbox/SwitchField";
import { Tabs } from "../../common/components/atoms/tabs/Tabs";
import { Tab } from "../../common/components/atoms/tabs/Tab";
import { useEffect, useState } from "react";
import { LoadingState } from "../../common/components/atoms/loadingState/LoadingState";
import { MissingDataState } from "../../common/components/atoms/missingDataState/MissingDataState";
import { ColorPicker } from "./ColorPicker";
import { Dialog } from "components/dialog/Dialog.tsx";
import { DialogContent } from "components/dialog/DialogContent.tsx";
import { DialogTitle } from "components/dialog/DialogTitle.tsx";
import { DialogBody } from "components/dialog/DialogBody.tsx";
import { DialogActions } from "components/dialog/DialogActions.tsx";
import type { Permission } from "src/common/context/useUser";
import { useLoadedLegalEntities } from "src/routes/routeDataHooks";

const permissionSet = new Set<Permission>(["DataSieUpload", "DataLayer"]);

const filterPermissions = (accessGroupObject: AccessGroupObject) => {
	return permissionSet.has(accessGroupObject.objectId as Permission);
};

interface Inputs {
	name: string;
	description: string;
	colorCode: string;
	permissions: string[];
	legalEntities: string[];
}

type AddAccessGroupModalProps = {
	modalOpen: boolean;
	handleClose: () => void;
	companyId: string;
	accessGroup?: AccessGroup | undefined;
};

export const AddAccessGroupModal = ({
	modalOpen,
	handleClose,
	companyId,
	accessGroup,
}: AddAccessGroupModalProps) => {
	const { t } = useTranslation();

	const { data: accessGroupObjects, isLoading: isLoadingAccessGroupObjects } =
		useGetAvailableObjectsForAccessGroupsQuery(companyId);
	const legalEntities = useLoadedLegalEntities();
	const {
		register,
		handleSubmit,
		reset,
		control,
		formState: { isDirty, isSubmitting, errors },
		watch,
	} = useForm<Inputs>({
		defaultValues: {
			description: accessGroup?.description ?? "",
			name: accessGroup?.name ?? "",
			colorCode: accessGroup?.colorCode ?? "",
			legalEntities: [],
			permissions: [],
		},
	});

	useEffect(() => {
		if (accessGroup) {
			reset({
				colorCode: accessGroup.colorCode,
				permissions: accessGroup.accessGroupObjects
					.filter(filterPermissions)
					.map(({ objectId }) => objectId),
				name: accessGroup.name,
				description: accessGroup.description,
				legalEntities: accessGroup.legalEntities,
			});
		}
	}, [accessGroup, reset]);

	const permissionsNames: Partial<Record<Permission, string>> = {
		DataSieUpload: t("Can upload SIE-files"),
		DataLayer: t("Can access Data Layer"),
	};

	const [addAccessGroup] = useAddAccessGroupMutation();
	const [editAccessGroup] = useEditAccessGroupMutation();

	const [tab, setTab] = useState(0);

	const onClose = () => {
		reset({
			description: "",
			name: "",
			colorCode: "",
			legalEntities: [],
			permissions: [],
		});
		setTab(0);
		handleClose();
	};

	const permissionsObjects = accessGroupObjects?.filter(filterPermissions);

	const accessGroupObjectById: Record<string, AccessGroupObject> = {};
	for (const accessGroupObject of accessGroupObjects ?? []) {
		accessGroupObjectById[accessGroupObject.objectId] = accessGroupObject;
	}

	const onSubmit: SubmitHandler<Inputs> = async (data) => {
		const accessGroupObjects = [
			...data.permissions.map((objectId) => {
				return {
					objectType: "Admin",
					objectId,
				};
			}),
		];
		const result = await (accessGroup
			? editAccessGroup({
					accessGroupObjects,
					colorCode: data.colorCode,
					companyId,
					description: data.description,
					legalEntities: data.legalEntities,
					name: data.name,
					groupId: accessGroup.groupId,
				})
			: addAccessGroup({
					accessGroupObjects,
					colorCode: data.colorCode,
					companyId,
					description: data.description,
					legalEntities: data.legalEntities,
					name: data.name,
				}));

		if ("error" in result) {
			showErrorNotification({
				message: accessGroup
					? t("The changes couldn't be saved")
					: t("Failed to add the group"),
			});
		} else {
			showSuccessNotification({
				message: accessGroup
					? t("The changes are saved")
					: t("The group was created"),
			});
			onClose();
		}
	};

	const [legalEntitiesFormValue, permissions] = watch([
		"legalEntities",
		"permissions",
	]);

	return (
		<Dialog open={modalOpen} onClose={handleClose}>
			<DialogContent size="xl">
				<DialogTitle>
					{accessGroup ? t("Edit group") : t("Create new group")}
				</DialogTitle>

				<DialogBody>
					{isLoadingAccessGroupObjects ? (
						<LoadingState />
					) : !accessGroupObjects ? (
						<MissingDataState />
					) : (
						<form id="add-edit-group-form" onSubmit={handleSubmit(onSubmit)}>
							<div className="flex flex-col gap-6 md:flex-row">
								<div className="min-w-0 flex-shrink-0 flex-grow basis-0 space-y-4">
									<div>
										<Label htmlFor="access-group-name-input">{t("Name")}</Label>
										<Input
											{...register("name", {
												required: t("This field is required"),
											})}
											id="access-group-name-input"
											aria-invalid={errors.name !== undefined}
											hint={errors.name?.message}
										/>
									</div>

									<div>
										<Label htmlFor="access-group-description-input">
											{t("Description")}
										</Label>
										<Input
											{...register("description")}
											id="access-group-description-input"
										/>
									</div>
									<div>
										<Label>{t("Choose colour for group")}</Label>
										<Controller
											control={control}
											name="colorCode"
											render={({ field }) => {
												return (
													<ColorPicker
														value={field.value}
														onChange={field.onChange}
													/>
												);
											}}
										/>
									</div>
								</div>
								<div className="min-w-0 flex-shrink-0 flex-grow basis-0">
									<Tabs
										selectedIndex={tab}
										onSelectTab={(index) => {
											setTab(index);
										}}
										className="mb-4"
									>
										<Tab count={legalEntitiesFormValue.length}>
											{t("Legal entities")}
										</Tab>
										<Tab count={permissions.length}>{t("Features")}</Tab>
									</Tabs>
									<Controller
										control={control}
										name="legalEntities"
										render={({ field: { value, onChange, ...field } }) => {
											if (tab !== 0) {
												return <>{null}</>;
											}
											return (
												<>
													<h6 className="mb-4">
														{t("Add legal entities to the group")}
													</h6>
													{legalEntities.length === 0 ? (
														t("No legal entities available")
													) : (
														<div className="max-h-[300px] space-y-2 overflow-y-auto">
															{legalEntities.map((legalEntity) => {
																return (
																	<div key={legalEntity.id}>
																		<SwitchField
																			{...field}
																			label={legalEntity.name}
																			checked={value.includes(
																				legalEntity.legalEntityMasterKey,
																			)}
																			onChange={(event) => {
																				if (event.target.checked) {
																					onChange([
																						...value,
																						legalEntity.legalEntityMasterKey,
																					]);
																				} else {
																					onChange(
																						value.filter((legalEntityId) => {
																							return (
																								legalEntityId !==
																								legalEntity.legalEntityMasterKey
																							);
																						}),
																					);
																				}
																			}}
																		/>
																	</div>
																);
															})}
														</div>
													)}
												</>
											);
										}}
									/>
									<Controller
										control={control}
										name="permissions"
										render={({ field: { value, onChange, ...field } }) => {
											if (tab !== 1) {
												return <>{null}</>;
											}
											return (
												<div className="max-h-[300px] space-y-2 overflow-y-auto">
													{permissionsObjects?.map((permission) => {
														return (
															<div key={permission.objectId}>
																<SwitchField
																	{...field}
																	label={
																		permissionsNames[
																			permission.name as Permission
																		] ?? ""
																	}
																	checked={value.includes(permission.objectId)}
																	onChange={(event) => {
																		if (event.target.checked) {
																			onChange([...value, permission.objectId]);
																		} else {
																			onChange(
																				value.filter((martId) => {
																					return martId !== permission.objectId;
																				}),
																			);
																		}
																	}}
																/>
															</div>
														);
													})}
												</div>
											);
										}}
									/>
								</div>
							</div>
						</form>
					)}
				</DialogBody>
				<DialogActions withCancelButton>
					<Button
						isLoading={isSubmitting}
						form="add-edit-group-form"
						type="submit"
						disabled={isSubmitting || !isDirty}
					>
						{accessGroup ? t("Save changes") : t("Create group")}
					</Button>
				</DialogActions>
			</DialogContent>
		</Dialog>
	);
};
