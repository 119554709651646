import {
	IconHome,
	IconSettings,
	IconDatabaseCog,
	IconBuildings,
	IconCode,
	IconReport,
	IconUsers,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { NavItem } from "./NavItem";
import PlanningIcon from "../../assets/images/icons/planning.svg?react";
import CalculatorIcon from "../../assets/images/icons/calculator.svg?react";
import ConsolidationIcon from "../../assets/images/icons/consolidation.svg?react";
import DataLayerIcon from "../../assets/images/icons/datalayer.svg?react";
import { useUser } from "../../common/context/userContextUtils";
import { CompanySelect } from "./CompanySelect.js";
import { Divider } from "../../common/components/atoms/divider/Divider.js";
import { COMPANYADMIN } from "src/common/service/users.js";
import { useLoadedCompanyDomain } from "src/routes/routeDataHooks.js";

interface CompanySidebarPartProps {
	sidebarOpen: boolean;
}

export const CompanySidebarPart = ({
	sidebarOpen,
}: CompanySidebarPartProps) => {
	const companyDomain = useLoadedCompanyDomain();

	const user = useUser();

	const { t } = useTranslation();
	const hasCompanyAdmin = user.hasPermissions(companyDomain.id, [COMPANYADMIN]);

	const accessToDataLayer =
		companyDomain.hasDataLayer &&
		(hasCompanyAdmin || user.hasPermissions(companyDomain.id, ["DataLayer"]));

	const productLinks: JSX.Element[] = [];
	if (accessToDataLayer)
		productLinks.push(
			<NavItem
				key="data layer"
				to={`/company-domains/${companyDomain.id}/datalayer`}
				label="Data Layer"
				icon={<DataLayerIcon />}
			/>,
		);

	if (companyDomain.ewr?.url)
		productLinks.push(
			<NavItem
				key="report"
				to={companyDomain.ewr.url}
				label="Report"
				icon={<CalculatorIcon />}
				reloadDocument
			/>,
			<NavItem
				key="consolidation"
				to={companyDomain.ewr.url}
				label="Consolidation"
				icon={<ConsolidationIcon />}
				reloadDocument
			/>,
		);

	return (
		<div className="grow overflow-y-auto">
			{sidebarOpen && (
				<div className="mx-4 mb-1">
					<Divider label={t("Company domain")} className="my-1" />
					<CompanySelect />
				</div>
			)}
			<nav>
				<div className="mx-4 mb-4 space-y-1">
					<NavItem
						to={`/company-domains/${companyDomain.id}`}
						label="Start"
						icon={<IconHome />}
						end
					/>
					{productLinks}
					<NavItem
						to={`/company-domains/${companyDomain.id}/planning`}
						label="Planning"
						icon={<PlanningIcon />}
					/>
					<NavItem
						label={t("Data sources")}
						to={`/company-domains/${companyDomain.id}/data-sources`}
						icon={<IconDatabaseCog />}
					/>
					{hasCompanyAdmin && (
						<NavItem
							label={t("Companies")}
							to={`/company-domains/${companyDomain.id}/legal-entities`}
							icon={<IconBuildings />}
						/>
					)}
					{hasCompanyAdmin && (
						<NavItem
							to={`/company-domains/${companyDomain.id}/settings`}
							label={t("Settings")}
							icon={<IconSettings />}
						/>
					)}

					{user.userIsSuperAdmin() && (
						<>
							<Divider label={t("Internal")} className="my-1" />
							<NavItem
								to={`/company-domains/${companyDomain.id}/internal/dev-tools`}
								label="Utvecklingsverktyg"
								icon={<IconCode />}
							/>
							<NavItem
								to={`/company-domains/${companyDomain.id}/internal/reports`}
								label={t("EWR reports")}
								icon={<IconReport />}
							/>
							<NavItem
								to={`/company-domains/${companyDomain.id}/internal/users`}
								label={t("Users")}
								icon={<IconUsers />}
							/>
							<NavItem
								to={`/company-domains/${companyDomain.id}/internal/planning`}
								label="Planning"
								icon={<PlanningIcon />}
							/>
						</>
					)}
				</div>
			</nav>
		</div>
	);
};
