import {
	useListAvailableCompaniesForBusinessCentralQuery,
	useListAvailableDimensionsForBusinessCentralQuery,
} from "src/common/redux/api/exopenApi.js";
import type { Credentials } from "./CredentialsForm.js";
import { LoadingState } from "components/loadingState/LoadingState";
import { useTranslation } from "react-i18next";
import { MissingDataState } from "components/missingDataState/MissingDataState";
import { CheckboxField } from "components/checkbox/CheckboxField";
import { Fragment, useState } from "react";
import { Label } from "../../../../common/components/atoms/label/Label";
import { NativeSelect } from "components/native-select/NativeSelect";
import { Button } from "components/button/Button";
import { skipToken } from "@reduxjs/toolkit/query";

type DataConfig = {
	costCenterDimensionId: string;
	projectDimensionId: string;
	companyId: string;
};

type Props = {
	credentials: Credentials;
	isSubmitting: boolean;
	isError: boolean;
	onSubmit: (configs: DataConfig[]) => void;
};

export const DataConfigEditor = ({
	credentials,
	isSubmitting,
	isError,
	onSubmit,
}: Props) => {
	const { t } = useTranslation();

	const [activatedCompanies, setActivatedCompanies] = useState<
		Record<
			string,
			| {
					costCenterDimensionId: string;
					projectDimensionId: string;
			  }
			| undefined
		>
	>({});

	const {
		data: companies,
		isFetching: isFetchingCompanies,
		isError: isErrorCompanies,
	} = useListAvailableCompaniesForBusinessCentralQuery(credentials);

	const {
		data: dimensions,
		isFetching: isFetchingDimensions,
		isError: isErrorDimensions,
	} = useListAvailableDimensionsForBusinessCentralQuery(
		companies
			? {
					...credentials,
					externalCompanyIds: companies.map((company) => company.id),
				}
			: skipToken,
	);

	if (isErrorCompanies || isErrorDimensions) {
		return <MissingDataState />;
	}

	if (isFetchingCompanies || isFetchingDimensions) {
		return <LoadingState />;
	}

	return (
		<div className="flex flex-col gap-2">
			<div className="font-medium text-gray-700">{t("Choose companies")}</div>
			{companies?.map((company) => {
				const companyMeta = dimensions?.find(
					(dimension) => dimension.externalCompanyId === company.id,
				);

				const currentConfig = activatedCompanies[company.id];

				return (
					<Fragment key={company.id}>
						<CheckboxField
							label={company.name}
							checked={Boolean(currentConfig)}
							onChange={(e) => {
								setActivatedCompanies((comp) => ({
									...comp,
									[company.id]: e.target.checked
										? {
												costCenterDimensionId: "",
												projectDimensionId: "",
											}
										: undefined,
								}));
							}}
						/>
						{companyMeta?.dimensions &&
							companyMeta.dimensions.length > 0 &&
							currentConfig && (
								<div className="ml-4">
									<Label htmlFor="cost-center-dimension-id">
										{t("Cost center dimension")}
									</Label>
									<NativeSelect
										id="cost-center-dimension-id"
										containerProps={{ className: "mb-5" }}
										value={currentConfig.costCenterDimensionId}
										onChange={(e) => {
											setActivatedCompanies((comp) => ({
												...comp,
												[company.id]: {
													...currentConfig,
													costCenterDimensionId: e.target.value,
												},
											}));
										}}
									>
										<option value="">{t("Not applicable")}</option>
										{companyMeta.dimensions.map((dimension) => (
											<option key={dimension.id} value={dimension.id}>
												{dimension.name}
											</option>
										))}
									</NativeSelect>
									<Label htmlFor="project-dimension-id">
										{t("Project dimension")}
									</Label>
									<NativeSelect
										id="project-dimension-id"
										containerProps={{ className: "mb-5" }}
										value={currentConfig.projectDimensionId}
										onChange={(e) => {
											setActivatedCompanies((comp) => ({
												...comp,
												[company.id]: {
													...currentConfig,
													projectDimensionId: e.target.value,
												},
											}));
										}}
									>
										<option value="">{t("Not applicable")}</option>
										{companyMeta.dimensions.map((dimension) => (
											<option key={dimension.id} value={dimension.id}>
												{dimension.name}
											</option>
										))}
									</NativeSelect>
								</div>
							)}
					</Fragment>
				);
			})}
			{isError && (
				<div className="mb-4 text-red-500 text-sm">
					{t("Failed to create data source")}
				</div>
			)}
			<Button
				onClick={() => {
					onSubmit(
						Object.entries(activatedCompanies)
							.filter(([, config]) => Boolean(config))
							.map(
								([companyId, config]) =>
									({
										companyId,
										...config,
									}) as DataConfig,
							),
					);
				}}
				disabled={
					!Object.entries(activatedCompanies).some(([, config]) => config)
				}
				isLoading={isSubmitting}
			>
				{t("Add data source")}
			</Button>
		</div>
	);
};
