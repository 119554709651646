import type { ReactNode } from "react";
import { useAmountFormatter } from "../utils/amountFormat/AmountFormatContext.js";
import { Chip } from "components/chip/Chip.js";

const DrilldownDetailsCaretText = ({ children }: { children: ReactNode }) => (
	<div className="text-sm pl-2 text-gray-500">↳ {children}</div>
);

export const DrilldownDetailsItem = ({
	children,
	name,
	amount,
	infoRows,
}: {
	name: ReactNode;
	amount: number;
	infoRows?: ReactNode[];
	children?: ReactNode | undefined;
}) => {
	const amountFormatter = useAmountFormatter();

	return (
		<div className="flex">
			<div className="flex-grow">
				<div className="text-sm font-medium">{name}</div>
				{children}
				{infoRows?.map((content, index) => (
					<DrilldownDetailsCaretText key={index}>
						{content}
					</DrilldownDetailsCaretText>
				))}
			</div>
			<div>{amountFormatter(amount)}</div>
		</div>
	);
};

export const DrilldownDetailsContainer = ({
	children,
	title,
}: {
	children: ReactNode;
	title: ReactNode;
}) => {
	return (
		<div className="space-y-2">
			<div>
				<Chip size="small" variant="purple">
					{title}
				</Chip>
			</div>
			{children}
		</div>
	);
};
