import { clsx } from "clsx";
import { type ComponentProps, forwardRef } from "react";
import { COMMON_CLASS } from "./checkboxUtils";

const SWITCH_CIRCLE_CLASS =
	"before:size-4 before:bg-white before:rounded-full before:absolute before:top-[2px] before:left-[2px] before:checked:translate-x-[16px] before:transition-transform before:shadow";

export type SwitchProps = ComponentProps<"input">;

export const Switch = forwardRef<HTMLInputElement, SwitchProps>(
	({ className, ...props }, ref) => {
		return (
			<input
				type="checkbox"
				role="switch"
				className={clsx(
					COMMON_CLASS,
					"relative h-[20px] w-[36px] rounded-[12px] bg-gray-100 transition-colors checked:bg-purple-500 disabled:checked:bg-purple-100 [&:not(:disabled):not(:checked)]:hover:bg-gray-200",
					SWITCH_CIRCLE_CLASS,
					className,
				)}
				{...props}
				ref={ref}
			/>
		);
	},
);

Switch.displayName = "Switch";
