import { ToggleTabButtonGroup } from "components/toggle-button/ToggleTabButtonGroup";
import { ToggleTabButtonLink } from "components/toggle-button/ToggleTabButtonLink";
import { useTranslation } from "react-i18next";
import { Outlet, type To, useOutletContext } from "react-router";

export const AccountHierarchyLayout = () => {
	const { t } = useTranslation();
	const { buildTo } = useOutletContext<{ buildTo: (pathname: string) => To }>();

	return (
		<>
			<ToggleTabButtonGroup className="mt-4">
				<ToggleTabButtonLink to={buildTo("")} end>
					{t("Income statement")}
				</ToggleTabButtonLink>
				<ToggleTabButtonLink to={buildTo("balance-sheet")}>
					{t("Balance sheet")}
				</ToggleTabButtonLink>
				<ToggleTabButtonLink to={buildTo("types")}>
					{t("Account types")}
				</ToggleTabButtonLink>
			</ToggleTabButtonGroup>
			<Outlet />
		</>
	);
};
