import { clsx } from "clsx";
import { type ComponentProps, forwardRef } from "react";
import { COMMON_CHECKBOX_RADIO, COMMON_CLASS } from "./checkboxUtils";

export type RadioProps = ComponentProps<"input">;

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
	({ className, ...props }, ref) => {
		return (
			<div className={clsx("relative size-4", className)}>
				<input
					type="radio"
					className={clsx(
						COMMON_CLASS,
						COMMON_CHECKBOX_RADIO,
						"peer rounded-full checked:bg-purple-25 disabled:checked:bg-gray-100",
					)}
					{...props}
					ref={ref}
				/>
				<div
					aria-hidden="true"
					className="absolute rounded-full bg-purple-500 size-[6px] pointer-events-none top-[calc(50%-6px/2)] left-[calc(50%-6px/2)] opacity-0 peer-checked:opacity-100 peer-disabled:bg-gray-200"
				/>
			</div>
		);
	},
);

Radio.displayName = "Radio";
