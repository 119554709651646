import { clsx } from "clsx";
import type { ReactNode, LabelHTMLAttributes, ComponentProps } from "react";
import { SortingArrowButton2 } from "../../../../features/planning/components/SortingArrowButton";
import { PlanningTable2 } from "../../../../features/planning/components/PlanningTable";
import { useTranslation } from "react-i18next";

interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
	children?: ReactNode | undefined;
	for?: string;
	optional?: boolean;
}

export const Label = ({
	children,
	className,
	optional,
	...props
}: LabelProps) => {
	const { t } = useTranslation();

	return (
		<label
			className={clsx(
				"font-medium text-sm text-gray-700 inline-block pb-[6px]",
				className,
			)}
			{...props}
		>
			{children}
			{optional && (
				<span className="pl-2 text-xs text-gray-500">({t("optional")})</span>
			)}
		</label>
	);
};

interface LabelWithSortingButtonProps {
	labelProps?: ComponentProps<"label">;
	children?: ReactNode | undefined;
	sortingProps: ComponentProps<typeof SortingArrowButton2>;
}

export const LabelWithSortingButton = ({
	labelProps,
	children,
	sortingProps,
}: LabelWithSortingButtonProps) => {
	return (
		<div className="group mb-2 flex items-center gap-x-1">
			<label {...labelProps}>
				<PlanningTable2.ThText>{children}</PlanningTable2.ThText>
			</label>
			<SortingArrowButton2 {...sortingProps} />
		</div>
	);
};
