import { clsx } from "clsx";
import { forwardRef, type LabelHTMLAttributes, type ReactNode } from "react";
import { Checkbox, type CheckboxProps } from "./Checkbox";

interface CheckboxFieldProps extends Omit<CheckboxProps, "children"> {
	label?: ReactNode | string;
	labelProps?: LabelHTMLAttributes<HTMLLabelElement>;
	supportingText?: string;
}

export const CheckboxField = forwardRef<HTMLInputElement, CheckboxFieldProps>(
	function CheckboxField({ label, labelProps, supportingText, ...props }, ref) {
		const { className: labelClassName, ...extraLabelProps } = labelProps ?? {};
		const { className, ...restProps } = props;

		const labelElement = (
			<label
				className={clsx(
					"inline-flex cursor-pointer items-center",
					labelClassName,
				)}
				{...extraLabelProps}
			>
				<Checkbox
					className={clsx("mr-2 shrink-0", className)}
					{...restProps}
					ref={ref}
				/>
				{typeof label === "string" ? (
					<span className="text-sm font-medium text-gray-700">{label}</span>
				) : (
					label
				)}
			</label>
		);

		return supportingText ? (
			<div>
				{labelElement}
				{supportingText && (
					<div className="text-sm text-gray-500 flex items-center pl-6">
						{supportingText}
					</div>
				)}
			</div>
		) : (
			labelElement
		);
	},
);
