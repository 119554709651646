import type { IconProps } from "@tabler/icons-react";
import { clsx } from "clsx";
import {
	type ReactElement,
	useContext,
	cloneElement,
	type ReactNode,
	type ComponentProps,
} from "react";
import { ButtonGroupContext } from "./ButtonGroup.js";
import {
	type ButtonVariant,
	type ButtonSize,
	DEFAULT_BUTTON_VARIANT,
	DEFAULT_BUTTON_SIZE,
	buttonPropertiesBySize,
	classByVariant,
} from "./buttonUtils.js";

export type ButtonLikeProps = {
	variant?: ButtonVariant | undefined;
	size?: ButtonSize | undefined;
	destructive?: boolean | undefined;
	icon?: ReactElement<IconProps> | undefined;
	iconPosition?: "left" | "right" | undefined;
	isLoading?: boolean | undefined;
	fullWidth?: boolean | undefined;
	align?: "start" | "center" | undefined;
	borderless?: boolean | undefined;
} & (
	| { children?: never; ariaLabel: string }
	| { children: ReactNode; ariaLabel?: string | undefined }
);

export const ButtonLike = ({
	align = "center",
	borderless = false,
	destructive = false,
	fullWidth = false,
	isLoading = false,
	iconPosition = "left",
	icon,
	size,
	variant,
	children,
	className,
	ariaLabel,
}: ButtonLikeProps & {
	children: ReactElement;
	className?: string | undefined;
}) => {
	const buttonGroup = useContext(ButtonGroupContext);
	const isIconButton = icon !== undefined && !children.props.children;

	const actualVariant =
		variant ?? buttonGroup?.variant ?? DEFAULT_BUTTON_VARIANT;
	const actualSize = size ?? buttonGroup?.size ?? DEFAULT_BUTTON_SIZE;
	const { className: sizeSpecificClass, iconSize } = buttonPropertiesBySize[
		actualSize
	]({ isIconButton });

	const classes = clsx(
		"focus:outline-none focus-visible:ring-4 aria-[invalid=true]:border-red-300 focus-visible:aria-[invalid=true]:ring-red-100",
		destructive
			? "focus-visible:ring-red-100"
			: "focus-visible:ring-purple-100 focus-visible:border-purple-400",
		borderless ? "ring-inset h-full" : "focus-visible:outline outline-4",
		borderless
			? "outline-0"
			: buttonGroup
				? "first:rounded-l-[8px] last:rounded-r-[8px] mr-[-1px]"
				: "rounded-lg",
		classByVariant[actualVariant]({
			destructive,
			borderless,
		}),
		"focus:outline-none border-solid appearance-none cursor-pointer disabled:cursor-not-allowed select-none items-center whitespace-nowrap font-medium transition-colors",
		sizeSpecificClass,
		isLoading && "relative",
		fullWidth ? "flex w-full" : "inline-flex",
		align === "start" ? "justify-start" : "justify-center",
		className,
	);

	const iconElement = icon && (
		<span className={clsx(isLoading && "opacity-0")}>
			{cloneElement(icon, { size: iconSize })}
		</span>
	);

	return cloneElement(
		children as ReactElement<ComponentProps<"a"> | ComponentProps<"button">>,
		{
			className: classes,
			children: (
				<>
					{isLoading && (
						<div className="absolute inline-flex">
							<span className="size-4 animate-spin rounded-full border-2 border-transparent border-r-current border-t-current" />
						</div>
					)}
					{iconPosition === "left" && iconElement}
					<span className={clsx("text-left", isLoading && "opacity-0")}>
						{children.props.children}
					</span>
					{iconPosition === "right" && iconElement}
				</>
			),
			"aria-label": ariaLabel,
		},
	);
};
