import { useSearchParams } from "react-router";

export const VismaEaccountingExternalCallbackPage = () => {
	const [searchParams] = useSearchParams();
	const isSuccess = searchParams.has("integrationId");

	if (isSuccess) {
		return "✅ Thank you. You can now close this page.";
	}

	return `❌ Unknown error: ${location.href}`;
};
