import { useTranslation } from "react-i18next";
import { useLoadedCompanyDomain } from "src/routes/routeDataHooks.js";
import { QueriesResult } from "components/queryResult/QueriesResult";
import { useCurrentPlanOrThrow } from "../useCurrentPlan.js";
import { useGetAccountsQuery } from "../api/accountApi.js";
import { Card, CardHeader, CardItemsHeader } from "../components/Card.js";
import { IncomingBalanceTable } from "./IncomingBalanceTable.js";
import { usePlanContextOrThrow } from "../tasks/TaskDetailPage/usePlanContextOrThrow.js";
import { accountsSelectors } from "../utils/accountsAdapter.js";
import { useGetIncomingBalancesForPlanQuery } from "../api/incomingBalanceApi.js";
import { ResetIncomingBalanceDialog } from "./ResetIncomingBalanceDialog.js";

export const IncomingBalancePage = () => {
	const { t } = useTranslation();

	const plan = useCurrentPlanOrThrow();
	const companyDomain = useLoadedCompanyDomain();
	const { readOnly } = usePlanContextOrThrow();

	return (
		<QueriesResult
			queries={{
				nexusIncomingBalances: useGetIncomingBalancesForPlanQuery({
					companyDomainId: plan.companyId,
					legalEntityId: plan.nexusLegalEntityId,
					planId: plan.id,
				}),
				accounts: useGetAccountsQuery({
					companyDomainId: companyDomain.id,
					planId: plan.id,
				}),
			}}
			render={({ nexusIncomingBalances, accounts }) => (
				<Card>
					<CardHeader>
						<CardItemsHeader
							heading={t("Incoming Balances")}
							subHeading={t(
								"Incoming balances refer to the initial financial state or balance of accounts at the beginning of the planning period.",
							)}
							count={nexusIncomingBalances.length}
						/>
						<ResetIncomingBalanceDialog />
					</CardHeader>
					<div className="p-4">
						<IncomingBalanceTable
							incomingBalances={nexusIncomingBalances}
							accounts={accountsSelectors.selectAll(accounts)}
							readOnly={readOnly}
						/>
					</div>
				</Card>
			)}
		/>
	);
};
