import { forwardRef, type ReactNode } from "react";
import { createPolymorphicComponent } from "../../../utils/createPolymorphicComponent";
import { clsx } from "clsx";
import { IconExternalLink } from "@tabler/icons-react";

export const DEFAULT_LINK_COLOR = "text-purple-500";

type AnchorWeight = "medium" | "semibold";
const anchorWeights: Record<AnchorWeight, string> = {
	medium: "font-medium",
	semibold: "font-semibold",
};

type AnchorSize = "xs" | "sm" | "md" | "lg";
const anchorSizes: Record<AnchorSize, string> = {
	xs: "text-xs",
	sm: "text-sm",
	md: "text-base",
	lg: "text-lg",
};

interface AnchorProps {
	external?: boolean;
	children?: ReactNode | undefined;
	size?: AnchorSize | undefined;
	weight?: AnchorWeight | undefined;
	className?: string | undefined;
	color?: string | undefined;
}

const _Anchor = forwardRef<HTMLAnchorElement, AnchorProps & { component: any }>(
	(
		{
			component,
			className,
			external = false,
			children,
			color = DEFAULT_LINK_COLOR,
			size = "sm",
			weight = "medium",
			...props
		},
		ref,
	) => {
		const childrenWithExternal = external ? (
			<span
				className="self-start overflow-hidden text-ellipsis whitespace-nowrap"
				title={typeof children === "string" ? children : undefined}
			>
				{children}
			</span>
		) : (
			children
		);

		const Component = component ?? "a";

		return (
			<Component
				ref={ref}
				className={clsx(
					"inline-flex max-w-full cursor-pointer items-center gap-1 hover:underline",
					anchorSizes[size],
					anchorWeights[weight],
					color,
					className,
				)}
				{...props}
			>
				<span className="truncate">{childrenWithExternal}</span>
				{external && <IconExternalLink className="shrink-0" size={16} />}
			</Component>
		);
	},
);

_Anchor.displayName = "Anchor";

export const Anchor = createPolymorphicComponent<"a", AnchorProps>(_Anchor);
