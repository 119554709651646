import { useLoadedCompanyDomain } from "src/routes/routeDataHooks.js";
import { useUser } from "../../common/context/userContextUtils";
import type { EwrPackageInfo } from "../../common/service/ewr";
import EwrPackageCard from "./EwrPackageCard";

export const EwrReportList = () => {
	const companyDomain = useLoadedCompanyDomain();
	const { userIsSuperAdmin } = useUser();
	const filter = userIsSuperAdmin()
		? (p: EwrPackageInfo) => {
				return p.package.isPublicPackage;
			}
		: (p: EwrPackageInfo) => {
				return p.package.isPublicPackage && !p.package.isInternalPackage;
			};

	return (
		<div className="mb-4 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
			{companyDomain.ewrPackages.filter(filter).map((p) => {
				return (
					<EwrPackageCard
						key={p.package.id}
						companyInfo={companyDomain}
						ewrPackage={p}
					/>
				);
			})}
		</div>
	);
};
