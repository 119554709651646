import type { HTMLAttributes, ReactNode } from "react";
import { clsx } from "clsx";
import { Breadcrumbs } from "../atoms/breadcrumbs/Breadcrumbs";
import type { ChipProps } from "../atoms/chip/Chip";
import type { To } from "react-router";

export type BreadCrumbSelectionItem = { label: string; value: string; to: To };

export interface BreadCrumbLink {
	name?: string | undefined;
	path?: string | undefined;
	icon?: ReactNode | undefined;
	chipProps?: ChipProps | undefined;
	content?: ReactNode;
	selections?:
		| {
				filterPlaceholder: string;
				items: BreadCrumbSelectionItem[];
				selected: string;
		  }
		| undefined;
}

interface BreadcrumbsLayoutProps extends HTMLAttributes<HTMLDivElement> {
	children: ReactNode;
	breadcrumbs: BreadCrumbLink[];
	headerContent?: ReactNode;
	mainProps?: HTMLAttributes<HTMLElement>;

	// if the center wrapper should only be applied to the header
	onlyCenterHeader?: boolean;
	maxWidthClassName?: string;
	description?: string;
}

export const BreadcrumbsLayout = ({
	breadcrumbs,
	headerContent,
	children,
	className,
	mainProps,
	onlyCenterHeader,
	maxWidthClassName = "max-w-screen-lg",
	description,
	...props
}: BreadcrumbsLayoutProps) => {
	return (
		<div className={clsx("p-4 md:p-6", className)} {...props}>
			<div
				className={
					!onlyCenterHeader ? `${maxWidthClassName} mx-auto` : undefined
				}
			>
				<header
					className={clsx(
						"mb-2 flex flex-wrap justify-end",
						onlyCenterHeader && `${maxWidthClassName} mx-auto`,
					)}
				>
					<Breadcrumbs
						maxItems={2}
						aria-label="breadcrumb"
						description={description}
						breadcrumbs={breadcrumbs}
					/>
					{headerContent && (
						<div className="flex items-center self-start">{headerContent}</div>
					)}
				</header>
				<div {...mainProps}>{children}</div>
			</div>
		</div>
	);
};
