import { ToggleTabButtonGroup } from "components/toggle-button/ToggleTabButtonGroup";
import { ToggleTabButtonLink } from "components/toggle-button/ToggleTabButtonLink";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router";

export const InvoicesLayout = () => {
	const { t } = useTranslation();

	return (
		<>
			<ToggleTabButtonGroup className="my-4">
				<ToggleTabButtonLink to="customer">
					{t("Customer Invoices")}
				</ToggleTabButtonLink>
				<ToggleTabButtonLink to="supplier">
					{t("Supplier Invoices")}
				</ToggleTabButtonLink>
			</ToggleTabButtonGroup>
			<Outlet />
		</>
	);
};
