import { Trans, useTranslation } from "react-i18next";
import { IconAlertTriangle } from "@tabler/icons-react";
import { FeaturedIcon } from "../featuredIcon/FeaturedIcon";

const supportEmail = "support@exopen.se";

export const MissingDataState = () => {
	const { t } = useTranslation();

	return (
		<div className="flex flex-col items-center justify-center pt-12">
			<FeaturedIcon size="xl" variant="error" theme="light_circle_outline">
				<IconAlertTriangle />
			</FeaturedIcon>

			<div className="mt-4 text-center text-xl">{t("No data found")}</div>
			<div className="w-80 text-center text-base text-gray-500">
				<Trans
					i18nKey="Something went wrong. Try reloading, or contact support: <a>{{supportEmail}}</a>"
					values={{
						supportEmail,
					}}
					components={{
						a: (
							<a
								className="underline"
								title={t("Exopen support email")}
								href={`mailto:${supportEmail}`}
							/>
						),
					}}
				/>
			</div>
		</div>
	);
};
