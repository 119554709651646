import type { ComponentProps, ReactNode } from "react";
import { clsx } from "clsx";
import {
	type Icon,
	IconAlertTriangle,
	IconCircleCheck,
	IconInfoCircle,
} from "@tabler/icons-react";

type AlertType = "info" | "warning" | "error" | "success";

const classesByType: Record<
	AlertType,
	{
		className: string;
		titleClassName: string;
		iconClassName: string;
		supportingTextClassName: string;
	}
> = {
	info: {
		iconClassName: "text-gray-600",
		titleClassName: "text-gray-700",
		className: "bg-gray-50 border-gray-300",
		supportingTextClassName: "text-gray-600",
	},
	error: {
		iconClassName: "text-red-600",
		titleClassName: "text-red-700",
		className: "bg-red-50 border-red-300",
		supportingTextClassName: "text-red-600",
	},
	success: {
		iconClassName: "text-green-600",
		titleClassName: "text-green-700",
		className: "bg-green-50 border-green-300",
		supportingTextClassName: "text-green-600",
	},
	warning: {
		iconClassName: "text-yellow-600",
		titleClassName: "text-yellow-700",
		className: "bg-yellow-50 border-yellow-300",
		supportingTextClassName: "text-yellow-600",
	},
};

const iconComponent: Record<AlertType, Icon> = {
	info: IconInfoCircle,
	error: IconInfoCircle,
	success: IconCircleCheck,
	warning: IconAlertTriangle,
};

interface AlertProps extends Omit<ComponentProps<"div">, "title"> {
	title?: string;
	variant: AlertType;
	supportingText?: ReactNode;
	actions?: ReactNode;
}

export const Alert = ({
	variant,
	title,
	supportingText,
	className,
	actions,
	children,
	...props
}: AlertProps) => {
	const Icon = iconComponent[variant];
	const classes = classesByType[variant];
	return (
		<div
			className={clsx(
				"flex p-4 rounded-lg text-sm border",
				classes.className,
				className,
			)}
			{...props}
		>
			<div className="mr-3">
				<Icon className={clsx("block", classes.iconClassName)} size={20} />
			</div>
			<div className="grow">
				{title && (
					<div className={clsx(classes.titleClassName, "font-medium")}>
						{title}
					</div>
				)}
				{supportingText && (
					<div className={clsx("text-sm", classes.supportingTextClassName)}>
						{supportingText}
					</div>
				)}
				{children}
				{actions && <div className="pt-3">{actions}</div>}
			</div>
		</div>
	);
};
