import type { ReactNode } from "react";
import { useDialogContext } from "./useDialogContext";

interface DialogTitleProps {
	children?: ReactNode | undefined;
	sideContent?: ReactNode;
}

export const DialogTitle = ({ children, sideContent }: DialogTitleProps) => {
	const { labelId } = useDialogContext();
	return (
		<div className="flex p-6 pb-3">
			<h2 className="text-lg font-bold" id={labelId}>
				{children}
			</h2>
			<div className="grow" />
			<div>{sideContent}</div>
		</div>
	);
};
