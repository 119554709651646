import { Navigate } from "react-router";
import { Card } from "../planning/components/Card";
import { useTranslation } from "react-i18next";
import { IconExternalLink } from "@tabler/icons-react";
import { Companies } from "../companyAdministration/Companies";
import { ButtonLink } from "../../common/components/atoms/button/ButtonLink.js";
import {
	useLoadedCompanyDomains,
	useLoadedUser,
} from "src/routes/routeDataHooks.js";

export const Home = () => {
	const { t } = useTranslation();
	const companyDomains = useLoadedCompanyDomains();
	const user = useLoadedUser();

	if (user.superAdmin) {
		return <Companies />;
	}

	if (companyDomains.length === 0) {
		return (
			<Card className="mx-auto mt-10 max-w-[400px] space-y-4 p-4">
				<div className="text-base">
					{t(
						"Your account is not connected to any company, please contact support to get started",
					)}
				</div>
				<ButtonLink
					reloadDocument
					to="https://www.exopen.se/kontakta-oss"
					target="_blank"
					icon={<IconExternalLink />}
					iconPosition="right"
				>
					{t("Contact support")}
				</ButtonLink>
			</Card>
		);
	}

	return <Navigate to={`/company-domains/${companyDomains[0].id}`} />;
};
