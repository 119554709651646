import { IconCheck } from "@tabler/icons-react";
import { clsx } from "clsx";
import {
	type ReactNode,
	forwardRef,
	type CSSProperties,
	useContext,
} from "react";
import { MultiSelectContext } from "./MultiSelectContext.ts";

type RenderSelectOption = (props: {
	isSelected: boolean;
	isDisabled: boolean;
	label: string;
	value: string;
}) => ReactNode;

export const MultiSelectOption = forwardRef<
	HTMLDivElement,
	{
		label: string;
		value: string;
		index: number;
		className?: string | undefined;
		style?: CSSProperties | undefined;
		disabled?: boolean | undefined;
		renderOption?: RenderSelectOption | undefined;
	}
>(function MultiSelectOption(
	{ label, value, index, className, style, disabled, renderOption },
	ref,
) {
	const { activeIndex, selectedIndicies, getItemProps, handleSelect } =
		useContext(MultiSelectContext);

	const isActive = activeIndex === index;
	const isSelected = selectedIndicies.includes(index);
	const itemDisabled = Boolean(disabled) && !isSelected;

	const content = renderOption ? (
		renderOption({ isDisabled: itemDisabled, isSelected, label, value })
	) : (
		<>
			<div className={clsx("mr-2 shrink-0", !isSelected && "invisible")}>
				<IconCheck className="text-purple-500" size={18} />
			</div>
			<div
				className={clsx(
					"text-sm grow truncate",
					itemDisabled && "text-gray-300",
				)}
			>
				{label}
			</div>
		</>
	);

	return (
		<div
			ref={ref}
			role="option"
			id={value}
			aria-selected={isActive}
			className={clsx(
				"flex cursor-pointer items-center px-3",
				itemDisabled
					? "aria-selected:bg-purple-25"
					: "aria-selected:bg-purple-50",
				itemDisabled && "cursor-not-allowed text-gray-500",
				className,
			)}
			{...getItemProps({
				onClick: disabled
					? undefined
					: () => {
							handleSelect(index);
						},
			})}
			style={style}
		>
			{content}
		</div>
	);
});
