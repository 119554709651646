import { IconX, type IconProps } from "@tabler/icons-react";
import { clsx } from "clsx";
import {
	type HTMLAttributes,
	forwardRef,
	type MouseEventHandler,
	type ReactElement,
	cloneElement,
} from "react";

type ChipColor =
	| "purple"
	| "error"
	| "gray"
	| "warning"
	| "blue-light"
	| "primary";

type ChipSize = "small" | "medium" | "large";

const chipPropValues: Record<
	ChipColor,
	{ color: string; bgColor: string; closeColor: string }
> = {
	primary: {
		color: "text-green-700",
		bgColor: "bg-green-200",
		closeColor: "text-green-500",
	},
	"blue-light": {
		color: "text-blue-light-700",
		bgColor: "bg-blue-light-50",
		closeColor: "text-blue-light-500",
	},
	error: {
		color: "text-red-700",
		bgColor: "bg-red-50",
		closeColor: "text-red-500",
	},
	gray: {
		color: "text-gray-700",
		bgColor: "bg-gray-200",
		closeColor: "text-gray-500",
	},
	purple: {
		color: "text-purple-700",
		bgColor: "bg-purple-50",
		closeColor: "text-purple-500",
	},
	warning: {
		color: "text-yellow-700",
		bgColor: "bg-yellow-50",
		closeColor: "text-yellow-500",
	},
};

const chipSizeValues: Record<
	ChipSize,
	{
		padding: string;
		paddingCloseBtn: string;
		textSizeClassName: string;
		iconSize: number;
	}
> = {
	small: {
		padding: "py-0.5 px-2",
		paddingCloseBtn: "py-0.5 pl-2 pr-0.5",
		textSizeClassName: "text-xs",
		iconSize: 12,
	},
	medium: {
		padding: "py-1 px-3",
		paddingCloseBtn: "py-1 pl-3 pr-1",
		textSizeClassName: "text-sm",
		iconSize: 16,
	},
	large: {
		padding: "py-2 px-4",
		paddingCloseBtn: "py-2 pl-4 pr-2",
		textSizeClassName: "text-base",
		iconSize: 20,
	},
};

export interface ChipProps extends HTMLAttributes<HTMLDivElement> {
	variant?: ChipColor | undefined;
	size?: ChipSize;
	closeButton?: boolean;
	onClose?: MouseEventHandler<HTMLButtonElement>;
	leftIcon?: ReactElement<IconProps>;
}

export const Chip = forwardRef<HTMLDivElement, ChipProps>(function Chip(
	{
		className,
		variant = "gray",
		leftIcon,
		size = "medium",
		children,
		closeButton,
		onClose,
		...props
	},
	ref,
) {
	return (
		<div
			ref={ref}
			className={clsx(
				"inline-flex items-center whitespace-nowrap rounded-full",
				closeButton
					? chipSizeValues[size].paddingCloseBtn
					: chipSizeValues[size].padding,
				chipPropValues[variant].bgColor,
				className,
			)}
			{...props}
		>
			{leftIcon && (
				<div className="mr-1">{cloneElement(leftIcon, { size: "12" })}</div>
			)}
			<div
				className={clsx(
					"font-medium",
					chipSizeValues[size].textSizeClassName,
					chipPropValues[variant].color,
				)}
			>
				{children}
			</div>
			{closeButton && (
				<button
					onClick={onClose}
					className={clsx(
						"ml-1 inline-flex cursor-pointer items-center rounded-full p-0.5 hover:bg-black/10 disabled:cursor-not-allowed",
						chipPropValues[variant].closeColor,
					)}
					type="button"
				>
					<IconX size={chipSizeValues[size].iconSize} />
				</button>
			)}
		</div>
	);
});
