import { DrilldownDetailsItem } from "src/features/planning/drilldown/DrilldownDetails";
import { useTranslation } from "react-i18next";
import { useLocale } from "src/locales/useLocale";
import type { ReactElement } from "react";
import type { TFunction } from "i18next";
import type { DrilldownDetailsRenderProps } from "../../common/types";
import {
	collect,
	type DomainDriverModuleEntryV2,
} from "@exopengithub/planning-api-shared";
import { useCurrentPlanOrThrow } from "src/features/planning/useCurrentPlan.js";
import { useGetDriverModuleEntriesV2Query } from "src/features/planning/api/driverModuleEntryApi.js";
import { arrayToRecordByKey } from "src/common/utils/array-utils/arrayToRecordByKey.js";
import { driverModuleEntriesV2Selectors } from "src/features/planning/utils/driverModuleEntriesAdapter.js";
import { getTransactionAmountFromAccount } from "../../../utils/getTransactionAmount.js";
import type { Temporal } from "@js-temporal/polyfill";
import type { DriverModuleTransactionMetaData } from "../types.js";
import type { ModuleTransaction } from "../../../types.js";
import { useGetDriversQuery } from "../../../api/driverApi.js";
import { Card } from "../../../components/Card.js";
import { unique } from "src/common/utils/unique.js";

const getName = (
	driverModuleEntry: DomainDriverModuleEntryV2 | undefined,
	drilldownPeriod: Temporal.PlainYearMonth[],
	t: TFunction,
	formatNumber: (value: number | bigint) => string,
) => {
	if (!driverModuleEntry) return null;

	const input = driverModuleEntry.inputs.find(
		(input) => input.period === drilldownPeriod.toString(),
	);

	return `${driverModuleEntry.driver.name}${
		input && driverModuleEntry.quantity
			? ` (${formatNumber(Number(input.quantity.toFixed(1)))} ${t("pcs")})`
			: ""
	}`;
};

export const DriverDrilldownDetails = ({
	transactions,
	drilldownPeriod,
	accountById,
}: DrilldownDetailsRenderProps<DriverModuleTransactionMetaData>) => {
	const { t } = useTranslation();
	const { formatNumber } = useLocale();
	const plan = useCurrentPlanOrThrow();
	const { data: driverModuleEntries } = useGetDriverModuleEntriesV2Query({
		legalEntityId: plan.nexusLegalEntityId,
		companyDomainId: plan.companyId,
		planId: plan.id,
	});

	const { data: drivers } = useGetDriversQuery({
		legalEntityId: plan.nexusLegalEntityId,
		companyDomainId: plan.companyId,
		planId: plan.id,
		refType: "driver",
	});
	const driverCollection = collect(drivers ?? []);

	const elements: ReactElement[] = [];
	const amountByRefId: Record<string, number> = transactions.reduce(
		(acc, transaction) => {
			const refId = transaction.refId;
			if (!acc[refId]) {
				acc[refId] = 0;
			}

			acc[refId] += getTransactionAmountFromAccount(transaction, accountById);

			return acc;
		},
		{} as Record<string, number>,
	);
	const transactionByDriver = transactions.reduce(
		(buckets, transaction) => {
			const key = transaction.metaData.driver.id;
			let bucket = buckets[key];
			if (!bucket) {
				bucket = buckets[key] = [];
			}
			bucket.push(transaction);
			return buckets;
		},
		{} as Record<string, ModuleTransaction<DriverModuleTransactionMetaData>[]>,
	);

	const driverModuleEntryById = arrayToRecordByKey(
		driverModuleEntries
			? driverModuleEntriesV2Selectors.selectAll(driverModuleEntries)
			: [],
		"id",
	);

	for (const refId in amountByRefId) {
		const driverModuleEntry = driverModuleEntryById[refId];
		if (!driverModuleEntry) {
			continue;
		}
		const amount = amountByRefId[refId];
		const name = getName(driverModuleEntry, drilldownPeriod, t, formatNumber);
		const infoRows = [];

		infoRows.push(
			`${formatNumber(driverModuleEntry.quantity)} ${
				driverModuleEntry.costCenter ? t("units for") : t("units")
			}${
				driverModuleEntry.costCenter
					? ` ${driverModuleEntry.costCenter.name}`
					: ""
			}`,
		);

		elements.push(
			<DrilldownDetailsItem
				amount={amount}
				name={name}
				key={refId}
				infoRows={infoRows}
			/>,
		);
	}

	return (
		<div>
			{elements.length > 0 && (
				<>
					<div className="font-bold mb-2">{t("Assumptions")}</div>
					<Card className="mb-4">
						{elements.map((element) => {
							return (
								<div
									key={element.key}
									className="border-b px-4 py-2 last:border-b-0"
								>
									{element}
								</div>
							);
						})}
					</Card>
				</>
			)}
			<div className="font-bold mb-2">{t("Drivers")}</div>
			<Card>
				{Object.entries(transactionByDriver)
					.sort(([driverId1], [driverId2]) => {
						const driver1 = driverCollection.find(driverId1);
						const driver2 = driverCollection.find(driverId2);

						return (driver1?.name ?? "").localeCompare(driver2?.name ?? "");
					})
					.map(([driverId, transactions]) => {
						const amount = transactions.reduce(
							(sum, transaction) =>
								sum + getTransactionAmountFromAccount(transaction, accountById),
							0,
						);
						const quantity = unique(transactions, (t) => t.refId).reduce(
							(sum, transaction) => sum + transaction.metaData.driverQuantity,
							0,
						);
						const driver = driverCollection.find(driverId);
						if (!driver) {
							return null;
						}
						return (
							<div
								key={driverId}
								className="flex items-baseline border-b px-4 py-2 last:border-b-0"
							>
								<div>
									<div className="font-medium">{driver.name}</div>
									<span className="block text-gray-500">
										{formatNumber(quantity)} {t("pcs")}
									</span>
								</div>
								<div className="ml-auto tabular-nums tracking-tighter">
									{formatNumber(amount)}
								</div>
							</div>
						);
					})}
			</Card>
		</div>
	);
};
