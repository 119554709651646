import { useTranslation } from "react-i18next";
import { EntityItemsList } from "./EntityItemsList.js";
import { EntityHierarchyTable } from "./EntityHierarchyTable.js";
import { QueryResult } from "../../../common/components/atoms/queryResult/QueryResult.js";
import {
	Card,
	CardHeader,
	CardItemsHeader,
} from "../../planning/components/Card.js";
import { useState } from "react";
import type { LegalEntityEntity } from "../../../common/service/nexus/types.js";
import { MoveItemsModal } from "./MoveItemsModal.js";
import { useEntityHierarchy } from "../entityHierarchyProviderUtils";

type Props = {
	isEditable?: boolean;
	onEdit?: () => void;
};

export const EntityHierarchyEditor = ({ isEditable = true, onEdit }: Props) => {
	const { t } = useTranslation();

	const {
		labels: { entityNamePlural },
		useHierarchyQuery,
	} = useEntityHierarchy();

	const [itemsToMove, setItemsToMove] = useState<LegalEntityEntity[]>([]);

	const hierarchyQuery = useHierarchyQuery({
		used: undefined,
	});

	return (
		<QueryResult
			query={hierarchyQuery}
			render={(data) => (
				<>
					<MoveItemsModal
						items={itemsToMove}
						onClose={() => setItemsToMove([])}
					/>
					{data.availableItems.length > 0 &&
						(data.hierarchy.length > 0 ? (
							<Card className="mt-4">
								<CardHeader>
									<CardItemsHeader
										heading={t("Unmapped {{entityNamePlural}}", {
											entityNamePlural,
										})}
										subHeading={t(
											"Move unmapped {{entityNamePlural}} to a suitable place below",
											{ entityNamePlural },
										)}
										count={data.availableItems.length}
									/>
								</CardHeader>
								<EntityItemsList
									isEditable={isEditable}
									items={data.availableItems}
									onMoveItems={setItemsToMove}
									hasFilterUsed={true}
								/>
							</Card>
						) : (
							<Card className="mt-4">
								<CardHeader>
									<CardItemsHeader
										heading={entityNamePlural}
										count={data.availableItems.length}
									/>
								</CardHeader>
								<EntityItemsList
									isEditable={false}
									items={data.availableItems}
									onMoveItems={() => {}}
								/>
							</Card>
						))}
					<EntityHierarchyTable
						onMoveItems={setItemsToMove}
						isEditable={isEditable}
						onEdit={onEdit}
					/>
				</>
			)}
		/>
	);
};
