import type { HierarchyType } from "src/common/service/nexus/utils/hierarchy.js";
import { invariant } from "../../../common/utils/invariant.js";
import { useLocation } from "react-router";
import {
	useLoadedCompanyHierarchy,
	useLoadedLegalEntity,
} from "src/routes/routeDataHooks.js";
import { QUERY_PARAM_GROUP } from "src/features/legalEntities/constants.js";

export const useCurrentHierarchyMode = () => {
	const legalEntity = useLoadedLegalEntity();
	const { hierarchy } = useLoadedCompanyHierarchy();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);

	const isGroup = searchParams.has(QUERY_PARAM_GROUP);
	const parentLegalEntityId =
		isGroup && hierarchy ? hierarchy.legalEntity.id : legalEntity.id;

	invariant(parentLegalEntityId);

	const hierarchyType: HierarchyType = isGroup ? "group" : "legalEntity";

	return { parentLegalEntityId, hierarchyType };
};
