import type { ReactNode } from "react";
import type { TFunction } from "i18next";

interface ChangeLogItem {
	id: string;
	title: (options: { t: TFunction }) => string;
	date: Date;
	content: (options: { t: TFunction }) => ReactNode;
	affectsPlanCalculations?: true;
}

/** The order matters, add new change log items to top of this array */
export const changeLogItems: ChangeLogItem[] = [
	{
		id: "61d7f8da-0c34-4940-a3c0-89148a7793a3",
		title: ({ t }) => t("New input mode for account and driver assumptions"),
		date: new Date("2024-07-04"),
		content: ({ t }) => {
			return (
				<>
					<p className="mb-4">
						{t(
							"The account and driver assumption inputs have had a major overhaul. The new input mode is designed to make it easier to work with assumptions and to provide a better overview of the data.",
						)}
					</p>
					<p className="mb-4">
						{t(
							"The input is now only based on periods of the plan, and you enter amount or quantity per month:",
						)}
					</p>
					<div className="flex items-center justify-center">
						<img
							src="/images/release_driver_account_input.png"
							alt={t("New input mode for account and driver assumptions")}
							className="my-4 max-h-[400px] object-contain"
						/>
					</div>
					<p className="mb-4 italic">
						{t(
							"Click the arrow (or use SHIFT + ENTER) to fill the remaining periods automatically.",
						)}
					</p>
					<p className="mb-4">
						{t(
							"As of now options for daily, weekly or quarterly inputs and the ability to use allocation keys is removed, please contact our support if you rely on this features.",
						)}
					</p>
				</>
			);
		},
	},
];
