import { clsx } from "clsx";
import { useContext } from "react";
import { SidebarContext } from "./SidebarContext";

interface NavItemDividerProps {
	label?: string;
	className?: string;
}

export const NavItemDivider = ({ label, className }: NavItemDividerProps) => {
	const { open } = useContext(SidebarContext);

	return (
		<div className={clsx("my-2 flex items-center gap-2", className)}>
			{open && label && <div className="text-xs text-gray-600">{label}</div>}
			<div className="grow">
				<div className="h-[1px] bg-gray-200" />
			</div>
		</div>
	);
};
