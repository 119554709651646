import { forwardRef, type ComponentProps } from "react";
import { ButtonLike, type ButtonLikeProps } from "./ButtonLike.js";
import { getButtonLikeProps } from "./getButtonLikeProps.js";
import type { Except } from "type-fest";
import { Link } from "react-router";

/**
 * If you want a link that looks like a button, this is the component for you.
 * It takes the same props as react-router <Link>.
 */
export const ButtonLink = forwardRef<
	HTMLAnchorElement,
	ButtonLikeProps & Except<ComponentProps<typeof Link>, "children">
>(function Button(props, ref) {
	const {
		buttonLikeProps,
		rest: { className, ...other },
	} = getButtonLikeProps<ComponentProps<typeof Link>>(props);

	return (
		<ButtonLike className={className} {...buttonLikeProps}>
			<Link {...other} ref={ref} />
		</ButtonLike>
	);
});
