import { clsx } from "clsx";
import { cloneElement } from "react";
import type { IconProps } from "@tabler/icons-react";
import { NavLink, type To } from "react-router";

export interface SideMenuItemProps {
	title: string;
	to: To;
	icon: React.ReactElement<IconProps>;
	end?: boolean | undefined;
}

export const SideMenuItem = ({ to, title, icon, end }: SideMenuItemProps) => (
	<NavLink
		to={to}
		className={({ isActive }) => {
			return clsx(
				"relative flex items-center gap-2 rounded-md px-3 py-2",
				isActive
					? "bg-black/10 after:absolute after:-left-3 after:bottom-1 after:top-1 after:w-1 after:rounded-full after:bg-purple-500"
					: "hover:bg-black/5",
			);
		}}
		end={end ?? false}
	>
		{({ isActive }) => {
			return (
				<>
					{cloneElement(icon, {
						className: clsx(
							"shrink-0",
							isActive ? "text-purple-500" : "text-gray-500",
						),
						size: 20,
					})}
					<div
						className={clsx(
							"text-sm font-medium",
							isActive ? "text-purple-600" : "text-gray-700",
						)}
					>
						{title}
					</div>
				</>
			);
		}}
	</NavLink>
);
