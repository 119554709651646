import { clsx } from "clsx";
import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

interface BreadcrumbsDescriptionProps {
	children?: string | undefined;
}

export const BreadcrumbsDescription = ({
	children,
}: BreadcrumbsDescriptionProps) => {
	const { t } = useTranslation();
	const [readMore, setReadMore] = useState<boolean | null>(null);
	const descRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (descRef.current) {
			if (descRef.current.offsetWidth === descRef.current.scrollWidth) {
				setReadMore(null);
			} else {
				setReadMore((prev) => {
					if (typeof prev !== "boolean") {
						return false;
					}
					return prev;
				});
			}
		}
	}, [children]);

	if (!children) {
		return null;
	}

	return (
		<div
			className="flex max-w-[400px] items-center text-xs"
			style={{ height: !readMore ? "1lh" : undefined }}
		>
			<div
				ref={descRef}
				className={clsx("text-gray-500 break-all", !readMore && "truncate")}
			>
				{children}
				{readMore === true && (
					<button
						type="button"
						onClick={() => {
							setReadMore(false);
						}}
					>
						<div className="text-purple-500 font-medium flex-shrink-0 cursor-pointer whitespace-nowrap pl-1">
							{t("Collapse")}
						</div>
					</button>
				)}
			</div>
			{readMore === false && (
				<button
					type="button"
					onClick={() => {
						setReadMore(true);
					}}
				>
					<div className="font-medium text-purple-500 flex-shrink-0 cursor-pointer whitespace-nowrap pl-1">
						{t("Read more")}
					</div>
				</button>
			)}
		</div>
	);
};
