import { clsx } from "clsx";
import {
	forwardRef,
	type ReactNode,
	type LabelHTMLAttributes,
	type ReactElement,
} from "react";
import { Switch, type SwitchProps } from "./Switch";
import type { ChipProps } from "components/chip/Chip";

interface SwitchFieldProps extends SwitchProps {
	label?: string;
	labelProps?: LabelHTMLAttributes<HTMLLabelElement>;
	supportingText?: ReactNode;
	labelColor?: string;
	labelChip?: ReactElement<ChipProps> | undefined;
}

export const SwitchField = forwardRef<HTMLInputElement, SwitchFieldProps>(
	function SwitchField(
		{ label, labelProps, supportingText, labelColor, labelChip, ...props },
		ref,
	) {
		const { className: labelClassName, ...extraLabelProps } = labelProps ?? {};
		const { className, ...restProps } = props;

		const labelElement = (
			<label
				className={clsx(
					"inline-flex cursor-pointer items-center",
					labelClassName,
				)}
				{...extraLabelProps}
			>
				<Switch
					className={clsx("mr-2 shrink-0", className)}
					{...restProps}
					ref={ref}
				/>
				<div className="flex items-center">
					<span
						className={clsx(
							"text-sm font-medium",
							labelColor ?? "text-gray-700",
						)}
					>
						{label}
					</span>
					{labelChip}
				</div>
			</label>
		);

		return supportingText ? (
			<div>
				{labelElement}
				<div className="flex items-center pl-11">
					{typeof supportingText === "string" ? (
						<div className="text-gray-500 text-sm">{supportingText}</div>
					) : (
						supportingText
					)}
				</div>
			</div>
		) : (
			labelElement
		);
	},
);
