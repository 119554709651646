import type { IconProps } from "@tabler/icons-react";
import { clsx } from "clsx";
import {
	cloneElement,
	forwardRef,
	type ReactElement,
	type ComponentPropsWithoutRef,
	type ReactNode,
} from "react";
import { Chip } from "../chip/Chip";
import { NavLink } from "react-router";

interface ToggleTabButtonLinkProps
	extends Omit<ComponentPropsWithoutRef<typeof NavLink>, "children"> {
	count?: number;
	icon?: ReactElement<IconProps>;
	children?: ReactNode | undefined;
}

export const ToggleTabButtonLink = forwardRef<
	HTMLAnchorElement,
	ToggleTabButtonLinkProps
>(function ToggleTabButton({ children, count, onClick, icon, ...other }, ref) {
	return (
		<NavLink
			className={({ isActive }) => {
				return clsx(
					"inline-flex items-center whitespace-nowrap px-3 py-[6px] text-gray-600",
					isActive && "rounded-md bg-white text-gray-700 shadow",
				);
			}}
			onClick={(event) => {
				event.currentTarget.scrollIntoView({
					block: "nearest",
					inline: "nearest",
				});
				onClick?.(event);
			}}
			ref={ref}
			{...other}
		>
			{icon
				? cloneElement(icon, {
						size: 18,
						color: "currentColor",
						className: "mr-2",
					})
				: null}
			<div className="text-inherit font-medium text-sm">{children}</div>
			{count !== undefined && (
				<Chip size="small" variant="gray" className="ml-2">
					{count}
				</Chip>
			)}
		</NavLink>
	);
});
