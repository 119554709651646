import {
	IconArrowDown,
	IconArrowUp,
	IconSortAscending,
	IconSortDescending,
} from "@tabler/icons-react";
import { clsx } from "clsx";
import type { ButtonHTMLAttributes } from "react";

export interface SortingArrowButtonProps
	extends ButtonHTMLAttributes<HTMLButtonElement> {
	order: "asc" | "desc";
	selected?: boolean | undefined;
}

export const SortingArrowButton = ({
	order,
	className,
	children,
	selected = false,
	...props
}: SortingArrowButtonProps) => {
	return (
		<button className={clsx("flex items-center gap-1", className)} {...props}>
			{children}
			<span
				className={clsx(
					"transition-opacity",
					selected ? "opacity-100" : "opacity-0 hover:opacity-60",
				)}
			>
				{order === "desc" ? (
					<IconArrowDown size={16} color="currentColor" className="block" />
				) : (
					<IconArrowUp size={16} color="currentColor" className="block" />
				)}
			</span>
		</button>
	);
};

interface SortingArrowButton2Props
	extends ButtonHTMLAttributes<HTMLButtonElement> {
	order: "asc" | "desc";
	selected?: boolean | undefined;
}

export const SortingArrowButton2 = ({
	order,
	selected,
	...props
}: SortingArrowButton2Props) => {
	return (
		<button
			className={clsx(
				"inline-flex cursor-pointer transition-opacity",
				selected ? "opacity-1" : "opacity-0 group-hover:opacity-60",
			)}
			{...props}
		>
			{order === "desc" ? (
				<IconSortDescending size={16} className="block text-gray-500" />
			) : (
				<IconSortAscending size={16} className="block text-gray-500" />
			)}
		</button>
	);
};
