import { useTranslation } from "react-i18next";
import {
	useGetCustomerHierarchyQuery,
	useCreateCustomerHierarchyGroupMutation,
	useUpdateCustomerHierarchyGroupMutation,
	useRemoveCustomerHierarchyGroupMutation,
} from "../../../common/service/nexus/nexusApi.js";
import { EntityHierarchyEditor } from "../components/EntityHierarchyEditor.js";
import { EntityHierarchyProvider } from "../EntityHierarchyProvider.js";
import { useCurrentHierarchyMode } from "../hooks/useCurrentHierarchyMode.js";

export const CustomerHierarchyPage = () => {
	const { t } = useTranslation();
	const { hierarchyType } = useCurrentHierarchyMode();

	return (
		<EntityHierarchyProvider
			labels={{
				entityName: t("Customer"),
				entityNamePlural: t("Customers"),
			}}
			useHierarchyQuery={useGetCustomerHierarchyQuery}
			createGroupMutation={useCreateCustomerHierarchyGroupMutation()}
			updateGroupMutation={useUpdateCustomerHierarchyGroupMutation()}
			removeGroupMutation={useRemoveCustomerHierarchyGroupMutation()}
			getItemLabel={(item) => item.name}
			entityColumns={[
				{ key: "code", label: t("Code") },
				{ key: "name", label: t("Name") },
			]}
			type={hierarchyType}
		>
			<EntityHierarchyEditor />
		</EntityHierarchyProvider>
	);
};
