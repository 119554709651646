import {
	useFloating,
	autoUpdate,
	offset as offsetMw,
	flip,
	shift,
	useClick,
	useDismiss,
	useRole,
	useInteractions,
	type Placement,
	type UseRoleProps,
	arrow,
	size,
} from "@floating-ui/react";
import { useState, useMemo, useRef, useCallback } from "react";

export type UsePopoverOptions = {
	controlledOpen?: boolean | undefined;
	controlledSetOpen?: ((open: boolean) => void) | undefined;
	placement?: Placement | undefined;
	offset?: number | undefined;
	role: NonNullable<UseRoleProps["role"]>;
	onClose?: (() => void) | undefined;
	closeOnEscape?: boolean | undefined;
	withArrow?: boolean | undefined;
	disabled?: boolean | undefined;
	disableInteractions?: boolean | undefined;
	maxHeight?: number | undefined;
};

export const usePopover = ({
	controlledOpen,
	controlledSetOpen,
	placement = "bottom",
	role,
	offset = 4,
	onClose,
	closeOnEscape = true,
	withArrow = true,
	disabled = false,
	disableInteractions = false,
	maxHeight,
}: UsePopoverOptions) => {
	const [unControlledOpen, unControlledSetOpen] = useState(false);
	const [labelId, setLabelId] = useState<string | undefined>(undefined);
	const [descriptionId, setDescriptionId] = useState<string | undefined>(
		undefined,
	);
	const arrowRef = useRef(null);

	const isControlled =
		typeof controlledOpen === "boolean" &&
		typeof controlledSetOpen === "function";
	const open = isControlled ? controlledOpen : unControlledOpen;
	const setOpen = isControlled ? controlledSetOpen : unControlledSetOpen;

	const setOpenCtx = useCallback(
		(open: boolean) => {
			setOpen(open);
			if (!open) {
				onClose?.();
			}
		},
		[onClose, setOpen],
	);

	const data = useFloating({
		placement,
		open,
		onOpenChange: setOpenCtx,
		whileElementsMounted: autoUpdate,
		middleware: [
			offsetMw(offset),
			flip({
				crossAxis: placement.includes("-"),
				fallbackAxisSideDirection: "end",
				padding: 4,
			}),
			shift({ padding: 4 }),
			withArrow ? arrow({ element: arrowRef, padding: 10 }) : undefined,
			size({
				padding: 4,
				apply({ availableHeight, elements, availableWidth }) {
					elements.floating.style.maxHeight = `${Math.min(availableHeight, maxHeight ?? Number.POSITIVE_INFINITY)}px`;
					elements.floating.style.maxWidth = `${availableWidth}px`;
				},
			}),
		],
	});

	const isInteractionsDisabled = disabled || disableInteractions;

	const click = useClick(data.context, {
		enabled: !isInteractionsDisabled,
	});
	const dismiss = useDismiss(data.context, {
		escapeKey: closeOnEscape,
		enabled: !isInteractionsDisabled,
	});
	const roleProps = useRole(data.context, {
		role,
		enabled: !disabled,
	});

	const interactions = useInteractions([click, dismiss, roleProps]);

	return useMemo(
		() => ({
			...interactions,
			...data,
			isControlled,
			open,
			setOpen: setOpenCtx,
			labelId,
			setLabelId,
			descriptionId,
			setDescriptionId,
			arrowRef,
			withArrow,
			disableInteractions,
		}),
		[
			interactions,
			data,
			isControlled,
			open,
			setOpenCtx,
			labelId,
			descriptionId,
			withArrow,
			disableInteractions,
		],
	);
};
