import { clsx } from "clsx";
import type { ComponentProps } from "react";
import { Chip } from "../../../common/components/atoms/chip/Chip";
import { useLocale } from "src/locales/useLocale.js";

type CardProps = ComponentProps<"div">;

export const Card = ({ className, children, ...props }: CardProps) => {
	return (
		<div
			className={clsx(
				"overflow-hidden rounded-lg border border-gray-200 bg-white",
				className,
			)}
			{...props}
		>
			{children}
		</div>
	);
};

type CardHeaderProps = ComponentProps<"div">;

export const CardHeader = ({
	className,
	children,
	...props
}: CardHeaderProps) => {
	return (
		<div
			className={clsx("border-b border-gray-200 p-4", className, "flex")}
			{...props}
		>
			{children}
		</div>
	);
};

interface CardItemsHeaderProps extends ComponentProps<"div"> {
	heading: string;
	subHeading?: React.ReactNode;
	count?: number | undefined;
}

export const CardItemsHeader = ({
	heading,
	count,
	subHeading,
}: CardItemsHeaderProps) => {
	const { formatNumber } = useLocale();

	return (
		<div className="grow">
			<div className={clsx("flex items-center", subHeading && "mb-1")}>
				<div className="font-medium mr-2">{heading}</div>
				{count !== undefined && (
					<Chip size="small" variant="gray">
						{formatNumber(count)}
					</Chip>
				)}
			</div>
			{subHeading && <div className="text-xs text-gray-500">{subHeading}</div>}
		</div>
	);
};
