import { emptyFusionHubApi } from "src/common/service/fusionHub/emptyFusionHubApi";

export const fortnoxApi = emptyFusionHubApi.injectEndpoints({
	endpoints: (builder) => ({
		getFortnoxAuthorizationUrl: builder.query<
			{ authorizationUrl: string },
			{
				companyDomainId: string;
				successRedirectUrl: string;
				errorRedirectUrl: string;
			}
		>({
			query: ({ companyDomainId, successRedirectUrl, errorRedirectUrl }) => ({
				url: "/integrations/fortnox/authorization-url",
				params: {
					successRedirectUrl,
					errorRedirectUrl,
					companyDomainId,
					accountType: "service",
				},
			}),
		}),
		getFortnoxReauthorizeUrl: builder.query<
			{ authorizationUrl: string },
			{
				integrationId: number;
				successRedirectUrl: string;
				errorRedirectUrl: string;
			}
		>({
			query: ({ integrationId, successRedirectUrl, errorRedirectUrl }) => ({
				url: `/integrations/${integrationId}/fortnox-raw/re-authorization-url`,
				params: {
					successRedirectUrl,
					errorRedirectUrl,
					accountType: "service",
				},
				method: "GET",
			}),
		}),

		getStatus: builder.query<{ refreshTokensPausedUntil: string | null }, void>(
			{
				query() {
					return "/integrations/fortnox/status";
				},
			},
		),
	}),
	overrideExisting: false,
});

export const {
	useLazyGetFortnoxAuthorizationUrlQuery,
	useLazyGetFortnoxReauthorizeUrlQuery,
	useGetStatusQuery,
} = fortnoxApi;
