import type {
	FusionHubSourceSystem,
	SourceSystem,
} from "src/features/connector/sourceSystems";
import type { JsonValue } from "type-fest";

export const TAGS = [
	"Integrations",
	"SyncStatus",
	"SieFile",
	"VitecCredential",
	"VitecIntegrationConfig",
	"Job",
	"JobInIntegration",
] as const;

export type FusionhubIntegration = {
	id: number;
	type: FusionHubSourceSystem;
	loadStatus: "INITIAL" | "LOADING" | "FAILED" | "READY";
	companyDomainId: string | null;
	createdAt: string;
	updatedAt: string;
};

export type ErLegalEntity = {
	id: string;
	name: string;
	currencyBase: string;
};

export type ErSyncStatus =
	| {
			status: "FINISHED";
			finishedAt: Date;
	  }
	| {
			status: "FAILED";
			failedAt: Date;
	  }
	| {
			status: "IN_PROGRESS";
			startedAt: Date;
	  };

export type ErNote = {
	sv: string;
	en: string;
};

export type ErDocumentation = Record<
	string,
	{
		title: ErNote;
		notes: ErNote[];
		sources: Record<SourceSystem, { notes: ErNote[] }>;
	}
>;

export type PaginatedResponse<T> = {
	items: T[];
	page: {
		cursor: string | null;
	};
};

export type PaginatedParams = {
	take?: number;
	cursor?: string;
	orderDirection?: "asc" | "desc";
};

export type MergeSystem = {
	name: string;
	slug: string;
	logo: string;
};

export type FusionHubJobStatus =
	| "QUEUED"
	| "RUNNING"
	| "COMPLETED"
	| "FAILED"
	| "RESERVED";

type FusionHubConcurrencyStatus = "RUNNING" | "PENDING";

type FusionHubJobLogLevel =
	| "trace"
	| "debug"
	| "info"
	| "warn"
	| "error"
	| "fatal";

type FusionHubJobLogRecord = {
	level: FusionHubJobLogLevel;
	message: string;
	timestamp: string;
	error?: { message: string; name: string; stack: string };
};

export type FusionHubJob = {
	id: number;
	name: string;
	status: FusionHubJobStatus;
	parameters: unknown;
	output: JsonValue;
	queuedAt: string;
	startedAt: string | null;
	completedAt: string | null;
	seenAt: string | null;
	concurrencyGroup: string | null;
	concurrencyStatus: FusionHubConcurrencyStatus | null;
	concurrencyCooldown: number | null;
	instanceId: number | null;
	integrationId: number | null;
	log: FusionHubJobLogRecord[] | null;
	createdBy: string;
};
